import { v4 as uuidv4 } from 'uuid';
import { refreshToken } from '../utils/Auth';

export function formatMillisecondsToHMS(milliseconds) {
    const { hours, minutes, seconds } = millisecondsToHMS(milliseconds);
  
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export function millisecondsToHMS(milliseconds) {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60));

    return { hours, minutes, seconds };
}
export function millisecondsToHMSObject(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000) % 60;
  const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  const h = String(hours).padStart(2, '0');
  const m = String(minutes).padStart(2, '0');
  const s = String(seconds).padStart(2, '0');

  return { h, m, s };
}
export function getVideoIDFromURL(url, returnType) {
    const pattern = /^(?:https?:\/\/youtu\.be\/|https?:\/\/www\.youtube\.com\/watch\?v=)([A-Za-z0-9_-]{11})/;
    const listPattern = /.*[&?]list=([A-Za-z0-9_-]+)/; // Pattern for list ID
  
    const listMatch = url.match(listPattern);
    const match = url.match(pattern);
  
    if (listMatch && listMatch[1] && match && match[1]) {
      if (returnType === 'video') {
        return { id: match[1], type: 'video' }; // Return video ID if specified
      } else if (returnType === 'playlist') {
        return { id: listMatch[1], type: 'playlist' }; // Return playlist ID if specified
      }
      return { id: null, playlist_id:listMatch[1], video_id:match[1], type: 'both' }; // Return "both" if both video and playlist IDs are present
    } else if (listMatch && listMatch[1]) {
      return { id: listMatch[1], type: 'playlist' }; // Return playlist ID
    } else if (match && match[1]) {
      return { id: match[1], type: 'video' }; // Return video ID
    } else {
      return { id: null, type: 'unknown' }; // Return unknown type for URLs that don't match either pattern
    }
}
/**
 * Universal fetch function to make HTTP requests.
 * 
 * @param {string} url - The URL to which the request is sent.
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE, etc.).
 * @param {Object|null} body - The body of the request for POST/PUT methods.
 * @param {string|null} authToken - Authorization token, if required.
 * @returns {Promise<any>} - A promise resolving to the response data.
 */
export async function universalFetch(url, method, body = null, authToken = null) {
  console.log("universalFetch authToken", authToken)
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': authToken ? `Bearer ${authToken}` : ''
  };

  const requestOptions = {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const contentType = response.headers.get("Content-Type");

      if (contentType && contentType.includes("application/json")) {
        const errorData = await response.json();
        console.log("errorData", errorData)
        throw new Error(`Error ${response.status}: ${errorData.message}`);
      } else {
        // Handle non-JSON responses (like plain text)
        const errorText = await response.text();
        console.log("errorText", errorText)
        throw new Error(`${errorText}`);
      }
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType.includes("application/json")) {
      return await response.json();
    } else if (contentType.includes("audio/mpeg")) {
      return await response.blob();
    } else if (contentType.includes("text/json")) {
      return await response.json();
    } else {
      return { message: 'Success' };
    }
  } catch (error) {
    console.error('Fetch error:', error.message);
    throw error;
  }
}
export async function universalFetchPublic(url, method, body = null) {
  const headers = {
    'Content-Type': 'application/json',
  };

  const requestOptions = {
    method: method,
    headers: headers,
    body: body ? JSON.stringify(body) : null,
  };

  try {
    const response = await fetch(url, requestOptions);

    if (!response.ok) {
      const contentType = response.headers.get("Content-Type");

      if (contentType && contentType.includes("application/json")) {
        const errorData = await response.json();
        throw new Error(`Error ${response.status}: ${errorData.message}`);
      } else {
        // Handle non-JSON responses (like plain text)
        const errorText = await response.text();
        throw new Error(`Error ${response.status}: ${errorText}`);
      }
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType.includes("application/json")) {
      return await response.json();
    } else if (contentType.includes("audio/mpeg")) {
      return await response.blob();
    } else if (contentType.includes("text/json")) {
      return await response.json();
    } else {
      return response;
    }
  } catch (error) {
    console.error('Fetch error:', error);
    throw error;
  }
}
export const fetchDataWithTokenRefresh = async (url, method, body, token, setToken) => {
  try {
    console.log("fetchDataWithTokenRefresh token", token)
      return await universalFetch(url, method, body, token);
  } catch (error) {
      // Check if the error message contains 'Unauthorized'
      if (error.message.includes('Unauthorized')) {
          const newToken = await refreshToken();
          if (newToken) {
              setToken(newToken);
              return await universalFetch(url, method, body, newToken);
          } else {
              throw new Error('Unable to refresh token');
          }
      }
      console.log("does this fire?")
      throw error;
  }
};

export function parseYouTubeVideoDuration(durationString) {
    console.log(durationString)
    console.log(/^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/.test(durationString))
    console.log(typeof(durationString))

    // Check if the string starts with "PT" (Period Time) and ends with "S" (Seconds)
    if (/^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/.test(durationString)) {
        const matches = durationString.match(/^PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?$/);

        const h = matches[1] ? parseInt(matches[1]) : 0;
        const m = matches[2] ? parseInt(matches[2]) : 0;
        const s = matches[3] ? parseInt(matches[3]) : 0;

        // Calculate the total duration in seconds
        const total_seconds = h * 3600 + m * 60 + s;

        return {
        h,
        m,
        s,
        total_seconds,
        };
    }

    throw new Error('Invalid duration string format.');
}
export function generateId() {
  return uuidv4();
}
export const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};
export function isValidImageUrl(url) {
  const regex = /^https?:\/\/.*\.(png|jpg)$/i;
  return regex.test(url);
}
export function spotifyUrlParser(urlOrUri) {
  // Check if it's a URI format
  if (urlOrUri.startsWith('spotify:')) {
      const parts = urlOrUri.split(':');
      return {
          type: parts[1],
          id: parts[2],
          uri: urlOrUri
      };
  }

  // Check if it's a URL format
  if (urlOrUri.startsWith('https://open.spotify.com/')) {
      const url = new URL(urlOrUri);
      const parts = url.pathname.split('/');
      return {
          type: parts[1],
          id: parts[2],
          uri: `spotify:${parts[1]}:${parts[2]}`
      };
  }

  // Return null if it doesn't match any format
  return null;
}
export function convertToSpotifyURI(input) {
  // Check if input is already in URI format
  if (input.startsWith('spotify:track:')) {
      return input;
  }

  // Check and convert if input is in URL format
  const match = input.match(/open\.spotify\.com\/track\/([a-zA-Z0-9]+)(?:\?|$)/);
  if (match && match[1]) {
      return `spotify:track:${match[1]}`;
  }

  // Return null or handle error for inputs that are neither
  return null;
}