import { createSession, createSegment, createMovement, createInterval, createMedia } from './dataModel.js';  // Assuming the above functions are in dataModel.js
import * as Constants from '../constants';

export const createDefaultSession = (sessionTitle, author) => {
    // Create default interval
    const defaultInterval = createInterval(); 

    // Create default media
    // const defaultMedia = createMedia();

    // Creating default movements with the default interval and media
    const warmupMovement = createMovement({ type: 'Warmup', intervals: [defaultInterval], media: createMedia() });
    const runMovement = createMovement({ type: 'Run', intervals: [defaultInterval], media: createMedia() });
    const cooldownMovement = createMovement({ type: 'Cooldown', intervals: [defaultInterval], media: createMedia() });

    // Calculate time for each movement based on intervals
    warmupMovement.time = warmupMovement.intervals.reduce((sum, interval) => sum + interval.time.total_seconds, 0);
    runMovement.time = runMovement.intervals.reduce((sum, interval) => sum + interval.time.total_seconds, 0);
    cooldownMovement.time = cooldownMovement.intervals.reduce((sum, interval) => sum + interval.time.total_seconds, 0);
    
    // Calculating segment times
    const warmupSegmentTime = warmupMovement.time;
    const runSegmentTime = runMovement.time;
    const cooldownSegmentTime = cooldownMovement.time;

    // Creating segments with respective movements and times
    const warmupSegment = createSegment({ type: 'Warmup', time: warmupSegmentTime, movements: [warmupMovement], position:1 });
    const runSegment = createSegment({ type: 'Run', time: runSegmentTime, movements: [runMovement], position:2 });
    const cooldownSegment = createSegment({ type: 'Cooldown', time: cooldownSegmentTime, movements: [cooldownMovement], position:3 });

    // Calculating total session time
    const totalSessionTime = warmupSegmentTime + runSegmentTime + cooldownSegmentTime;

    // Creating session with the three segments and total time
    const session = createSession({ 
        title: sessionTitle,
        time: totalSessionTime,
        author,
        segments: [warmupSegment, runSegment, cooldownSegment]
    });

    return session;
}
export function findPreviousIntervalPaceDuringSegmentEdit(session, currentSegment, currentMovementIndex, currentIntervalIndex) {
    // Check if there is a previous interval in the current movement
    if (currentIntervalIndex > 0) {
        return currentSegment.movements[currentMovementIndex].intervals[currentIntervalIndex - 1].pace;
    }

    // Check previous movements in the current segment
    if (currentMovementIndex > 0) {
        const previousMovement = currentSegment.movements[currentMovementIndex - 1];
        if (previousMovement.intervals.length > 0) {
            return previousMovement.intervals[previousMovement.intervals.length - 1].pace;
        }
    }

    // Check previous segments
    let currentSegmentIndex = session.segments.findIndex(segment => segment.id === currentSegment.id);
    for (let i = currentSegmentIndex - 1; i >= 0; i--) {
        const segment = session.segments[i];
        if (segment.movements.length > 0) {
            const lastMovement = segment.movements[segment.movements.length - 1];
            if (lastMovement.intervals.length > 0) {
                return lastMovement.intervals[lastMovement.intervals.length - 1].pace;
            }
        }
    }

    return null; // No previous interval found
}

export function getPaceChangeAudioFileName(previous_pace, current_pace) {


    const prevIndex = Constants.PACES.indexOf(previous_pace);
    const currIndex = Constants.PACES.indexOf(current_pace);

    if (prevIndex === -1 || currIndex === -1) {
      return "Invalid pace";
    }
  
    if (prevIndex === currIndex) {
      return "same";
    }
  
    const direction = prevIndex < currIndex ? "up" : "down";
    
    return `${direction}2${current_pace.toLowerCase()}`;
  }

export function findNextIntervalPace(session, currentSegment, currentMovementIndex, currentIntervalIndex) {
    // Check for the next interval in the current movement
    if (currentIntervalIndex < currentSegment.movements[currentMovementIndex].intervals.length - 1) {
        return currentSegment.movements[currentMovementIndex].intervals[currentIntervalIndex + 1].pace;
    }

    // Check subsequent movements in the current segment
    if (currentMovementIndex < currentSegment.movements.length - 1) {
        const nextMovement = currentSegment.movements[currentMovementIndex + 1];
        if (nextMovement.intervals.length > 0) {
            return nextMovement.intervals[0].pace;
        }
    }

    // Check subsequent segments
    let currentSegmentIndex = session.segments.findIndex(segment => segment.id === currentSegment.id);
    for (let i = currentSegmentIndex + 1; i < session.segments.length; i++) {
        const segment = session.segments[i];
        if (segment.movements.length > 0 && segment.movements[0].intervals.length > 0) {
            return segment.movements[0].intervals[0].pace;
        }
    }

    return null; // No next interval found
}

export function getNextVoiceLineOnlineLocation(segmentData, segmentIndex, movementIndex, intervalIndex) {
    
    // Check if there's a next interval in the same movement
    if (segmentData[segmentIndex].movements[movementIndex].intervals[intervalIndex + 1]) {
        return segmentData[segmentIndex].movements[movementIndex].intervals[intervalIndex + 1].voiceline.online_location;
    }

    // Check if there's a next movement in the same segment
    if (segmentData[segmentIndex].movements[movementIndex + 1]) {
        if (segmentData[segmentIndex].movements[movementIndex + 1].intervals.length > 0) {
            return segmentData[segmentIndex].movements[movementIndex + 1].intervals[0].voiceline.online_location;
        }
    }

    // Check if there's a next segment
    if (segmentData[segmentIndex + 1]) {
        if (segmentData[segmentIndex + 1].movements.length > 0 && segmentData[segmentIndex + 1].movements[0].intervals.length > 0) {
            return segmentData[segmentIndex + 1].movements[0].intervals[0].voiceline.online_location;
        }
    }

    // If there is no next interval
    return null;
}