import React, { useState, useEffect, useRef } from 'react';
import '../App.css';

const Dropdown = ({ options, onSelect, title, selectedValue, disabled, disabledAlertMessage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (disabled) {
      if (disabledAlertMessage) {
        alert(disabledAlertMessage);
      }
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleOnClick = option => {
    if (!disabled) {
      onSelect(option);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    let matchedOption;
    if (options[0].type !== "coach") {
      matchedOption = options.find(option => option.value === selectedValue);
    } else {
      matchedOption = options.find(option => option.label === selectedValue);
    }
    setSelectedOption(matchedOption);

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedValue, options]);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', width: '100%' }}>
      <div className={`dropdown ${disabled ? 'disabled' : ''}`} onClick={toggleDropdown}>
        <h4>{title}</h4>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>{selectedOption ? selectedOption.label : "Select an option"}</div>
          <div>{!disabled && (isOpen ? '▲' : '▼')}</div>
        </div>
      </div>

      {isOpen && !disabled && (
        <div className="dropdown-choices-parent" style={{ maxHeight: 200, overflowY: "auto" }}>
          {options.map(option => (
            <div className="dropdown-choice" key={option.value} onClick={() => handleOnClick(option)}>
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;