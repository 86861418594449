import React, { useContext, useEffect, useRef } from 'react';
import { SessionContext } from '../App'; // Adjust the path as needed
import  Footer  from '../components/Footer';
import { useLocation } from 'react-router-dom';
import { useQuery } from '../utils/Hooks';
import { universalFetchPublic } from '../utils/Utils';
import { SessionCardStripedBackground } from '../components/SessionCardStripedBackground';
import * as Constants from '../constants';
// import {ReactComponent as IconViews} from '../assets/icons/views.svg';
// import {ReactComponent as IconCompletes} from '../assets/icons/completes.svg';
// import {ReactComponent as IconMedia} from '../assets/icons/media.svg';
// import {ReactComponent as IconDifficulty} from '../assets/icons/difficulty.svg';
// import {ReactComponent as IconEdit} from '../assets/icons/edit.svg';
// import {ReactComponent as IconDuplicate} from '../assets/icons/copy.svg';
import { millisecondsToHMSObject } from '../utils/Utils';
import { Link } from 'react-router-dom';
import {fetchDataWithTokenRefresh} from '../utils/Utils';
import {useNavigate} from 'react-router-dom';
import {ReactComponent as IconFavoriteFill} from '../assets/icons/favorite_fill.svg';
import {ReactComponent as IconFavoriteOpen} from '../assets/icons/favorite_open.svg';
import  StarRating  from '../components/StarRating';


import Tooltip from '../components/Tooltip';


const Session = () => {
  const { user, token, setToken } = useContext(SessionContext);
  const [somethingLoading, setSomethingLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const [sessionState, setSessionState] = React.useState(null);
  const [intervals, setIntervals] = React.useState([]);
  const [segments, setSegments] = React.useState([]);
  const [isThisSessionFavorited, setIsThisSessionFavorited] = React.useState(false);
  const [favoritingLoading, setFavoritingLoading] = React.useState(false);
  // const [setHasUserRatedThisSession] = React.useState(false);
  const [sessionRating, setSessionRating] = React.useState(0);
  const [sessionNumberOfRatings, setSessionNumberOfRatings] = React.useState(0);
  const [didUserSetNewRating, setDidUserSetNewRating] = React.useState(false);
  const query = useQuery();
  let session = useRef(null);
  const location = useLocation();
  const sessionId = query.get('id');
  const navigate = useNavigate();

  const fetchTheSession = async () => {
    let ipResponse = await universalFetchPublic(process.env.REACT_APP_GET_IP, "GET", null);
    let ip = ipResponse.ip;
    let detailedEndpoint = `${process.env.REACT_APP_GET_SESSION_BY_ID}?ip=${ip}&id=${sessionId}`;
    let fetch_session = await universalFetchPublic(detailedEndpoint, "GET", null);
    session.current = fetch_session[0];
    let rating = "No ratings"
    if(session.current.number_of_ratings!==0) {
      rating = roundToTenths(session.current.rating / session.current.number_of_ratings);
    }
    console.log(session.current.number_of_ratings)
    console.log("Rating", rating)
    setSessionNumberOfRatings(session.current.number_of_ratings);
    setSessionRating(rating);
    console.log("Session", session.current.updated)
    setSessionState(session.current);
    figureOutIntervals(); 
  }
  function convertEpochToDate(seconds) {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }
  const figureOutIntervals = () => {
    let intervals = []
    let segments = []
    session.current.segments.forEach(segment => {
      segments.push({time: segment.time, type: segment.type, color: Constants.SEGMENT_TYPE_COLORS[segment.type]})
      segment.movements.forEach(movement => {
        movement.intervals.forEach(interval => {
          intervals.push({time: interval.time.total_seconds, color: Constants.PACES_COLORS[interval.pace]})
        })
      })
    });
    setIntervals(intervals);
    setSegments(segments);

  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
  }
  async function dealWithFavorites(){
    setFavoritingLoading(true);
    let favorites_fetch = await fetchDataWithTokenRefresh(process.env.REACT_APP_HANDLE_FAVORITES, "GET", null, token, setToken)
    console.log("Favorites", favorites_fetch)
    console.log("Session", session.current.id)
    if (favorites_fetch) {
      if (favorites_fetch.length > 0) {
        let found = favorites_fetch.find(favorite => favorite.sessionId === session.current.id);
        console.log("Found", found)
        if (found) {
          setIsThisSessionFavorited(true);
        } else {
          setIsThisSessionFavorited(false);
        }
      } else {
        setIsThisSessionFavorited(false);
      }
    } else {
      setIsThisSessionFavorited(false);
    }
    
    setFavoritingLoading(false);
  }
  function roundToTenths(num) {
    return Math.round(num * 10) / 10;
  }
  // async function dealWithRatings(){
  //   //see if user has rated
  //   let has_user_rated = await fetchDataWithTokenRefresh(process.env.REACT_APP_CHECK_IF_USER_RATED+"?sessionId="+session.current.id, "GET", null, token, setToken)
  //   console.log(has_user_rated)
  //   setHasUserRatedThisSession(has_user_rated);
  // }
  useEffect(() => {
    console.log("Session ID", sessionId)
    console.log("Location", location.state.session)
    if(location.state === null || location.state === undefined){
      console.log("No state")
      //we need to fetch the data from the ID in the url
      fetchTheSession();
    }else{
      setSessionState(location.state.session);
      session.current = location.state.session;
      let rating = "No ratings"
      console.log(session.current.number_of_ratings)
      if(session.current.number_of_ratings!==0) {
        rating = roundToTenths(session.current.rating / session.current.number_of_ratings);
      }
      console.log("Rating", rating)
      setSessionNumberOfRatings(session.current.number_of_ratings);
      setSessionRating(rating);
      figureOutIntervals();
    }
    if(user){
      dealWithFavorites();
      // dealWithRatings();
    }

    return () => {
      // cleanup
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <div>
        {somethingLoading && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
            <div className="loading-icon"></div>
            <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
          </div>
        )}
        <div style={{display: "flex", flexDirection: "column", minHeight:"calc(100vh - 178px)"}}>
    
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", flexGrow: 1, backgroundColor:"#F3F3F3", height:"100%"}}>
            <div style={{maxWidth:1200, width:"100%", backgroundColor:"#fff", flexGrow: 1}}>
              <div style={{width:"calc(100% - 20px)", padding:10}}>
              {(sessionState && intervals && segments) && (
                <div>
                  <SessionCardStripedBackground card={false} intervals={intervals} segments={segments} totalTime={sessionState.time}>
                    <div style={{display:"flex", flex:1, flexDirection:'row', alignItems:"center"}}>
                      <div>
                        <img alt="coach" src={sessionState.coach_id.photo} style={{width:50, height:50, borderRadius:50, borderWidth:4, borderColor:"#000", borderStyle:"solid"}}></img>
                        <h4 style={{fontWeight:800, color:"#fff", padding:3, backgroundColor:"#000", textAlign:"center", borderRadius:5, marginTop:-10}}>{sessionState.coach_id.name}</h4>
                      </div>
                      <div style={{marginLeft:20, flex:1, flexDirection:"row", display:"flex", alignSelf:"center"}}>
                        <div>
                          <h1 className="time" style={{ flex: 1, color: "#fff"}}>
                            {millisecondsToHMSObject(sessionState.time*1000).h}:{millisecondsToHMSObject(sessionState.time*1000).m}:{millisecondsToHMSObject(sessionState.time*1000).s}
                          </h1>
                          <h3 style={{color:"#fff", marginTop:-5}}>{formatDate(sessionState.date_created)}</h3>
                        </div>
                        {user && (
                        <div style={{alignSelf:"center", marginLeft:10  }}>
                          {!favoritingLoading ? (
                            <Tooltip text={isThisSessionFavorited===true?"Unfavorite this session.":"Make this session a favorite."}>
                              <div onClick={async ()=>{
                                setFavoritingLoading(true);
                                let add_favorite = await fetchDataWithTokenRefresh(process.env.REACT_APP_HANDLE_FAVORITES, "POST", {sessionId: session?.current?.id, sessionAuthor: session?.current?.author}, token, setToken)
                                console.log("Add Favorite", add_favorite)
                                await dealWithFavorites();
                                setFavoritingLoading(false);
                              }}>
                                {!isThisSessionFavorited ? (
                                  <IconFavoriteOpen fill="#FFFF00" style={{width:40, height:40, marginRight:5}}></IconFavoriteOpen>
                                ):(
                                  <IconFavoriteFill fill="#FFFF00" style={{width:40, height:40, marginRight:5}}></IconFavoriteFill>
                                )}
                              </div>
                            </Tooltip>
                          ):(
                            <div>
                              <div className="loading-icon"></div>
                            </div>
                          )}
                        </div>
                        )}
                      </div>
                      {(user && user.product==="pro") && (
                        <Link to={{
                          pathname: "/build-session",
                          search: `?id=${sessionState.id}&duplicate=true`,  // Optional: Keep it if you want to pass the session ID in the URL as well 
                        }} state={{ session: sessionState }}
                        >
                        <button className="medium" style={{marginRight:10}}>Duplicate</button>
                        </Link>
                      )}
                      {(user && user.uid === sessionState.author && user.product==="pro") && (
                        <Link to={{
                            pathname: "/build-session",
                            search: `?id=${sessionState.id}`,  // Optional: Keep it if you want to pass the session ID in the URL as well 
                          }} state={{ session: sessionState }}
                        >
                          <button className="medium" style={{marginRight:10}}>Edit Session</button>
                        </Link>
                      )}
                      {(user && user.uid === sessionState.author && user.product==="pro") && (
                        <button className="medium" style={{ backgroundColor:"#D81F1F"}} onClick={async ()=>{
                          const isConfirmed = window.confirm('Are you sure you want to delete this session? This cannot be undone.');
                          if (isConfirmed) {
                            setLoadingMessage("Deleting session...")
                            setSomethingLoading(true);
                            // REACT_APP_DELETE_SESSION
                            try{
                              await fetchDataWithTokenRefresh(process.env.REACT_APP_DELETE_SESSION+"?runId="+sessionState.id, "GET", null, token, setToken);
                              navigate("/profile");
                            }catch(e){
                              alert("Error deleting session: "+e)
                            }
                            setSomethingLoading(false);
                          }

                        }}>Delete Session</button>
                      )}
                    </div>
                  </SessionCardStripedBackground>
                  <div style={{display:"flex", flexDirection:"row"}}>
                    <div style={{flex:1}}>
                      <div style={{display:"flex", flexDirection:"row", alignItems:"center", padding:10}}>
                        <div>
                          <img src="https://via.placeholder.com/150" alt="coach" style={{width:50, height:50, borderRadius:50, borderWidth:4, borderColor:"#000", borderStyle:"solid"}}></img>
                        </div>
                        <div style={{marginLeft:10}}>
                          <h2>{sessionState.title}</h2>
                          <h3>Created by: {sessionState.author}</h3>
                        </div>
                      </div>
                      <div style={{display:"flex", flexDirection:"row", backgroundColor:"#F3F3F3", padding:10, alignItems:"center"}}>
                        <h3 style={{alignSelf:'center', marginRight:10}}><span style={{fontWeight:400}}>Rating:</span></h3>
                        <StarRating token={token} setToken={setToken} sessionId={sessionState.id} initialRating={sessionRating} readOnly={user===null?true:false} sessionAuthor={sessionState.author} 
                        updateParent={async ()=>{
                          setDidUserSetNewRating(true);
                          await fetchTheSession()
                          setDidUserSetNewRating(false);
                        }}
                        triggerLoadingIcon={(visible_or_no)=>{
                          if(visible_or_no){
                            setDidUserSetNewRating(true);
                          }else{
                            setDidUserSetNewRating(false);
                          }
                        }}/>
                        <h3 style={{alignSelf:'center', marginLeft:10}}>({sessionRating})</h3>
                        <h3 style={{alignSelf:'center', marginLeft:10, marginRight:10}}>{sessionNumberOfRatings} {sessionNumberOfRatings===1?"vote":"votes"}</h3>
                        {didUserSetNewRating && (
                          <div className="loading-icon-small-dark"></div>
                        )}
                      </div>
                      <div style={{display:"flex", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Starts:</span> {sessionState.plays==null?0:sessionState.plays}</h3>
                      </div>
                      <div style={{display:"flex", backgroundColor:"#F3F3F3", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Completes:</span> {sessionState.completes==null?0:sessionState.completes}</h3>
                      </div>
                      <div style={{display:"flex", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Favorites:</span> {sessionState.favorites}</h3>
                      </div>
                      <div style={{display:"flex", backgroundColor:"#F3F3F3", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Difficulty:</span> {sessionState.difficulty}</h3>
                      </div>
                      <div style={{display:"flex", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Media:</span> {sessionState.media_source}</h3>
                      </div>
                      <div style={{padding:10, backgroundColor:"#F3F3F3",}}>
                        {sessionState.genres?.length > 0 ? (
                          <h3><span style={{fontWeight:400}}>Genres:</span> {sessionState.genres.join(", ")}</h3>
                        ) : (
                          <h3><span style={{fontWeight:400}}>Genres:</span> None selected.</h3>
                        )}
                      </div>
                      <div style={{display:"flex", padding:10}}>
                        <h3 style={{alignSelf:'center'}}><span style={{fontWeight:400}}>Last Updated:</span> {convertEpochToDate(sessionState.updated._seconds)}</h3>
                      </div>
                      <div style={{display:"flex", padding:10, marginTop:10, backgroundColor:"#000", borderTopLeftRadius:4, borderTopRightRadius:4}}>
                        <h3 style={{color:"#ffffff", alignSelf:'center', fontWeight:400}}>What the coach says</h3>
                      </div>
                      <div style={{padding:10, backgroundColor:"#F3F3F3"}}>
                        <h3>Intro</h3>
                      </div>
                      {sessionState.start_voice_line.default !== true ? (
                      <div style={{padding:10}}>
                        <h3 style={{paddingBottom:10}}>{sessionState.start_voice_line.coach.name}: {sessionState.start_voice_line.string}</h3>
                        <audio style={{width:"100%"}} controls src={
                          process.env.REACT_APP_ENV === "development" 
                            ? sessionState.start_voice_line.online_location.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/")
                            : sessionState.start_voice_line.online_location
                        }></audio>
                      </div>
                      ):(
                        <div style={{padding:10}}>
                          <h3>Default (preloaded)</h3>
                        </div>
                      )}
                      <div style={{padding:10, backgroundColor:"#F3F3F3"}}>
                        <h3>Outro</h3>
                      </div>
                      {sessionState.start_voice_line.default !== true ? (
                      <div style={{padding:10}}>
                        <h3 style={{paddingBottom:10}}>{sessionState.end_voice_line.coach.name}: {sessionState.end_voice_line.string}</h3>
                        <audio style={{width:"100%"}} controls src={
                          process.env.REACT_APP_ENV === "development" 
                            ? sessionState.end_voice_line.online_location.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/")
                            : sessionState.end_voice_line.online_location
                        }></audio>
                      </div>
                      ):(
                        <div style={{padding:10}}>
                          <h3>Default (preloaded)</h3>
                        </div>
                      )}
                    </div>
                    <div style={{flex:1, marginLeft:5}}>
                      <h1 style={{marginTop:10}}>Session Timeline</h1>
                      {sessionState.segments.map((segment, index) => (
                        <div>
                          <div style={{display:"flex", padding:10, marginTop:10, backgroundColor:Constants.SEGMENT_TYPE_COLORS[segment.type], borderTopLeftRadius:4, borderTopRightRadius:4}}>
                            <h3 style={{color:"#ffffff", alignSelf:'center', fontWeight:400}}>{segment.type}: {millisecondsToHMSObject(segment.time*1000).h}:{millisecondsToHMSObject(segment.time*1000).m}:{millisecondsToHMSObject(segment.time*1000).s}</h3>
                          </div>
                          {segment.movements.map((movement, index) => (
                            <div>
                              <div style={{display:"flex", flexDirection:"row"}}>
                                <div style={{display:"flex", flex:1, flexDirection:"row", backgroundColor:"#000", alignSelf:'center', fontWeight:400, padding:10}}>
                                  <h3 style={{flex:1, color:"#fff"}}>Movement Time: {millisecondsToHMSObject(movement.time*1000).h}:{millisecondsToHMSObject(movement.time*1000).m}:{millisecondsToHMSObject(movement.time*1000).s}</h3>
                                </div>
                                <div style={{width:10, backgroundColor:Constants.SEGMENT_TYPE_COLORS[segment.type]}}>
                                </div>
                              </div>
                              {movement.intervals.map((interval, index) => (
                                <div style={{display:"flex", flexDirection:"row"}}>
                                  <div style={{width:10, backgroundColor:Constants.PACES_COLORS[interval.pace]}}>
                                  </div>
                                  <div style={{display:"flex", flex:1, flexDirection:"column", alignSelf:'center', fontWeight:400, padding:10, backgroundColor: index % 2 === 0 ? '#F3F3F3' : 'transparent'}}>
                                    <h3 key={index}>
                                      <span style={{fontWeight:500}}>{interval.pace}</span>: {millisecondsToHMSObject(interval.time.total_seconds*1000).h}:{millisecondsToHMSObject(interval.time.total_seconds*1000).m}:{millisecondsToHMSObject(interval.time.total_seconds*1000).s}
                                    </h3>
                                    <h3 style={{marginBottom:10}}>
                                      {interval.voiceline.coach.name===""?sessionState.coach_id.name:interval.voiceline.coach.name} says: {interval.voiceline.default===true?"Default":interval.voiceline.string}
                                    </h3>
                                    {interval.voiceline.default !== true && (
                                    <audio style={{width:"100%"}} controls src={
                                      process.env.REACT_APP_ENV === "development"
                                        ? interval.voiceline.online_location.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/")
                                        : interval.voiceline.online_location
                                    }></audio>
                                    )}
                                  </div>
                                  <div style={{width:10, backgroundColor:Constants.SEGMENT_TYPE_COLORS[segment.type]}}>
                                  </div>
                                </div>
                              ))}
                              {movement.playing_media && (
                              <div style={{display:"flex", flexDirection:"row"}}>
                                <div style={{width:10, backgroundColor:"#000"}}>
                                </div>
                                <div style={{padding:10, flex:1, display:"flex", flexDirection:"row", alignItems:'center'}}> 
                                  {sessionState.media_source === "Spotify" ? (
                                    <img src={movement.media.thumbnail} alt="thumbnail" style={{maxWidth:75}}></img>
                                  ):(
                                    <img src={movement.media.thumbnail?.default?.url} alt="thumbnail" style={{maxWidth:75}}></img>
                                  )}
                                  <div style={{marginLeft:10}}>
                                      <h3>{movement.media.title}</h3>
                                      <h3 style={{color:"#707070"}}>{movement.media.artist}</h3>
                                      {sessionState && sessionState.media_source === "Spotify" && (
                                        <h3 style={{color:"#707070"}}>{movement.media.description}</h3>
                                      )}
                                  </div>
                                </div>
                                <div style={{width:10, backgroundColor:Constants.SEGMENT_TYPE_COLORS[segment.type]}}>
                                </div>
                              </div>
                              )}
                              {index===segment.movements.length-1 && (
                              <div style={{height:10, flex:1, backgroundColor:Constants.SEGMENT_TYPE_COLORS[segment.type]}}>
                              </div>
                              )}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              </div>
            </div>
            {/* {(user.email==="test@test.com" || user.email==="whitnemp@gmail.com") && (
            <div style={{height:300, padding:10}}>
              <h3 style={{marginBottom:10}}>Runerval Sessions</h3>
              <HorizontalSessionList sessions={runervalSessions}></HorizontalSessionList>
            </div>
            )} */}
          </div>

        </div>
        <Footer></Footer>
      </div>
  );
};

export default Session;