import React from 'react';

function Tooltip({ children, text }) {
    const [isVisible, setIsVisible] = React.useState(false);
    const timeoutRef = React.useRef(null); // Using useRef to hold the timeout ID

    const handleMouseEnter = () => {
        // Set a timeout to make the tooltip visible after 2 seconds
        timeoutRef.current = setTimeout(() => {
            setIsVisible(true);
        }, 1000); // 2000 milliseconds = 2 seconds
    };

    const handleMouseLeave = () => {
        // Clear the timeout if the mouse leaves the component
        // This prevents the tooltip from showing if the mouse leaves before 2 seconds
        clearTimeout(timeoutRef.current);
        setIsVisible(false);
    };

    return (
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: 'relative', display: 'inline-block', zIndex: 99 }}
        >
            {children}
            {isVisible && (
                <h3 style={{
                    position: 'absolute',
                    bottom: '100%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    marginBottom: '5px',
                    backgroundColor: 'black',
                    color: 'white',
                    borderRadius: '5px',
                    padding: '5px',
                    whiteSpace: 'nowrap'
                }}>
                    {text}
                </h3>
            )}
        </div>
    );
}

export default Tooltip;
