import React, { useEffect, forwardRef } from 'react';

const YouTubeVideo = forwardRef(({ videoId }, ref) => {
  useEffect(() => {
    const initializePlayer = () => {
      ref.current = new window.YT.Player('player', {
        height: '390',
        width: '640',
        videoId: videoId,
      });
    };

    // Check if the YouTube IFrame API is already loaded
    if (window.YT && window.YT.Player) {
      initializePlayer();
    } else {
      // Load the IFrame Player API code asynchronously.
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = initializePlayer;
    }

    // Cleanup function to reset the ref
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
      ref.current = null;
    };
  }, [videoId, ref]);

  return <div id="player"></div>;
});

export default YouTubeVideo;