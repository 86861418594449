import React from 'react';
import {ReactComponent as IconAvailable} from '../assets/icons/available.svg';
import {ReactComponent as IconNotAvailable} from '../assets/icons/not_available.svg';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '2px',
    backgroundColor: 'lightgrey',
    margin: '20px',
    borderRadius: '5px',
    overflow: 'hidden',
    border: '2px solid lightgrey', // Added to ensure the very outside grey border is visible
  },
  header: {
    padding: '20px',
    backgroundColor: '#000', // Example header background color for Runerval and Runerval Pro
    color: 'white', // Header text color
    fontSize: '24px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  featureName: {
    backgroundColor: '#F8F8F8', // Feature column background color
    color: '#000', // Feature text color
    padding: '20px',
    textAlign: 'left', // Align feature names to the left
    fontSize: '18px',
    display:"flex", 
    alignItems:"center", 
    justifyItems:"center",
    maxWidth: 300
  },
  cell: {
    backgroundColor: 'white',
    padding: '20px',
    textAlign: 'center',
    fontSize: '18px',
    color:"#000",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    justifyItems:"center"
  }
};

const ComparisonGrid = () => {
  const features = [
    { name: 'Cost', free: 'Free', paid: '8.99/mo' },
    { name: 'Run public and Runerval built sessions', free: 'A', paid: 'A' },
    { name: 'Create custom sessions', free: 'NA', paid: 'A' },
    { name: 'Access to session builder in the browser', free: 'NA', paid: 'A' },
    { name: 'Generate custom AI audio guidelines', free: 'NA', paid: '10,000 characters/month' },
    { name: 'Running time per week', free: '90min', paid: 'Unlimited' },
    { name: 'Funny verbally abusive sessions... if you\'re into that.', free: 'NA', paid: 'A' },
  ];

  // Function to handle line breaks in feature names
  const renderWithLineBreaks = (text) => text.split('`').map((segment, index) => (
    <React.Fragment key={index}>
      {segment}
      {index < text.split('`').length - 1 && <br />}
    </React.Fragment>
  ));

  return (
    <div style={styles.container}>
      <div style={{...styles.header, textAlign:"left", backgroundColor:"#fff", color:"#000", gridRowStart: '1', gridRowEnd: '2'}}>Features</div>
      <div style={{...styles.header, gridColumnStart: '2'}}>Runerval</div>
      <div style={{...styles.header, gridColumnStart: '3', backgroundColor:"#17cf8b"}}>Runerval Pro</div>
      {features.map((feature, index) => (
        <React.Fragment key={index}>
          <div style={styles.featureName}>{renderWithLineBreaks(feature.name)}</div>
          <div style={styles.cell}>
            {feature.free==="NA"?(
              <IconNotAvailable fill="#ff0000" style={{width:40, height:40, marginRight:5}}></IconNotAvailable>
            ):(
              <div style={{height:50, display:"flex", alignItems:"center", justifyItems:"center"}}>
                  {feature.free==="A"?(
                      <IconAvailable fill="#17cf8b" style={{width:40, height:40, marginRight:5}}></IconAvailable>
                  ):(
                      feature.free
                  )}
              </div>
            )}
          </div>
          <div style={{...styles.cell}}>
            {feature.paid==="NA"?(
              <IconNotAvailable fill="#ff0000" style={{width:40, height:40, marginRight:5}}></IconNotAvailable>
            ):(
              <div style={{height:50, display:"flex", alignItems:"center", justifyItems:"center"}}>
                {feature.paid==="A"?(
                  <IconAvailable fill="#17cf8b" style={{width:40, height:40, marginRight:5}}></IconAvailable>
                ):(
                  feature.paid
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ComparisonGrid;