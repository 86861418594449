import React, { useContext } from 'react';
import './SessionCard.css';
import { millisecondsToHMSObject } from '../utils/Utils';
import {ReactComponent as IconEdit} from '../assets/icons/edit.svg';
import {ReactComponent as IconViews} from '../assets/icons/views.svg';
import {ReactComponent as IconCompletes} from '../assets/icons/completes.svg';
import {ReactComponent as IconMedia} from '../assets/icons/media.svg';
import {ReactComponent as IconDifficulty} from '../assets/icons/difficulty.svg';
import {ReactComponent as IconDuplicate} from '../assets/icons/copy.svg';
import {ReactComponent as IconExplicit} from '../assets/icons/explicit.svg';
import * as Constants from '../constants'
import {SessionCardStripedBackground} from './SessionCardStripedBackground';
import { SessionContext } from '../App'; // Adjust the path as needed
import Tooltip from './Tooltip';
import { useNavigate } from 'react-router-dom';


export const SessionCard = ({ session, card }) => {
    //get user
    const { user } = useContext(SessionContext);
    const navigate = useNavigate();
    const handleNavigation = (path, state) => {
      navigate(path, { state: state });
    };
    //loop through the sessions'segments'movements and create an array of intervals
    let intervals = []
    let segments = []
    session.segments.forEach(segment => {
      segments.push({time: segment.time, type: segment.type, color: Constants.SEGMENT_TYPE_COLORS[segment.type]})
      segment.movements.forEach(movement => {
        movement.intervals.forEach(interval => {
          intervals.push({time: interval.time.total_seconds, color: Constants.PACES_COLORS[interval.pace]})
        })
      })
    });
    function formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }

    return (
      // <Link className="button" to={{
      //   pathname: "/session",
      //   search: `?id=${session.id}-author-${session.author}`,  // Optional: Keep it if you want to pass the session ID in the URL as well 
      // }} state={{ session: session }}
      // >
        <div style={{height:"100%", display:"flex", flexDirection:"column", width:380}} onClick={() => handleNavigation(`/session?id=${session.id}-author-${session.author}`, { session: session })}>
          <SessionCardStripedBackground card={card} intervals={intervals} segments={segments}>
            <div style={{ padding:10, display: "flex", position: "relative", zIndex: 1, flex:1, justifyContent:"center"}}>
              <div>
                <img alt="coach" src={session.coach_id.photo} style={{width:50, height:50, borderRadius:50, borderWidth:4, borderColor:"#000", borderStyle:"solid"}}></img>
                <h4 style={{fontWeight:800, color:"#fff", padding:3, backgroundColor:"#000", textAlign:"center", borderRadius:5, marginTop:-10}}>{session.coach_id.name}</h4>
              </div>
              <div style={{ flex: 1, color: "#fff", justifyContent:"center", marginLeft:10, alignSelf:"center" }}>
                <h1 className="time" style={{ flex: 1, color: "#fff", alignSelf:"center" }}>
                  {millisecondsToHMSObject(session.time*1000).h}:{millisecondsToHMSObject(session.time*1000).m}:{millisecondsToHMSObject(session.time*1000).s}
                </h1>
                <h3 style={{color:"#fff", marginTop:-5}}>{formatDate(session.date_created)}</h3>
              </div>
              {user && (
                <div>
                  <Tooltip text="Edit Session">
                    <IconEdit fill="#fff" style={{width:25, height:25}} onClick={(e) => {
                        e.stopPropagation(); // Prevent the outer div click from firing
                        handleNavigation(`/build-session?id=${session.id}`, { session: session });
                    }}/>
                  </Tooltip>
                  <Tooltip text="Duplicate Session">
                    <IconDuplicate fill="#fff" style={{width:25, height:25}} onClick={(e) => {
                        e.stopPropagation();
                        handleNavigation(`/build-session?id=${session.id}&duplicate=true`, { session: session });
                    }}/>
                  </Tooltip>
                </div>
              )}
            </div>
          </SessionCardStripedBackground>
          <div className="info-wrapper">
            <h3 style={{whiteSpace:"nowrap", overflow:"hidden", textOverflow:"ellipsis"}}>{session.title}</h3>
            <div style={{display:"flex", justifyContent:"space-between", marginTop:10, alignItems:'center'}}>
              <Tooltip text="Runners Started">
                <h3 style={{display:"flex"}}>
                    <IconViews fill="#000" style={{width:25, height:25, marginRight:5}}></IconViews>
                    <div style={{alignSelf:'center'}}>{session.plays==null?0:session.plays}</div>
                </h3>
              </Tooltip>
              <Tooltip text="Runners Finished">
                <h3 style={{display:"flex"}}>
                  <IconCompletes fill="#000" style={{width:25, height:25, marginRight:5}}></IconCompletes>
                  <div style={{alignSelf:'center'}}>{session.completes==null?0:session.completes}</div>
                </h3>
              </Tooltip>
              <Tooltip text="Difficulty">
                <h3 style={{display:"flex"}}>
                  <IconDifficulty fill="#000" style={{width:25, height:25, marginRight:5}}></IconDifficulty>
                  <div style={{alignSelf:'center'}}>{session.difficulty}</div>
                </h3>
              </Tooltip>
              <Tooltip text="Media Source">
                <h3 style={{display:"flex"}}>
                  <IconMedia fill="#000" style={{width:25, height:25, marginRight:5}}></IconMedia>
                  <div style={{alignSelf:'center'}}>{session.media_source}</div>
                </h3>
              </Tooltip>
              {session.explicit && (
                <Tooltip text="Coach says curse words.">
                  <div style={{backgroundColor:"#ff0000", borderRadius:5}} title="Coach says curse words.">
                    <IconExplicit fill="#ffffff" style={{width:25, height:25, display: 'block', margin: 0, padding: 0}}></IconExplicit>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      // </Link>
    );
  }

  
  