import React, { useState, useRef } from 'react';
// import { SessionContext } from '../App'; // Adjust the path as needed
import '../App.css';
import Footer from '../components/Footer';
import Dropdown from '../components/Dropdown';
import * as Constants from '../constants'
import { universalFetchPublic } from '../utils/Utils';
import SessionCardLoading from '../components/SessionCardLoading';
import { SessionCard } from '../components/SessionCard';

const BrowseSessions = () => {
  const [somethingLoading] = useState(false);
  const [loadingMessage] = useState("");
  const [sessionTitle, setSessionTitle] = useState("");
  const [filterSessionTime, setFilterSessionTime] = useState("");
  const [filterSessionMedia, setFilterSessionMedia] = useState("");
  const [filterSessionGenre, setFilterSessionGenre] = useState("");
  const [filterSessionDifficulty, setFilterSessionDifficulty] = useState("");
  const [filterSessionSortBy, setFilterSessionSortBy] = useState("date_created");
  const [filterSessionSortByAscDesc, setFilterSessionSortByAscDesc] = useState("desc");
  const [filterSessionsByCoach, setFilterSessionsByCoach] = useState("");
  const [areWeFetchingSessions, setAreWeFetchingSessions] = useState(false);
  const [canWeGetMore, setCanWeGetMore] = useState(true);
  const [canWeGetLess, setCanWeGetLess] = useState(false);
  let currentPage = useRef(1)
  // const [currentPageState, setCurrentPageState] = useState(currentPage.current);
  // const [lastDocId, setLastDocId] = useState(null); // For pagination
  const [fetchedSessions, setFetchedSessions] = useState([]);
  let lastDateCreated = useRef()
  const difficulties =  [...Constants.DIFFICULTIES, "Any"].map(difficulty => ({
    label: difficulty,
    value: difficulty==="Any"?"":difficulty,
    type: "difficulty"
  }));
  const quick_build_time = ["<5", "5-10", "10-15", "15-20", "20-25", "25-30", "30-35", "35-40", "40-45", "45-50", "50-55", "55-60", "60-65", "Any"].map(timeRange => {
    // Handle the special case of "Any"
    if (timeRange === "Any") {
      return {
        label: timeRange,
        value: "", // No specific value for "Any"
        type: "quick_build_time"
      };
    }
    
    // Handle the special case of "<5"
    if (timeRange === "<5") {
      return {
        label: timeRange,
        value: "0-299", // Representing 0 to less than 5 minutes in seconds
        type: "quick_build_time"
      };
    }
    
    // Handle normal ranges like "5-10"
    const [start, end] = timeRange.split("-").map(Number); // Split the range and convert to numbers
    return {
      label: timeRange,
      value: `${start * 60}-${end * 60}`, // Convert minutes to seconds and format as a range string
      type: "quick_build_time"
    };
  });
  
  const media_sources =  [...Constants.MEDIA_SOURCES, "Any"].map(media_sources => ({
    label: media_sources,
    value: media_sources==="Any"?"":media_sources,
    type: "media_source"
  }));
  const coaches =  [...Constants.COACHES, "Any"].map(coaches => ({
    label: coaches==="Any"?"Any":coaches.name,
    value: coaches==="Any"?"":coaches.id,
    type: "coaches"
  }));
  const genres =  [...Constants.GENRES, "Any"].map(genres => ({
    label: genres,
    value: genres==="Any"?"":genres,
    type: "genre"
  }));
  const sort_by =  ["date_created", "completes", "plays", "favorites", "rating"].map(sort_by => ({
    label: sort_by==="date_created"?"Date Created":sort_by==="completes"?"Completes":sort_by==="plays"?"Views":sort_by==="favorites"?"Favorites":"Rating",
    value: sort_by,
    type: "sort_by"
  }));
  const asc_desc =  ["Descending", "Ascending"].map(asc_desc => ({
    label: asc_desc,
    value: asc_desc==="Ascending"?"asc":"desc",
    type: "asc_desc"
  }));
  const handleSelect = async (option, intervalIndex) => {
    if(option.type === "difficulty") {
      setFilterSessionDifficulty(option.value);
    }
    if(option.type === "quick_build_time") {
      setFilterSessionTime(option.value);
    }
    if(option.type === "media_source") {
      setFilterSessionMedia(option.value);
    }
    if(option.type === "genre") {
      setFilterSessionGenre(option.value);
    }
    if(option.type === "sort_by") {
      setFilterSessionSortBy(option.value);
    }
    if(option.type === "asc_desc") {
      setFilterSessionSortByAscDesc(option.value);
    }
    if(option.type === "coaches") {
      setFilterSessionsByCoach(option.value);
    }
  };
  const handleSearchTitleSubmit = async (event) => {
    event.preventDefault();
    console.log("searching for title", sessionTitle);
    setAreWeFetchingSessions(true);
    currentPage.current = 1;
    await fetchSessionsFromSearch()
    setAreWeFetchingSessions(false);
  };
  async function fetchSessionsFromSearch() {
    console.log("lastDocId", lastDateCreated.current)
    //get user IP for rate limiting and shit
    let ip;
    try{
      ip = await universalFetchPublic(process.env.REACT_APP_GET_IP, "GET", null)
    }catch(e){
      alert(e)
    }
    try {
      let body = {
        title: sessionTitle,
        coach: filterSessionsByCoach,
        difficulty: filterSessionDifficulty,
        time: filterSessionTime,
        media_source: filterSessionMedia,
        genre: filterSessionGenre,
        sort_by: filterSessionSortBy,
        asc_desc: filterSessionSortByAscDesc,
        offset: (currentPage.current-1) * 9
      };
      
      // Iterate over the keys of the object and delete any keys with null or empty string values
      Object.keys(body).forEach(key => {
        if (body[key] === null || body[key] === "") {
          delete body[key];
        }
      });
      let get_sessions = await universalFetchPublic(process.env.REACT_APP_SEARCH_SESSIONS+"?ip="+ip.ip, "POST", body)
      let sessionIds = get_sessions.map((session) => {
        if(session.author === "runerval"){
          session.author = "Runerval"
        }
        return session.id + "-author-" + session.author;
      }).join(",");
      let get_sessions_from_fb = await universalFetchPublic(process.env.REACT_APP_GET_SESSION_BY_ID+"?ip="+ip.ip+"&id="+sessionIds, "GET")
      console.log(get_sessions_from_fb)
      if(get_sessions_from_fb.length > 0){
        if(get_sessions_from_fb.length > 9){
          get_sessions_from_fb.pop(); //remove last item from array becuase we are not showing it... only using it to see if there are more sessions on the next page.
          setCanWeGetMore(true)
        }else{
          setCanWeGetMore(false)
        }
        if(currentPage.current > 1){
          setCanWeGetLess(true)
        }else{
          setCanWeGetLess(false)
        }
       

        setFetchedSessions(get_sessions_from_fb);
      }else{
        alert("No sessions found.")
      }
    } catch (error) {
      alert("No sessions found.")
      console.log("Error fetching sessions:", error);
    }

  }

  return (
    <div>
      {somethingLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
          <div className="loading-icon"></div>
          <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
        </div>
      )}
      <div style={{display: "flex", flexDirection: "column", minHeight:"calc(100vh - 178px)"}}>
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", flexGrow: 1, backgroundColor:"#F3F3F3", height:"100%"}}>
          <div style={{maxWidth:1200, width:"100%", backgroundColor:"#fff", flexGrow: 1}}>
            <div style={{width:"calc(100% - 20px)", padding:10}}>
              <h3 style={{marginTop:10}}>Optional Filters:</h3>
              <div style={{flex:1}}>
                <div style={{display:"grid", gridTemplateColumns: "repeat(7, 1fr)", gap:10, flexDirection:"row", marginTop:6}}>
                  <Dropdown options={difficulties} onSelect={handleSelect} title="Difficulty" selectedValue={filterSessionDifficulty}/>
                  <Dropdown options={quick_build_time} onSelect={handleSelect} title="Session Length (min)" selectedValue={filterSessionTime}/>
                  <Dropdown options={media_sources} onSelect={handleSelect} title="Media Source" selectedValue={filterSessionMedia}/>
                  <Dropdown options={coaches} onSelect={handleSelect} title="Coach" selectedValue={filterSessionsByCoach}/>
                  <Dropdown options={genres} onSelect={handleSelect} title="Genre" selectedValue={filterSessionGenre}/>
                  <Dropdown options={sort_by} onSelect={handleSelect} title="Sort By" selectedValue={filterSessionSortBy}/>
                  <Dropdown options={asc_desc} onSelect={handleSelect} title="New/Old" selectedValue={filterSessionSortByAscDesc}/>
                </div>
              </div>
              <form onSubmit={(e)=>{
                handleSearchTitleSubmit(e)
                lastDateCreated.current = null;
                }}>
                <div style={{display:"flex"}}>
                  <div style={{flex:1, marginRight:10}}>
                    <h4 className='input-title'>Session Title</h4>
                    <input type="text" placeholder="Search all titles " value={sessionTitle} onChange={e => setSessionTitle(e.target.value)} />
                  </div>
                  <button style={{marginTop:6}} type="submit" className="medium">Search</button>
                </div>
              </form>
              {areWeFetchingSessions ? (
                <div style={{display:"grid", gap:"10px", marginTop:"10px", gridTemplateColumns:"repeat(3, 1fr)"}}>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                  <SessionCardLoading></SessionCardLoading>
                </div>
              ):(
                <div>
                  <div style={{display:"grid", gap:"10px", gridTemplateColumns:"repeat(3, 1fr)", marginTop:"10px"}}>
                    {fetchedSessions.length === 0 && (
                      <div style={{marginTop:20}}>
                        <h3>No sessions found</h3>
                      </div>
                    )}
                    {fetchedSessions.map((session, index) => (
                      <SessionCard key={index} session={session}></SessionCard>
                    ))}
                  </div>
                  <div style={{display:'flex', marginTop:10}}>
                    {canWeGetLess ? (
                      <button style={{flex:1, marginRight:5}} className='medium' onClick={async ()=>{
                        setAreWeFetchingSessions(true);
                        currentPage.current--
                        // setCurrentPageState(currentPage.current)
                        await fetchSessionsFromSearch()
                        setAreWeFetchingSessions(false);
                      }}>Previous</button>
                    ):(
                      <button style={{flex:1, marginRight:5}} className='medium disabled' onClick={
                        ()=>{
                          alert("There are no more sessions with the chosen criteria.")
                        }}>Previous</button>
                    )}
                    {canWeGetMore ? (
                      <button style={{flex:1, marginLeft:5}} className='medium' onClick={async ()=>{
                        setAreWeFetchingSessions(true);
                        currentPage.current++
                        // setCurrentPageState(currentPage.current)
                        await fetchSessionsFromSearch()
                        setAreWeFetchingSessions(false);
                      }}>More</button>
                    ):(
                      <button style={{flex:1, marginLeft:5}} className='medium disabled' onClick={
                        ()=>{
                          alert("There are no more sessions with the chosen criteria.")
                        }}>More</button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BrowseSessions;