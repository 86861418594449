import React, { useContext } from 'react';
import { SessionContext } from '../App'; // Adjust the path as needed

const About = () => {
  const { user } = useContext(SessionContext);

  return (
    <div>
      <h1>About Page</h1>
      {user ? (
        <p>Welcome, {user.displayName || user.email}!</p>
      ) : (
        <p>You are not logged in.</p>
      )}
    </div>
  );
};

export default About;