import React, { useState } from 'react';
import {login} from '../utils/Auth';
import { Link } from 'react-router-dom';


function LoginForm({onLoading}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [didFail, setDidFail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    onLoading(true, "Logging in...")
    // Handle login logic here
    try{
      await login(email, password);
    }catch(error){
      setDidFail(true);
      console.log(error);
      console.log(error.message)
      if(error.message === "Firebase: Error (auth/user-disabled).") {
        setErrorMessage("Email unverified.")
      }else{
        setErrorMessage("Credentials invalid.")
      }
      // alert(error.message)
    }
    onLoading(false, "Logging in...")
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Login</h2>
      <div>
        {didFail && <h3 style={{color:"red"}}>{errorMessage}</h3>}
        <h4 className='input-title'>Email</h4>
        <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
        {/* <label>
          Email:
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
        </label> */}
      </div>
      <div>
        <h4 className='input-title'>Password</h4>
        <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
      </div>
      <div style={{display:"flex", flexDirection:"row"}}>
        <button className="medium" style={{marginTop:10}} type="submit">Login</button>
        <div style={{flex:1}}></div>
        <Link to="/signup">
         <button className="medium" style={{marginTop:10}} type="submit">Register</button>
        </Link>
      </div>
    </form>
  );
}

export default LoginForm;