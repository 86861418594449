import NavigationProfile from '../components/NavigationProfile'; // Adjust the path as needed
import Footer from '../components/Footer';
import React, { useContext, useEffect, useRef } from 'react';
import LoginForm from "../components/LoginForm";
import { SessionContext } from '../App'; // Adjust the path as needed
import {ReactComponent as BadgeApple} from '../assets/images/badge_apple.svg';
import googleBadge from '../assets/images/badge_google.png';
import {fetchDataWithTokenRefresh} from '../utils/Utils';
import { SessionCard } from '../components/SessionCard';
import { universalFetchPublic } from '../utils/Utils';
import SessionCardLoading from '../components/SessionCardLoading';
import { Link } from 'react-router-dom';
import './Profile.css';
// import { useNavigate } from 'react-router-dom';

// let global_startAfter = null;
// let global_sortBy = "date_created";

function Profile() {
  const { user, setUser } = useContext(SessionContext);
  const { token, setToken } = useContext(SessionContext);
  const [sessions, setSessions] = React.useState([]);
  const [latestSessions, setLatestSessions] = React.useState([]); // [TODO: Add the latest runs here
  // const [runervalSessions, setRunervalSessions] = React.useState([]); // [TODO: Add the runerval sessions here
  const [somethingLoading, setSomethingLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");
  const [startAfter, setStartAfter] = React.useState(null);
  const [startAfterFirst, setStartAfterFirst] = React.useState(null);
  const [sortBy] = React.useState("date_created");
  const [isThereMoreUserSessions, setIsThereMoreUserSessions] = React.useState(true);
  const [userSessionsPage, setUserSessionsPage] = React.useState(1);
  const [latestSessionsPage, setLatestSessionsPage] = React.useState(1);
  const [lastSessionTimeEnded, setLastSessionTimeEnded] = React.useState(null);
  const [firstSessionTimeEnded, setFirstSessionTimeEnded] = React.useState(null);
  const [isThereMoreLatestSessions, setIsThereMoreLatestSessions] = React.useState(true);
  const [fetchingUserSessions, setFetchingUserSessions] = React.useState(true);
  const [fetchingLatestSessions, setFetchingLatestSessions] = React.useState(true);
  // const [isFavoritesToggled, setIsFavoritesToggled] = React.useState(false);
  const [mySessionsViewType, setMySessionsViewType] = React.useState("all"); // ["all", "favorites"]

  const limit = 6;
  let startIndex = useRef(0);
  let isFavoritesToggledRef = useRef(false);
  let mySessionsViewTypeRef = useRef("all");
  // let favoritesStartAfter = null;

  const handleLoading = (loading, message) => {
    setSomethingLoading(loading);
    setLoadingMessage(message);
  }
  async function requestUsersSessions(pagination) {
    setFetchingUserSessions(true);
    try{
      
      let endpoint = `/api/sessions?authorId=${user.uid}&limit=${limit + 1}&sortBy=${sortBy}`
      // Append the `startAfter` parameter for pagination if applicable
      if(pagination==="next"){
        if (startAfter) {
          endpoint += `&startAfter=${startAfter}`;
        }
      }else if(pagination==="previous"){
        if (startAfterFirst) {
          endpoint += `&startBefore=${startAfterFirst}`;
        }
      }
      let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+endpoint, "GET", null, token, setToken)
      if(get_sessions.length > 0){
        // Determine if there are more sessions to load
        let isThereMore = get_sessions.length > limit;
        // If there are more sessions than the limit, remove the extra session used for the check
        if (isThereMore) {
          get_sessions.pop(); // Remove the extra session used to check for more items
        }

        console.log(get_sessions, "get_sessions")
        setStartAfter(get_sessions[get_sessions.length - 1].id+"-author-"+get_sessions[get_sessions.length - 1].author);
        setStartAfterFirst(get_sessions[0].id+"-author-"+get_sessions[0].author);
        setSessions(get_sessions)
        setIsThereMoreUserSessions(isThereMore);
      }
      
    }catch(error) {
      alert("Error fetching sessions")
      console.log(error)
    }
    console.log("firng?")
    setFetchingUserSessions(false);
  }

  async function fetchUserFavorites(previous_or_next) {
    setFetchingUserSessions(true);
    let endpoint;
    if(previous_or_next === "next"){
      endpoint = process.env.REACT_APP_FETCH_FAVORITES + "?pageSize="+(limit+1)+"&startIndex="+startIndex.current;
    }else if(previous_or_next === "previous"){
      const startIndexConst = parseInt(startIndex.current) - limit*2;
      console.log(startIndex, "startIndex")
      console.log(startIndexConst, "startIndexConst")
      endpoint = process.env.REACT_APP_FETCH_FAVORITES + "?pageSize="+(limit+1)+"&startIndex="+startIndexConst;
    }else{
      endpoint = process.env.REACT_APP_FETCH_FAVORITES + "?pageSize="+(limit+1);
    }
    console.log(endpoint)
    let favorites = await fetchDataWithTokenRefresh(endpoint, "GET", null, token, setToken)
    console.log(favorites, "favorites")
    console.log(favorites.sessionData)
    favorites.sessionData.length > limit ? setIsThereMoreUserSessions(true) : setIsThereMoreUserSessions(false);
    if(favorites.sessionData.length === limit + 1){
      favorites.sessionData.pop();
    }
    setSessions(favorites.sessionData)
    startIndex.current = favorites.startIndex;
    setFetchingUserSessions(false);
  }

    // Assuming `lastSessionTimeEnded` is managed outside this function and initially set to an empty string or null
  // For the first call, it would not append the `startAfter` parameter, effectively fetching the first page
  async function requestUsersRecentSessions(pagination) {
    setFetchingLatestSessions(true);
    try {
      let ipResponse = await universalFetchPublic(process.env.REACT_APP_GET_IP, "GET", null);
      let ip = ipResponse.ip;

      // Construct the base endpoint URL
      let endpoint = `${process.env.REACT_APP_GET_DATA}/api/runHistory?runnerId=${user.uid}&latest=${limit+1}`;

      // Append the `startAfter` parameter for pagination if applicable
      if(pagination==="next"){
        if (lastSessionTimeEnded) {
          endpoint += `&startAfter=${lastSessionTimeEnded}`;
        }
      }else if(pagination==="previous"){
        if (firstSessionTimeEnded) {
          endpoint += `&startBefore=${firstSessionTimeEnded}`;
        }
      }
      // Fetch the latest session IDs based on the endpoint constructed
      let latest_sessions_ids = await fetchDataWithTokenRefresh(endpoint, "GET", null, token, setToken);
      // Determine if there are more sessions to load
      let isThereMore = latest_sessions_ids.length > limit;
      setIsThereMoreLatestSessions(isThereMore);
      // If there are more sessions than the limit, remove the extra session used for the check
      if (isThereMore) {
        latest_sessions_ids.pop(); // Remove the extra session used to check for more items
      }

      // Update `lastSessionTimeEnded` for pagination in subsequent requests
      if (latest_sessions_ids.length > 0) {
        setLastSessionTimeEnded(latest_sessions_ids[latest_sessions_ids.length - 1].timeEnded)
        setFirstSessionTimeEnded(latest_sessions_ids[0].timeEnded)
        // Assuming you need to use these session IDs to fetch more detailed data for each session
        let session_ids = latest_sessions_ids.map(session => session.runId + "-author-" + user.uid).join(",");

        // Fetch detailed session data using the session IDs
        let detailedEndpoint = `${process.env.REACT_APP_GET_SESSION_BY_ID}?ip=${ip}&id=${session_ids}`;
        let latest_sessions = await fetchDataWithTokenRefresh(detailedEndpoint, "GET", null, token, setToken);

        // Update your state or UI with the fetched session data
        setLatestSessions(latest_sessions);
      }

      
    } catch (error) {
      alert("Error fetching runs");
      console.error(error); // Use console.error for errors
    }
    setFetchingLatestSessions(false);
  }

  async function requestAllProfileData() {
    // setSomethingLoading(true)
    // setLoadingMessage("Fetching profile data...")
    await requestUsersSessions();
    await requestUsersRecentSessions();
    //fetching user's profile data

    // if(user.email==="test@test.com" || user.email==="whitnemp@gmail.com"){
    //   try{
    //     let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/sessions?authorId=Runerval", "GET", null, token, setToken)
    //     setRunervalSessions(get_sessions)
    //   }catch(error) {
    //     alert("Error fetching sessions")
    //     console.log(error)
    //   }
    // }
    // setSomethingLoading(false)
  }
  useEffect(() => {
    if(user){
      requestAllProfileData();
    }
    return () => {
      setStartAfter(null);
      setIsThereMoreUserSessions(true);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps


    return (
      <div>
        {somethingLoading && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
            <div className="loading-icon"></div>
            <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
          </div>
        )}
        <div style={{display: "flex", flexDirection: "column", minHeight:"calc(100vh - 178px)"}}>
          {user ? (
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", flexGrow: 1, backgroundColor:"#F3F3F3", height:"100%"}}>
              <NavigationProfile user={user} token={token} setUser={setUser} settoken={setToken}></NavigationProfile>
              <div style={{maxWidth:1200, width:"100%", backgroundColor:"#fff", flexGrow: 1}}>
                <div style={{width:"calc(100% - 20px)", padding:10}}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginBottom:10}}>
                    <h2 style={{justifySelf:"center", marginRight:10}}>My Sessions</h2>
                    <button style={{justifySelf:"center", marginRight:10, backgroundColor:mySessionsViewType === "all"?null:"#888888"}} className="small" onClick={async ()=>{
                        setUserSessionsPage(1);
                        // setIsFavoritesToggled(!isFavoritesToggled);
                        setMySessionsViewType("all");
                        await requestUsersSessions();
                        isFavoritesToggledRef.current = !isFavoritesToggledRef.current;
                        mySessionsViewTypeRef.current = "all";
                    }}>
                        All
                    </button>
                    <button style={{justifySelf:"center", backgroundColor:mySessionsViewType==="favorites"?null:"#888888"}} className="small" onClick={async ()=>{
                        setUserSessionsPage(1);
                        // setIsFavoritesToggled(!isFavoritesToggled);
                        setMySessionsViewType("favorites");
                        await fetchUserFavorites();
                        isFavoritesToggledRef.current = !isFavoritesToggledRef.current;
                        mySessionsViewTypeRef.current = "favorites";
                    }}>
                        Favorites
                    </button>
                  </div>
                  {!fetchingUserSessions ? (
                    <div>
                      {sessions.length===0 ? (
                        <div>
                          {mySessionsViewType==="all" ? (
                            <>
                                <h3>You have not built any sessions.</h3>
                                {(user.product==="free" || user.product===null || user.product===undefined) ? (
                                  <h3>Build sessions here in the browser with <span style={{color:"#17cf8b"}}>Runerval Pro</span>. Upgrade in the app.</h3>
                                ):(
                                  <Link to="/build-session">
                                    <button className="medium">Build Now</button>
                                  </Link>
                                )}
                            </>
                          ):(
                            <h3>You have no favorites. Click on the star at the top of a session's details page to favorite a session.</h3>
                          )}
                        </div>
                      ):(
                        <div style={{display:"flex", flexDirection:"column"}}>
                          {!fetchingUserSessions ? (
                            <>
                            {sessions.length > 0 && (
                            <div className="grid-container">
                              {sessions.map((session, index) => (
                                <SessionCard  card={true} key={index} session={session} />
                              ))}
                            </div>
                            )}
                            </>
                          ):(
                            <div className="grid-container">
                              {[1,1,1,1,1,1].map(() => (
                                <SessionCardLoading/>
                              ))}
                            </div>
                          )}
                          {(sessions.length < 4 && !fetchingUserSessions) && (
                            <div style={{height:200}}></div>
                          )}
                          <div style={{display:"flex", flexDirection:"row"}}>
                            <div style={{flex:1, marginTop:10}}>
                              <h3>Page: {userSessionsPage}</h3>
                            </div>
                            {userSessionsPage > 1 ? (
                              <button className="medium" style={{marginTop:10, marginRight:10}} onClick={async ()=>{
                                if(mySessionsViewType==="favorites"){
                                  await fetchUserFavorites("previous")
                                }else{
                                  await requestUsersSessions("previous")
                                }
                                setUserSessionsPage(userSessionsPage-1)
                              }}>Previous</button>
                            ):(
                              <button style={{marginTop:10, marginRight:10}} className={`medium disabled`}>Previous</button>
                            )}
                            {isThereMoreUserSessions ? (
                              <button className="medium" style={{marginTop:10}} onClick={async ()=>{
                                if(mySessionsViewType==="favorites"){
                                  await fetchUserFavorites("next")
                                }else{
                                  await requestUsersSessions("next")
                                }
                                setUserSessionsPage(userSessionsPage+1)
                              }}>More</button>
                            ):(
                              <button style={{marginTop:10}} className={`medium disabled`}>More</button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ):(
                    <div className="grid-container">
                      {[1,1,1,1,1,1].map((card, index) => (
                        <SessionCardLoading key={index}/>
                      ))}
                    </div>
                  )}
                  <h2 style={{marginBottom:10, marginTop:10}}>Latest Started/Completed</h2>
                  {!fetchingLatestSessions ? (
                    <div>
                    {latestSessions.length===0 ? (
                      <div>
                        <h3>You have never started a session. Time to get to work! Start a session in the app.</h3>
                      </div>
                    ):(
                      <div style={{display:"flex", flexDirection:"column"}}>
                        <div>
                          {!fetchingLatestSessions ? (
                            <div className="grid-container">
                              {latestSessions.map((latestSessions, index) => (
                                <SessionCard card={true} key={index} session={latestSessions} />
                              ))}
                            </div>
                          ):(
                            <div className="grid-container">
                              {[1,1,1,1,1,1].map((card, index) => (
                                <SessionCardLoading key={index}/>
                              ))}
                            </div>
                          )}
                        </div>
                        {(latestSessions.length < 4 && !fetchingLatestSessions) && (
                          <div style={{height:200}}></div>
                        )}
                        <div style={{display:"flex", flexDirection:"row"}}>
                          <div style={{flex:1, marginTop:10}}>
                            <h3>Page: {latestSessionsPage}</h3>
                          </div>
                          {latestSessionsPage > 1 ? (
                            <button className="medium" style={{marginTop:10, marginRight:10}} onClick={async ()=>{
                              await requestUsersRecentSessions("previous")
                              setLatestSessionsPage(latestSessionsPage-1)
                            }}>Previous</button>
                          ):(
                            <button style={{marginTop:10, marginRight:10}} className={`medium disabled`}>Previous</button>
                          )}
                          {isThereMoreLatestSessions ? (
                            <button className="medium" style={{marginTop:10}} onClick={async ()=>{
                              await requestUsersRecentSessions("next")
                              setLatestSessionsPage(latestSessionsPage+1)
                            }}>More</button>
                          ):(
                            <button style={{marginTop:10}} className={`medium disabled`}>More</button>
                          )}
                        </div>
                      </div>
                    )}
                    </div>
                  ):(
                    <div className="grid-container">
                      {[1,1,1,1,1,1].map((card, index) => (
                        <SessionCardLoading key={index}/>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* {(user.email==="test@test.com" || user.email==="whitnemp@gmail.com") && (
              <div style={{height:300, padding:10}}>
                <h3 style={{marginBottom:10}}>Runerval Sessions</h3>
                <HorizontalSessionList sessions={runervalSessions}></HorizontalSessionList>
              </div>
              )} */}
            </div>
          ):(
          <div style={{display:"flex", justifyContent:"center"}}>
            <div style={{maxWidth:1200, marginTop:100, minWidth:400}}>
              <LoginForm onLoading={handleLoading}></LoginForm>
              <h2 style={{marginTop:20}}>Download and register within the app:</h2>
              <div style={{display:"flex", height:100}}>
                <BadgeApple style={{flex:.9, height:100, marginRight:5}}></BadgeApple>
                <div style={{backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundImage: `url(${googleBadge})`, flex:1, height:100, marginLeft:5, backgroundSize:"113%"}}></div>
              </div>
            </div>
          </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    );
}

export default Profile;