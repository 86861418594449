export const COACHES =  [
    {
        id: "IoRoGwBUb9KXuqqMtBKe",
        name: "Myra",
        photo: "https://storage.googleapis.com/runerval-a6a0b.appspot.com/coachProfileImages/Myra.jpeg"
    },
    {
        id: "VCk0kR1XzKkw2IABfxOV",
        name: "Leon",
        photo: "https://storage.googleapis.com/runerval-a6a0b.appspot.com/coachProfileImages/Leon.jpeg"
    }
];
export const DIFFICULTIES = [
    "Beginner",
    "Intermediate",
    "Advanced",
    "Elite"
];

export const GENRES = [
    "Alternative Rock",
    "Baroque Classical",
    "Bluegrass",
    "Blues",
    "Classical",
    "Country",
    "Delta Blues",
    "Electronic",
    "Electronic Dance",
    "Folk",
    "Funk",
    "Gospel",
    "Hip-Hop/Rap",
    "Indie",
    "Jazz",
    "Latin",
    "Metal",
    "Neo-Soul",
    "Pop",
    "Pop Punk",
    "Punk",
    "R&B/Soul",
    "Reggae",
    "Reggaeton",
    "Rock",
    "Ska",
    "Smooth Jazz",
    "Techno",
    "Trap"
];

export const HOURS_ARRAY = [0, ...Array.from({ length: 25 }, (_, index) => index + 1)];
export const HOURS_ARRAY_STRING = HOURS_ARRAY.map((val)=>{return val.toString().padStart(2, '0')})
export const MINUTES_ARRAY = [0, ...Array.from({ length: 60 }, (_, index) => index + 1)];
export const MINUTES_ARRAY_STRING = MINUTES_ARRAY.map((val)=>{return val.toString().padStart(2, '0')})
export const SECONDS_ARRAY = [0, ...Array.from({ length: 60 }, (_, index) => index + 1)];
export const SECONDS_ARRAY_STRING = SECONDS_ARRAY.map((val)=>{return val.toString().padStart(2, '0')})

export const LIMITS = {
    voiceline_characters: 10000
}

export const MEDIA_SOURCES = [
    "None",
    "YouTube",
    "Spotify"
];
export const PACES = [
    "Walk",
    "Jog",
    "Run",
    "Sprint"
]
export const PACES_COLORS = {
    Walk: "#17cf8b",
    Jog: "#F1ED0D",
    Run: "#F18F0D",
    Sprint: "#F10D0D"
};
export const SEGMENT_TYPE_COLORS = {
    Warmup: "#4CAF50", // Green signifies starting or warming up
    Cooldown: "#2196F3", // Blue signifies cooling down or relaxation
    Intervals: "#FF9800", // Orange signifies medium to high intensity
    Run: "#F44336", // Red signifies high intensity
    Rest: "#9E9E9E", // Grey signifies rest or low activity
    Free: "#3F51B5" // Indigo signifies flexibility or free choice
};

export const RUN_TYPES = [
    "Outside",
    "Treadmill",
    "Track"
]
export const SEGMENT_TYPES = [ 
    "Warmup",
    "Cooldown",
    "Intervals",
    "Run",
    "Rest",
    "Free"
]
export const SESSION_SORT_TYPES = [ 
    "date_created",
    "plays",
    "completes"
]
export const QUICK_BUILD_LENGTHS = [
    10,
    20,
    30,
    45,
    60
]