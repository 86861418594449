import { Link } from 'react-router-dom';
import { logout } from '../utils/Auth';
import React, { useState } from 'react';
import Toast from '../components/Toast';
import { fetchDataWithTokenRefresh } from '../utils/Utils';
import { useNavigate } from 'react-router-dom';

const NavigationProfile = ({user, token, setUser, setToken}) => {

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const navigate = useNavigate();
  const showToast = (message, type = 'success') => {
    setToastMessage(message);
    setToastType(type);
    setIsToastOpen(true);
  };

  const closeToast = () => {
    setIsToastOpen(false);
  };

    const navStyle = {
      backgroundColor: "#fff",
      width: "100%",
      boxShadow: "0 4px 15px -3px rgba(0, 0, 0, 0.2)", // Shadow at the top and bottom of the nav
      position:"relative",
      justifyContent:"center",
      zIndex:1,
      display:"flex"
    };
  
    const buttonContainerStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width:"100%",
      maxWidth: "1200px",
      padding: "10px 0", // Adjust vertical padding as needed
    };
  
    return (
      <nav style={navStyle}>
        <div style={buttonContainerStyle}>
          {/* Add your buttons here */}
          {/* <h3>Profile</h3>
          <Link to="/profile/my-sessions">
            <h3>My Sessions</h3>
          </Link>
          <h3>Settings</h3> */}
          {/* Add more buttons as needed */}
          <div style={{flex:1, display:"flex", flexDirection:"row", alignItems:"center"}}>
            <img src={process.env.REACT_APP_FILE_STORAGE_LOCATION === "http://127.0.0.1:9199/"?user?.photoURL?.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/"):user?.photoURL} alt="Profile" style={{marginLeft:10, width:50, height:50, borderRadius:50, marginRight:10}}></img>
            <div>
              <h3>Welcome back, {user.username}!</h3>
              <h3>{user.email}</h3>
            </div>
          </div>
          <button style={{marginRight:10}} className="medium" onClick={async ()=>{
              let user_metadata = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA + "/api/userMetaData?user_id="+user.uid, "GET", null, token, setToken);
              user_metadata = user_metadata[0];
              user_metadata.fetched = Date.now();
              let firebaseUser = {...user, ...user_metadata}
              setUser(firebaseUser);
              localStorage.setItem('userMetaData', JSON.stringify(firebaseUser));
              console.log(firebaseUser, "firebaseUser")
            }}>
              Refresh Profile Data
          </button>
          <button style={{marginRight:10}} className="medium" onClick={async ()=>{
              showToast("User settings coming soon. Change user settings in the app.", "error")
              // alert("Modify user settings in the app. Browser updates coming soon.")
            }}>
              Settings
          </button>
          <Link style={{marginRight:10}} to="/login">
            <button className="medium" style={{backgroundColor:"#D81F1F"}} onClick={async ()=>{
                await logout();
                navigate("/login");
              }}>
                Logout
            </button>
          </Link>
        </div>
        <Toast message={toastMessage} type={toastType} isOpen={isToastOpen} closeToast={closeToast} />
      </nav>
    );
  };
  
  export default NavigationProfile;
  