import React, { useState, useEffect, useContext } from 'react';
import {ReactComponent as IconRewind10} from '../assets/icons/replay_10.svg';
import {ReactComponent as IconForward10} from '../assets/icons/forward_10.svg';
import {ReactComponent as IconPlay} from '../assets/icons/play.svg';
import {ReactComponent as IconPause} from '../assets/icons/pause.svg';
import {ReactComponent as IconDevices} from '../assets/icons/devices.svg';
import {ReactComponent as IconRefresh} from '../assets/icons/refresh.svg';
import { SessionContext } from '../App'; // Adjust the path as needed
import { fetchDataWithTokenRefresh, convertToSpotifyURI, millisecondsToHMSObject } from "../utils/Utils";
import {refreshSpotifyToken} from '../utils/SpotifyAuth';
import '../App.css';
import Toast from '../components/Toast';

let global_timelineInterval = null;
const SpotifyPlayer = ({media, onCurrentTimeChange}) => {
    const { token, setToken } = useContext(SessionContext);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0); // in seconds
    const [doWeHaveDevice, setDoWeHaveDevice] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success'); // or 'error'
    const [deviceName, setDeviceName] = useState(''); // or 'error'
    const [canMediaControl, setCanMediaControl] = useState(true); // or 'error'
    const [hasFirstPlayHappened, setHasFirstPlayHappened] = useState(false)
    const [isScrubbing, setIsScrubbing] = useState(false);
    const trackInfo = {
        title: media.title,
        artist: media.artist,
        album: media.description,
        albumImageUrl: media.thumbnail,
        duration: media.duration // in seconds
    };
    const showToast = (message, type = 'success') => {
      setToastMessage(message);
      setToastType(type);
      setIsToastOpen(true);
    };
    const closeToast = () => {
      setIsToastOpen(false);
    };
    const timelineInterval = (stop_or_start) =>{
      if(stop_or_start === "start"){
        if(global_timelineInterval!= null){
          clearInterval(global_timelineInterval)
        }
        global_timelineInterval = setInterval(()=>{
          // Use a functional update to ensure you always have the latest currentTime
          setCurrentTime(currentTime => {
            const newTime = currentTime + 1;
            console.log(newTime);
            return newTime;
          });
          onCurrentTimeChange(currentTime => {
            const newTime = currentTime + 1;
            console.log(newTime);
            return newTime;
          })
        }, 1000)
      }
      if(stop_or_start === "stop"){
        if(global_timelineInterval!= null){
          clearInterval(global_timelineInterval)
        }
      }
    }
    const togglePlayPause = async () => {
      setCanMediaControl(false)
        // Integrate with Spotify's playback controls
        try{
          let spotify_access_data = localStorage.getItem('spotifyAccess');
          //check if expires in is later than now
          let is_token_expired = false;
          if(spotify_access_data){
            spotify_access_data = JSON.parse(spotify_access_data)
            let date = new Date();
            let time = date.getTime();
            if(spotify_access_data.expires_at < time){
              is_token_expired = true;
            }
          }else{
            is_token_expired = true;
          }
          if(is_token_expired){
            //we need to refresh the token
            try {
                await refreshSpotifyToken(token, setToken);
            } catch(error) {
                // Handle error
                alert("error", error)
            }
            spotify_access_data = localStorage.getItem('spotifyAccess');
            spotify_access_data = JSON.parse(spotify_access_data)
          }
          if(!isPlaying===true){
            console.log("hasFirstPlayHappened", hasFirstPlayHappened)
            let body;
            if(hasFirstPlayHappened!==true){
              setHasFirstPlayHappened(true)
              body = {
                'uris': [convertToSpotifyURI(media.location)]
              }
            }else{
              body=null;
            }
            console.log(body)
            console.log("BODY")
            let spotifyData = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/play&request_method=PUT", "POST", body, token, setToken);
            console.log("spotifyData", spotifyData)
            timelineInterval("start")        
          }else{
            let spotifyData = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/pause&request_method=PUT", "POST", null, token, setToken);
            console.log("spotifyData", spotifyData)
            timelineInterval("stop")  
          }

          setIsPlaying(!isPlaying);
          setCanMediaControl(true)
        }catch(error){
          console.error(error)
        }
    };
    const skipForward = async() => {
      setCanMediaControl(false)
      try{
        let spotify_access_data = localStorage.getItem('spotifyAccess');
        //check if expires in is later than now
        let is_token_expired = false;
        if(spotify_access_data){
          spotify_access_data = JSON.parse(spotify_access_data)
          let date = new Date();
          let time = date.getTime();
          if(spotify_access_data.expires_at < time){
            is_token_expired = true;
          }
        }else{
          is_token_expired = true;
        }
        if(is_token_expired){
          //we need to refresh the token
          try {
              await refreshSpotifyToken(token, setToken);
          } catch(error) {
              // Handle error
              alert("error", error)
          }
          spotify_access_data = localStorage.getItem('spotifyAccess');
          spotify_access_data = JSON.parse(spotify_access_data)
        }
        try{
          await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/seek?position_ms="+((currentTime+10)*1000)+"&request_method=PUT", "POST", null, token, setToken);
          setHasFirstPlayHappened(true)
          if(isPlaying){
            timelineInterval("start")  
          }
          setCurrentTime(currentTime+10)
          onCurrentTimeChange(currentTime+10)
        }catch(error){
          alert("unable to skip forward")
        }
        setCanMediaControl(true)
      }catch(error){
        console.error(error)
      }
        
    };
    const rewind = async () => {
      setCanMediaControl(false)
      try{
        let spotify_access_data = localStorage.getItem('spotifyAccess');
        //check if expires in is later than now
        let is_token_expired = false;
        if(spotify_access_data){
          spotify_access_data = JSON.parse(spotify_access_data)
          let date = new Date();
          let time = date.getTime();
          if(spotify_access_data.expires_at < time){
            is_token_expired = true;
          }
        }else{
          is_token_expired = true;
        }
        if(is_token_expired){
          //we need to refresh the token
          try {
              await refreshSpotifyToken(token, setToken);
          } catch(error) {
              // Handle error
              alert("error", error)
          }
          spotify_access_data = localStorage.getItem('spotifyAccess');
          spotify_access_data = JSON.parse(spotify_access_data)
        }
        try{
          let currentTimeLocal = currentTime;
          currentTimeLocal-10<0?currentTimeLocal=0:currentTimeLocal=currentTimeLocal-10;
          await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/seek?position_ms="+(currentTimeLocal*1000)+"&request_method=PUT", "POST", null, token, setToken);
          setHasFirstPlayHappened(true)
          if(isPlaying){
            timelineInterval("start")  
          }
          if(currentTimeLocal-10<0){
            setCurrentTime(0)
            currentTimeLocal=0;
            onCurrentTimeChange(0)
          }else{
            setCurrentTime(currentTime => {
              const newTime = currentTime - 10;
              console.log(newTime);
              return newTime;
            })
            currentTimeLocal=currentTime-10;
            onCurrentTimeChange(currentTime-10)
          }
        }catch(error){
          alert("unable to rewind")
        }
        setCanMediaControl(true)
      }catch(error){
        console.error(error)
      }
    };
    const handleScrubDown = () => {
      timelineInterval("stop")  
      setIsScrubbing(true);
    };
    const handleScrubUp = (e) => {
      if (isScrubbing) {
        setIsScrubbing(false);
        onScrubberChange(e); // Call your original function here
      }
    };
    const handleOnChange = (e) => {
      console.log(Number(e.target.value), "NUMBER")
      setCurrentTime(Number(e.target.value));
      onCurrentTimeChange(Number(e.target.value))
      // You can optionally update the current time here, but don't perform heavy operations
    };
    const onScrubberChange = async (e) => {
      //THE SCRUBBER IS FIRING FOIR EVERY LITTLE BIT IT MOVES!! NOT WHEN ITS RELEASED! :O
      setCanMediaControl(false)
      try{
        let spotify_access_data = localStorage.getItem('spotifyAccess');
        //check if expires in is later than now
        let is_token_expired = false;
        if(spotify_access_data){
          spotify_access_data = JSON.parse(spotify_access_data)
          let date = new Date();
          let time = date.getTime();
          if(spotify_access_data.expires_at < time){
            is_token_expired = true;
          }
        }else{
          is_token_expired = true;
        }
        if(is_token_expired){
          //we need to refresh the token
          try {
              await refreshSpotifyToken(token, setToken);
          } catch(error) {
              // Handle error
              alert("error", error)
          }
          spotify_access_data = localStorage.getItem('spotifyAccess');
          spotify_access_data = JSON.parse(spotify_access_data)
        }

        await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/seek?position_ms="+(Number(e.target.value)*1000)+"&request_method=PUT", "POST", null, token, setToken);
        setHasFirstPlayHappened(true)
        if(isPlaying){
          timelineInterval("start")  
        }
        setCanMediaControl(true)
      }catch(error){
        console.error(error)
      }
    };
    const getSpotifyPlayerStatus = async () => {
      setIsRefreshing(true)
      try{
        let spotify_access_data = localStorage.getItem('spotifyAccess');
        //check if expires in is later than now
        let is_token_expired = false;
        if(spotify_access_data){
          spotify_access_data = JSON.parse(spotify_access_data)
          let date = new Date();
          let time = date.getTime();
          if(spotify_access_data.expires_at < time){
            is_token_expired = true;
          }
        }else{
          is_token_expired = true;
        }
        if(is_token_expired){
          //we need to refresh the token
          try {
              await refreshSpotifyToken(token, setToken);
          } catch(error) {
              // Handle error
              alert("error", error)
          }
          spotify_access_data = localStorage.getItem('spotifyAccess');
          spotify_access_data = JSON.parse(spotify_access_data)
        }
        let spotifyData = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player", "GET", null, token, setToken);
        console.log("spotifyData", spotifyData)
        if(spotifyData!==null && spotifyData!==undefined && spotifyData!=="" && spotifyData.length !== 0){
          setDeviceName(spotifyData.device.name)
          showToast("Session identified: " + spotifyData.device.name, "success")
          setDoWeHaveDevice(true)
        }else{
          showToast("No active Spotify sessions", "error")
          setDoWeHaveDevice(false)
        }
      }catch(error){
        console.error(error)
      }
      setIsRefreshing(false)
    }

    useEffect(() => {
      //check Spotify player status:
      getSpotifyPlayerStatus()
      return (async ()=>{
        setHasFirstPlayHappened(false)
        let spotify_access_data = localStorage.getItem('spotifyAccess');
          //check if expires in is later than now
          let is_token_expired = false;
          if(spotify_access_data){
            spotify_access_data = JSON.parse(spotify_access_data)
            let date = new Date();
            let time = date.getTime();
            if(spotify_access_data.expires_at < time){
              is_token_expired = true;
            }
          }else{
            is_token_expired = true;
          }
          if(is_token_expired){
            //we need to refresh the token
            try {
                await refreshSpotifyToken(token, setToken);
            } catch(error) {
                // Handle error
                alert("error", error)
            }
            spotify_access_data = localStorage.getItem('spotifyAccess');
            spotify_access_data = JSON.parse(spotify_access_data)
          }
        await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint=https://api.spotify.com/v1/me/player/pause&request_method=PUT", "POST", null, token, setToken);
        if(global_timelineInterval!= null){
          clearInterval(global_timelineInterval)
        }
        setCurrentTime(0)
        onCurrentTimeChange(0)
      })
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{display:"flex", justifyContent:"space-between", flexDirection:"column", minWidth:550, backgroundColor:"#000", padding:20, borderRadius:4}}>
            <div style={{display:"flex"}}>
                <div style={{display:"flex", flex:1, flexDirection:"row"}}>
                  <img style={{maxWidth:120}} src={trackInfo.albumImageUrl} alt="Album Cover" />
                  <div style={{marginLeft:10, alignSelf:"center"}}>
                    <h2 style={{color:"#fff"}}>{trackInfo.title}</h2>
                    <h3 style={{color:"#fff"}}>{trackInfo.artist}</h3>
                    <h3 style={{color:"#fff"}}>{trackInfo.album}</h3>
                  </div>
                </div>
                <div style={{display:"flex", flexDirection:"row"}}>
                  {!doWeHaveDevice ? (
                    <div style={{marginRight:10}}>
                      <h3 style={{color:"#ff0000", textAlign:'right'}}>Disconnected.</h3>
                      <h4 style={{color:"#ff0000"}}>Start Spotify, play a track, refresh.</h4>
                    </div>
                  ):(
                    <h3 style={{color:"#17CF8B", marginTop:10}}>{deviceName}</h3>
                  )}
                  <div style={{display:"flex", flexDirection:"column"}}>         
                    <IconDevices fill={doWeHaveDevice===false?"#ff0000":"#17CF8B"} style={{marginRight:10, width:40, height:40}}></IconDevices>
                    {!doWeHaveDevice && (
                      <IconRefresh className={isRefreshing ? "spinning" : ""} fill={doWeHaveDevice===false?"#ff0000":"#17CF8B"} style={{width:40, height:40}} onClick={getSpotifyPlayerStatus}></IconRefresh>
                    )}
                  </div>
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column"}}>
              <div style={{marginTop:20, display:"flex", justifyContent:"center", position:"relative"}}>
                  <IconRewind10 fill="#fff" style={{marginRight:10, width:40, height:40}} onClick={rewind}></IconRewind10>
                  {isPlaying ? 
                    <IconPause fill="#fff" style={{width:40, height:40}} onClick={canMediaControl===true?togglePlayPause:null}></IconPause>
                  : 
                    <IconPlay fill="#fff" style={{width:40, height:40}} onClick={canMediaControl===true?togglePlayPause:null}></IconPlay>
                  }
                  <IconForward10 fill="#fff" style={{marginLeft:10, width:40, height:40}} onClick={skipForward}>Skip Forward 10s</IconForward10>
              </div>
              <div>
                  <div style={{display:"flex", alignItems:"center", justifyItems:"center"}}>
                    <h3 style={{minWidth:100, textAlign:"center", color:"#fff"}}>{millisecondsToHMSObject(currentTime*1000).h}:{millisecondsToHMSObject(currentTime*1000).m}:{millisecondsToHMSObject(currentTime*1000).s}</h3>
                    <input
                        type="range"
                        min="0"
                        style={{paddingTop:10}}
                        max={trackInfo.duration}
                        value={currentTime}
                        onChange={handleOnChange}
                        onMouseDown={handleScrubDown}
                        onMouseUp={handleScrubUp}
                    />
                    <h3 style={{minWidth:100, textAlign:"center", color:"#fff"}}>{millisecondsToHMSObject(trackInfo.duration*1000).h}:{millisecondsToHMSObject(trackInfo.duration*1000).m}:{millisecondsToHMSObject(trackInfo.duration*1000).s}</h3>
                  </div>
              </div>
            </div>
            <Toast message={toastMessage} type={toastType} isOpen={isToastOpen} closeToast={closeToast} />
        </div>
    );
};

export default SpotifyPlayer;