import React from 'react';
import './Footer.css'; // Make sure to create a corresponding CSS file
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-links">
        <Link to="/">
          <span>Home</span>
        </Link>
        <Link to="/contact">
          <span>Contact</span>
        </Link>
      </div>
      <h2 className="footer-copy" style={{color:"#fff"}}>
        &copy; {new Date().getFullYear()} Runerval from Michael Whitney. All rights reserved.
      </h2>
    </footer>
  );
}

export default Footer;