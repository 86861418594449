import React, { useContext, useEffect, useState } from 'react';
import LoginForm from "../components/LoginForm";
import { logout } from '../utils/Auth';
import { SessionContext } from '../App'; // Adjust the path as needed
import Footer from '../components/Footer';
import {ReactComponent as BadgeApple} from '../assets/images/badge_apple.svg';
import googleBadge from '../assets/images/badge_google.png';
import { useNavigate } from 'react-router-dom';


function Login() {
  const { user } = useContext(SessionContext);
  const navigate = useNavigate()
  const [somethingLoading, setSomethingLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Signing up...");

  const handleLoading = (loading, message) => {
    setSomethingLoading(loading);
    setLoadingMessage(message); 
    if(user && !loading){
      console.log("DIUDE HTIS FGHJS(OGTUI?");
      navigate("/profile");
    }
  }
  
  useEffect(() => {
    if(user) {
      console.log("DIUDE HTIS FGHJS(OGTUI?22222222222222222")
      handleLoading(false, "")
    }
    //check to see if new session or edit session
    return () => {
      setSomethingLoading(false);
    }
  }
  , [user]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
      <div>
        {somethingLoading && (
          <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
            <div className="loading-icon"></div>
            <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
          </div>
        )}
        <div style={{minHeight:"calc(100vh - 178px)"}}>
          {user ? (
            <div>
              <p>
                You are logged in as {user.email}
              </p>
              <button onClick={async ()=>{
                await logout();
                console.log("LOGGED OUT")
              }}>
                Logout
              </button>
            </div>
          ):(
          <div style={{display:"flex", justifyContent:"center", marginBottom:10}}>
            <div style={{maxWidth:1200, marginTop:100, minWidth:400}}>
              <LoginForm onLoading={handleLoading}></LoginForm>
              <h2 style={{marginTop:20}}>Download and register within the app:</h2>
              <div style={{display:"flex", height:100}}>
                <BadgeApple style={{flex:.9, height:100, marginRight:5}}></BadgeApple>
                <div style={{backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundImage: `url(${googleBadge})`, flex:1, height:100, marginLeft:5, backgroundSize:"113%"}}></div>
              </div>
            </div>
          </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    );
}

export default Login;