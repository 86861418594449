import React, { useState, useEffect } from 'react';
import './ToggleSwitch.css'; // Import your CSS here

const ToggleSwitch = ({ onChange, initialState = false }) => {
    const [isOn, setIsOn] = useState(initialState);

    useEffect(() => {
        setIsOn(initialState); // Update the state when initialState changes
    }, [initialState]);

    const toggleSwitch = (e) => {
        setIsOn(!isOn);
        if (onChange) {
            // Pass the event object to the external onChange prop function
            onChange(e);
        }
    };


    return (
        <label className="switch">
            <input 
                type="checkbox" 
                checked={isOn} 
                onChange={toggleSwitch} 
            />
            <span className="slider round"></span>
        </label>
    );
};

export default ToggleSwitch;