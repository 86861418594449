import { Link } from 'react-router-dom';
import {ReactComponent as IconProfile} from '../assets/icons/profile.svg';
import {ReactComponent as AppIcon} from '../assets/app_icon.svg';
import {ReactComponent as IconLogin} from '../assets/icons/login.svg';

const NavigationHeader = ({user}) => {

    return (
      <nav style={{backgroundColor:"#000", boxShadow: "0 4px 15px -3px rgba(0, 0, 0, 0.2)", position:'relative', zIndex:2}}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <Link className="button" to="/">
            <div style={{ width:200, padding:10, display:"flex", flexDirection:"row", alignItems:"center" }}> {/* For text aligned to the far left */}
              <AppIcon style={{width:60, height:60}}></AppIcon>
              <h2 style={{color:"#fff", fontWeight:600}}>Runerval</h2>
            </div>
          </Link>
          <div style={{ display: "flex", justifyContent: "center", flex: 1, gap: "20px" }}>
            {/* <Link to="/"><button className="nav" style={{height:"100%"}}>Home</button></Link>
            <Link to="/about"><button className="nav" style={{height:"100%"}}>About</button></Link> */}
            <Link to="/browse-sessions"><button className="nav" style={{height:"100%"}}>Browse Sessions</button></Link>
            <Link to="/build-session"><button className="nav" style={{height:"100%"}}>Build Session</button></Link>
          </div>
          <div style={{width:200, display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
            <button className="nav">
              {user==null ? (
                <Link to="/login">
                  <IconLogin style={{fill:"#ffffff", width:40, height:40}}></IconLogin>
                </Link>
              ):(
                <Link to="/profile">
                  <IconProfile style={{fill:"#ffffff", width:40, height:40}}></IconProfile>
                </Link>
              )}
            </button>
          </div>
        </div>
      </nav>
    );
}
export default NavigationHeader;