
import { fetchDataWithTokenRefresh } from "./Utils";


export const authenticateWithSpotify = async (token, setToken) => {
  // Check if there is an access token in local storage
  const storedToken = JSON.parse(localStorage.getItem('spotifyAccess'));

  if (storedToken) {
    // If there is an access token, check if it's expired
    const currentTime = new Date().getTime();

    if (currentTime > storedToken.expires_at) {
      await refreshSpotifyToken(token, setToken)
    }
  } else {
    // If there is no access token, start the full authentication process
    let code_challenge_verification = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_CODE_CHALLENGE, "GET", null, token, setToken)
    let client_id = "1964a3d1ab0347ddbc29ebcfae8551cc"
    const spotifyScopes = "playlist-read-private app-remote-control user-modify-playback-state user-read-playback-state user-read-email user-read-private"
    console.log("code_challenge_verification")
    console.log(code_challenge_verification)
    sessionStorage.setItem('spotifyCodeVerifier', code_challenge_verification.code_verifier);
    const redirect_uri = process.env.REACT_APP_ENV === "development" 
    ? "http://localhost:3000/spotify-redirect.html?api=spotify" 
    : "https://runerval-a6a0b.web.app/spotify-redirect.html?api=spotify"; 

    const authUrl = `https://accounts.spotify.com/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}&scope=${encodeURIComponent(spotifyScopes)}&code_challenge_method=S256&code_challenge=${code_challenge_verification.code_challenge}`;
    
    const width = 600, height = 800;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(authUrl, 'SpotifyAuthPopup', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`);

    await new Promise((resolve) => {
      window.addEventListener('message', async (event) => {
        if (event.origin !== "http://localhost:3000" && event.origin !== "https://runerval-a6a0b.web.app") {
                  return;
              }
        if (event.data.api === "spotify") {
            // ... your existing logic
            const code_verifier = sessionStorage.getItem('spotifyCodeVerifier');
            console.log("code_verifier", code_verifier)
            const redirect_uri = process.env.REACT_APP_ENV === "development" 
                ? "http://localhost:3000/spotify-redirect.html?api=spotify" 
                : "https://runerval-a6a0b.web.app/spotify-redirect.html?api=spotify";
            // Note: Do NOT include client_secret in client-side code

            // Construct the request body
            const requestBody = {
                grant_type: 'authorization_code',
                code: event.data.code,
                redirect_uri: redirect_uri,
                code_verifier: code_verifier
                // client_secret should be handled on the server side
            };
            try{
            const tokenResponse = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_TOKEN_SWAP, 'POST', requestBody, token, setToken);
            console.log(tokenResponse);
            //get current time in seconds
            const date = new Date();
            const time = date.getTime();
            const expiresAt = time + tokenResponse.expires_in*1000;
            tokenResponse.expires_at = expiresAt;
            localStorage.setItem('spotifyAccess', JSON.stringify(tokenResponse));
            resolve();
            }catch(error){
                alert("Error: " + error)
                console.log(error)
            }
          
            // Handle the tokenResponse
          }
      });
    });
  }
};

export const refreshSpotifyToken = async (token, setToken) => {
    try {
      if(localStorage.getItem('spotifyAccess') == null) {
        alert("No Spotify access found")
        return
      }
      let refresh_token = localStorage.getItem('spotifyAccess');
      refresh_token = JSON.parse(refresh_token);
      refresh_token = refresh_token.refresh_token;
      
      // Construct the request body
      const requestBody = {
          grant_type: "refresh_token",
          refresh_token
      };
      const tokenResponse = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_TOKEN_REFRESH, "POST", requestBody, token, setToken);
      // Process data
      //get current time in seconds
      const date = new Date();
      const time = date.getTime();
      const expiresAt = time + tokenResponse.expires_in*1000;
      tokenResponse.expires_at = expiresAt;
      localStorage.setItem('spotifyAccess', JSON.stringify(tokenResponse));
    }catch(error) {
      // Handle error
      console.error(error)
      throw new Error("ERROR: Unable to refresh Spotify token - SpotifyAuth:  ", error)
    }
}