import React from 'react';
import './SessionCardLoading.css'; // Import the CSS for styling

const SessionCardLoading = ({orientation}) => {
  console.log(orientation)
  return (
    <div style={{marginRight:orientation==="horizontal"?10:0, height:196}} className="loading-box">
      <div className="loading-animation"></div>
    </div>
  );
};

export default SessionCardLoading;