import React from 'react';
import './SessionCardStripedBackground.css'; // Assuming you have a CSS file for basic styles

export const SessionCardStripedBackground = ({ intervals, totalTime, children, segments, card }) => {
    const computedTotalTime = totalTime || intervals.reduce((acc, curr) => acc + curr.time, 0);

  
    const stripeStyles = intervals.map(interval => {
      const width = (interval.time / computedTotalTime) * 100;
      return { background: interval.color, width: `${width}%` };
    });

    const segmentStyles = segments.map(segment => {
      const width = (segment.time / computedTotalTime) * 100;
      return {width: `${width}%`, backgroundColor: segment.color};
    });
  
    return (
      <div>
        <div className="striped-container">
          {stripeStyles.map((style, index) => (
            <div key={index} className="stripe" style={style}></div>
          ))}
          <div className="overlay" style={{backgroundColor:card===true?"rgba(0, 0, 0, 0.3)":"rgba(0, 0, 0, 0.5)"}}></div> {/* Overlay for darkening */}
          <div className="content" style={{right:card===true?0:"auto", marginLeft:card===true?0:10, display:card===true?"block":"flex", width:card===true?"auto":"calc(100% - 20px)"}}>
            {children}
          </div>
        </div>
        <div style={{display:"flex", flexDirection:"row"}}>
        {segments.map((segment, index) => (
          <div key={index} className="segment" style={{...segmentStyles[index], justifyContent: 'center', display: 'flex'}}>
            <h4 style={{color:"#fff", padding:2}}>{segment.type}</h4>
          </div>
        ))}
        </div>
      </div>
    );
  };
  
