import React, { useState } from 'react';
import {fetchDataWithTokenRefresh} from '../utils/Utils';

function StarRating({ initialRating = 0, readOnly = false, sessionId, token, setToken, sessionAuthor, updateParent, triggerLoadingIcon}) {
  const [rating, setRating] = useState(initialRating);
  const [hover, setHover] = useState(0);

  const handleRating = async (rate) => {
    if (!readOnly) {
      setRating(rate);
      triggerLoadingIcon(true)
      let rate_request = await fetchDataWithTokenRefresh(process.env.REACT_APP_HANDLE_RATINGS, "POST", {sessionId: sessionId, rating: rate, sessionAuthor: sessionAuthor}, token, setToken);
      triggerLoadingIcon(false)
      console.log(rate_request)
      updateParent()
      console.log(`Rated ${rate} out of 5`);  // This function gets called when a star is clicked
    }
  };

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleRating(ratingValue)}
              style={{ display: "none" }}
              disabled={readOnly}
            />
            <span
              onMouseEnter={() => !readOnly && setHover(ratingValue)}
              onMouseLeave={() => !readOnly && setHover(rating)}
              style={{
                cursor: readOnly ? 'default' : 'pointer',
                color: ratingValue <= (hover || rating) ? '#ffc107' : '#aaabad',
              }}
            >&#9733;</span>
          </label>
        );
      })}
    </div>
  );
}

export default StarRating;
