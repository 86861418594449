import { getAuth, signInWithEmailAndPassword, signOut, connectAuthEmulator, browserLocalPersistence,  } from "firebase/auth";
import { universalFetchPublic } from './Utils';

export const auth = getAuth();
console.log("process.env.NODE_ENV", process.env.NODE_ENV)
if(process.env.NODE_ENV === "development"){
  connectAuthEmulator(auth, "http://localhost:9099");
}

export const login = async (email, password) => {

  //use firebase auth emulators
  //firebase emulators:start --only auth
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email
    if (!emailRegex.test(email)) {
      throw new Error("Invalid email format");
    }
  
    // Password validation
    const minPasswordLength = 6; // Example: minimum password length
    if (password.length < minPasswordLength) {
      throw new Error(`Password must be at least ${minPasswordLength} characters long`);
    }
  
    try {
      if(process.env.REACT_APP_ENV === "development"){
        await auth.setPersistence(browserLocalPersistence);
      }
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      return userCredential.user;
    } catch (error) {
      console.error("Error signing in: ", error);
      throw error;
    }
    
};
export const logout = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem('userMetaData'); // Replace 'username' with the key you used to store the item
  } catch (error) {
    console.error("Error signing out: ", error);
    throw error;
  }
};
export const refreshToken = async () => {
  console.log("WE REFRESHED TOKEN")
  const user = auth.currentUser;
  if (user) {
      return await user.getIdToken(true);
  }
  return null;
};
export const signUp = async (password, repeatPassword, email, birthday, username, newsletter) => {

  if(password !== repeatPassword){
    throw new Error("Passwords do not match");
  }
  if(password.length < 6){
    throw new Error("Password must be at least 6 characters long");
  }
  if(!email){
    throw new Error("Email is required");
  }

  try {
    await universalFetchPublic(process.env.REACT_APP_REGISTER_USER, "POST", {email, password, username, birthday, newsletter});
  } catch (error) {
    throw new Error(error);
  }
}