import React, { useState, useRef, useEffect } from 'react';
import '../App.css';

const DropdownMulti = ({ options, title, onSelectionChange, defaultSelectedOptions}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null); // Ref for the dropdown

  const toggleDropdown = () => setIsOpen(!isOpen);

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        };

        // Add event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        // Clean up
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
      if (defaultSelectedOptions) {
        setSelectedOptions(defaultSelectedOptions);
      }
    }, [defaultSelectedOptions]); // This effect runs whenever defaultSelectedOptions changes
    
    const handleOptionClick = option => {
      let updatedSelectedOptions = [];
    
      if (selectedOptions.includes(option.value)) {
        // Remove the option if it's already selected
        updatedSelectedOptions = selectedOptions.filter(value => value !== option.value);
      } else {
        // Add the option if it's not already selected
        updatedSelectedOptions = [...selectedOptions, option.value];
      }
    
      setSelectedOptions(updatedSelectedOptions); // Update the state
      onSelectionChange(updatedSelectedOptions); // Call the callback with the updated selections
    };

  const isOptionSelected = option => selectedOptions.includes(option.value);

  return (
    <div ref={dropdownRef} style={{ position: 'relative', width: '100%'  }}>
      <div className='dropdown' onClick={toggleDropdown}>
        <h4>{title}</h4>
        <div style={{display:"flex"}}>
            <h3 style={{flex:1}}>Choose Multiple ({selectedOptions.length})</h3>
            <div>{isOpen ? '▲' : '▼'}</div>
        </div>
      </div>

      {isOpen && (
        <div className="dropdown-menu">
          {options.map(option => (
            <div 
              key={option.value} 
              onClick={() => handleOptionClick(option)}
              style={{
                backgroundColor: isOptionSelected(option) ? '#17CF8B' : 'white',
                padding: '5px',
                cursor: 'pointer'
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMulti;