import * as Constants from '../constants'
import { v4 as uuidv4 } from 'uuid';

export let createInterval = ({ 
    id = "interval-"+generateId(),
    time = { s: "10", m: "00", h: "00", total_seconds: 10 }, 
    pace = Constants.PACES[0],
    interval_length_lock = false,
    playback_time_lock = false,
    voiceline = {
        id:'',
        online_location: '',
        string: '',
        coach: {
            id: '',
            name: '',
            photo: ''
        },
        author: '',
        default: true
    }
} = {}) => {
    return {
        id,
        time,
        pace,
        interval_length_lock,
        playback_time_lock,
        voiceline
    };
}

export let createMedia = ({
    id = "media-"+generateId(),
    title = '',
    artist = '',
    duration = 0,
    number_of_items = 0,
    thumbnail = '',
    starttime = {
        s: "00",
        m: "00",
        h: "00",
        total_seconds: 0,
        random: true
    },
    location = '',
    description = '',
    type = '',
    source = '',
    continuous_playback = false
} = {}) => {
    return {
        id,
        title,
        artist,
        duration,
        number_of_items,
        thumbnail,
        starttime,
        location,
        description,
        type,
        source,
        continuous_playback
    };
}

export let createMovement = ({
    id = "movement-"+generateId(),
    position = 1,
    source = '',
    location = '',
    time = 10,
    automatictimeplaying = false,
    type = '',
    location_loading = '',
    playing_media = false,
    intervals,
    media
} = {}) => {
    if (!intervals) {
        intervals = [createInterval()];
    }

    if (!media) {
        media = createMedia();
    }

    return {
        id,
        position,
        source,
        location,
        time,
        automatictimeplaying,
        type,
        location_loading,
        playing_media,
        intervals,
        media
    };
}

export let createSegment = ({
    id = "segment-"+generateId(),
    position = 1,
    time = 10,
    type = Constants.SEGMENT_TYPES[0],
    movements = [createMovement()],
    start_sfx = '',
    complete_sfx = '',
    special = "standard"
} = {}) => {
    return {
        id,
        position,
        time,
        type,
        movements,
        start_sfx,
        complete_sfx,
        special
    };
}

export let createSession = ({
    id = "session-"+generateId(),
    type = Constants.RUN_TYPES[1],
    open_to_public = false,
    title = '',
    difficulty = Constants.DIFFICULTIES[0],  // example default, adjust as needed
    time = 0,
    date_created = new Date().toISOString(),
    author = '',
    thumbnail = 'https://firebasestorage.googleapis.com/v0/b/runerval-a6a0b.appspot.com/o/sessionImages%2Fdefault_thumbnail.jpeg?alt=media&token=bf238be6-7b65-469a-a802-8049a7a43452',
    titlelowercase = '',
    coach_id = Constants.COACHES[0],
    genres = [],
    explicit = false,
    segments=[],
    plays = null,
    favorites = 0,
    completes = null,
    media_source = Constants.MEDIA_SOURCES[0],
    updated = false,
    deleted = false,
    rating = 0,
    number_of_ratings = 0,
    start_voice_line = {
        id:'',
        online_location: '',
        string: '',
        coach: {
            id: '',
            name: '',
            photo: ''
        },
        author: '',
        default: true
    },
    end_voice_line = {
        id:'',
        online_location: '',
        string: '',
        coach: {
            id: '',
            name: ''
        },
        author: '',
        default: true
    }
} = {}) => {
    return {
        id,
        type,
        favorites,
        rating,
        number_of_ratings,
        explicit,
        open_to_public,
        title,
        difficulty,
        time,
        date_created,
        author,
        thumbnail,
        titlelowercase,
        coach_id,
        genres,
        segments,
        plays,
        completes,
        media_source,
        updated,
        deleted,
        start_voice_line,
        end_voice_line
    };
}

function generateId() {
    return uuidv4();
}