import React, { useContext, useEffect, useState, useRef } from 'react';
import { SessionContext } from '../App'; // Adjust the path as needed
import Dropdown from '../components/Dropdown';
import MultiSelectDropdown from '../components/DropdownMulti';
import '../App.css';
import {ReactComponent as IconDelete} from '../assets/icons/delete.svg';
import {ReactComponent as IconLock} from '../assets/icons/lock.svg';
import {ReactComponent as IconLockOpen} from '../assets/icons/lock_open.svg';
import {ReactComponent as IconDrag} from '../assets/icons/drag.svg';
import ToggleSwitch from '../components/ToggleSwitch';
import { useQuery } from '../utils/Hooks';
import { createDefaultSession} from '../data/dataUtils';
import { createSegment, createMovement, createInterval, createMedia } from '../data/dataModel';
import { formatMillisecondsToHMS, getVideoIDFromURL, parseYouTubeVideoDuration, millisecondsToHMSObject, generateId, blobToBase64, isValidImageUrl, spotifyUrlParser } from '../utils/Utils';
import * as Constants from '../constants'
import YouTubeVideo from '../components/YouTubeVideo';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Bubble from '../components/Bubble';
import Toast from '../components/Toast';
import {fetchDataWithTokenRefresh} from '../utils/Utils';
import {authenticateWithSpotify} from '../utils/SpotifyAuth';
import SpotifyPlayer from '../components/SpotifyPlayer';
import { useLocation, useNavigate } from 'react-router-dom';
import {ReactComponent as BadgeApple} from '../assets/images/badge_apple.svg';
import googleBadge from '../assets/images/badge_google.png';
import LoginForm from "../components/LoginForm";
import Footer from '../components/Footer';
import { v4 as uuidv4 } from 'uuid';
import {app as firebase_app} from '../firebase';
import Tooltip from '../components/Tooltip';

import { getDatabase, ref, onValue } from "firebase/database";

let global_session = null;
let global_quick_build_session = null;


const BuildSession = () => {
  const { user, token, setToken } = useContext(SessionContext);
  const [session, setSession] = useState(null); 
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(null);
  const [selectedMovementIndex, setSelectedMovementIndex] = useState(null);
  const [selectedIntervalIndex, setSelectedIntervalIndex] = useState(null);
  const [playingMedia, setPlayingMedia] = useState(false); // Whether or not media is playing
  const [mediaSource, setMediaSource] = useState(null); // The media source that is playing
  const query = useQuery();
  const navigate = useNavigate();
  const [somthingLoading, setSomethingLoading] = useState(false); // Whether or not something is loading
  const [loadingMessage, setLoadingMessage] = useState(""); // The message to display while loading
  const [showYouTubeIntervalController, setShowYouTubeIntervalController] = useState(false); // Whether or not to show the YouTube interval controller
  const [showCoachVoicelineBuilder, setShowCoachVoicelineBuilder] = useState(false); // Whether or not to show the coach voiceline builder
  const [userNumberOfTokens, setUserNumberOfTokens] = useState(0); // The number of tokens the user has
  const [voicelineToGenerate, setVoicelineToGenerate] = useState(null); // The voiceline to generate
  const [coachVoiceLineEditing, setCoachVoiceLineEditing] = useState(null); // Whether or not the coach voiceline is being edited
  const [newlyGeneratedVoiceLine, setNewlyGeneratedVoiceLine] = useState(null); // The newly generated voice line
  const [generatedVoiceLineUrl, setGeneratedVoiceLineUrl] = useState(null); // The generated voice line URL
  const [generatedVoiceLineBlob, setGeneratedVoiceLineBlob] = useState(null); // The generated voice line blob
  const [coachVoiceLineEditingType, setCoachVoiceLineEditingType] = useState(null); // The type of coach voiceline being edited
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success'); // or 'error'
  const [showThumbnailSelector, setShowThumbnailSelector] = useState(false); // The selected coach voiceline
  const [thumbnailSearchString, setThumbnailSearchString] = useState(""); // The search string for thumbnails
  const [doWeHaveSession, setDoWeHaveSession] = useState(false); // Whether or not we have a session
  const [chooseCoachDisabled, setChooseCoachDisabled] = useState(false); // Whether or not we can choose a coach
  const [mediaSourceDisabled, setMediaSourceDisabled] = useState(false); // Whether or not we can choose a media source
  const [spotifyPlayerCurrentTime, setSpotifyPlayerCurrentTime] = useState(0);
  const [quickOrAdvanced, setQuickOrAdvanced] = useState("quick"); // Whether or not we are in quick or advanced mode
  const [hasQuickOrAdvancedBeenChosen, setHasQuickOrAdvancedBeenChosen] = useState(false);
  const [quickBuildSessionData, setQuickBuildSessionData] = useState(null);
  const [quickBuildLoadingStatuses, setQuickBuildLoadingStatuses] = useState(null)
  const [quickBuildAreWeUsingCustomInstructions, setQuickBuildAreWeUsingCustomInstructions] = useState(false);
  const [usersCharacterCount, setUsersCharacterCount] = useState(0);
  const [areWeFetchingCharacterCount, setAreWeFetchingCharacterCount] = useState(false);

  // const [quickBuildProgress, setQuickBuildProgress] = useState(0);



  const playerRef = useRef(null);
  
  const showToast = (message, type = 'success') => {
    setToastMessage(message);
    setToastType(type);
    setIsToastOpen(true);
  };

  const closeToast = () => {
    setIsToastOpen(false);
  };
  const coaches =  Constants.COACHES.map(coach => ({
    label: coach.name,
    value: JSON.stringify(coach),
    type: "coach"
  }));
  const location = useLocation();



  const difficulties =  Constants.DIFFICULTIES.map(difficulty => ({
    label: difficulty,
    value: difficulty,
    type: "difficulty"
  }));
  const quick_build_time =  Constants.QUICK_BUILD_LENGTHS.map(quick_build_time => ({
    label: quick_build_time,
    value: quick_build_time,
    type: "quick_build_time"
  }));
  const media_sources =  Constants.MEDIA_SOURCES.map(media_sources => ({
    label: media_sources,
    value: media_sources,
    type: "media_source"
  }));
  const genres =  Constants.GENRES.map(genres => ({
    label: genres,
    value: genres,
    type: "genre"
  }));
    
  const run_types =  Constants.RUN_TYPES.map(run_types => ({
    label: run_types,
    value: run_types,
    type: "run_type"
  }));
  const segments =  Constants.SEGMENT_TYPES.map(segment => ({
    label: segment,
    value: segment,
    type: "segment"
  }));
  const paces =  Constants.PACES.map(pace => ({
    label: pace,
    value: pace,
    type: "pace"
  }));
  const newOrEditSession = () => {
    setLoadingMessage("Figuring out session..")
    setSomethingLoading(true);
    const sessionId = query.get('id');
    const duplicate = query.get('duplicate');
    console.log("DRAFT SESSION")
    console.log(localStorage.getItem('draft_session'))
    console.log("location")
    console.log(location)
    // if(sessionId && location.state && location.state.session.author != user.uid) {
    //   navigate("/profile/my-sessions");
    //   alert("You don't have permission to edit this session");
    //   return;
    // }
    if(sessionId && location.state) { //we have a session_id, we are editing a session
      setHasQuickOrAdvancedBeenChosen(true);
      setQuickOrAdvanced("advanced");
      if(duplicate){
        console.log("DUOLICATING SESSION")
        global_session = location.state.session;
        global_session.id = "session-"+generateId();
        global_session.author = user?.uid;
        global_session.date_created = new Date().toISOString();
        global_session.title = global_session.title + " (2)";
        global_session.titlelowercase = global_session.title.toLowerCase();
        global_session.plays = null;
        global_session.completes = null;
        setSession(global_session)
        //set local data too
        localStorage.setItem('draft_session', JSON.stringify(global_session));  
      }else{
        console.log("DID THIS SESSION FIRE")
        global_session = location.state.session;
        setSession(global_session)
        //set local data too
        localStorage.setItem('draft_session', JSON.stringify(global_session));  
      }
    } else if(localStorage.getItem('draft_session')) {//we dont have a session_id but we have a local storage, we are editing a session
      showToast('You are editing a draft. Discard or Save to start new.', 'success')
      global_session = JSON.parse(localStorage.getItem('draft_session'));
      //we need to check user Id and compare to saved local storage. If different we need to reset session
      if(global_session.author !== user?.uid){
        global_session = createDefaultSession(null, user?.uid);
        //update coaches format to reflect dropdown
        localStorage.setItem('draft_session', JSON.stringify(global_session));
      }
      setSession(global_session)
    }else{//we dont have a session_id and no local storage, we are creating a new session
      global_session = createDefaultSession(null, user?.uid);
      //update coaches format to reflect dropdown
      setSession(global_session)
      localStorage.setItem('draft_session', JSON.stringify(global_session));
    }
    global_session.media_source === "None"? setPlayingMedia(false) : setPlayingMedia(true)
    setMediaSource(global_session.media_source)
    checkIfWeCanChangeCoach()
    checkIfMediaIsPlayingInAnyMovement()
    setDoWeHaveSession(true)
    setSomethingLoading(false);
  }
  const deleteSegMoveInt = (seg_move_int) => {
    const updatedSession = { ...global_session };
    //swtich statement for segment movement or interval
    switch (seg_move_int) {
      case "segment":
        // Check if there is at least one segment
        if (updatedSession.segments.length === 1) {
          alert("At least one segment must exist.");
          return;
        }
        //we need to delete the segment
        updatedSession.segments.splice(selectedSegmentIndex, 1);
        updatedSession.segments.forEach((segment, index) => {
          segment.position = index + 1;
        });
        setSelectedSegmentIndex(null);
        setSelectedMovementIndex(null);
        setSelectedIntervalIndex(null);
        break;
      case "movement":
        // Check if there is at least one movement in the selected segment
        if (updatedSession.segments[selectedSegmentIndex].movements.length === 1) {
          alert("At least one movement must exist in the segment.");
          return;
        }
        //we need to delete the movement
        updatedSession.segments[selectedSegmentIndex].movements.splice(
          selectedMovementIndex,
          1
        );
        updatedSession.segments[selectedSegmentIndex].movements.forEach(
          (movement, index) => {
            movement.position = index + 1;
          }
        );
        setSelectedMovementIndex(null);
        setSelectedIntervalIndex(null);
        break;
      case "interval":
        // Check if there is at least one interval in the selected movement
        if (
          updatedSession.segments[selectedSegmentIndex].movements[
            selectedMovementIndex
          ].intervals.length === 1
        ) {
          alert("At least one interval must exist in the movement.");
          return;
        }
        //we need to delete the interval
        updatedSession.segments[selectedSegmentIndex].movements[
          selectedMovementIndex
        ].intervals.splice(selectedIntervalIndex, 1);
        updatedSession.segments[selectedSegmentIndex].movements[
          selectedMovementIndex
        ].intervals.forEach((interval, index) => {
          interval.position = index + 1;
        });
        setSelectedIntervalIndex(null);
        break;
      default:
        //default case
    }
    global_session = updatedSession;
    localStorage.setItem("draft_session", JSON.stringify(global_session));
    setSession(updatedSession);
  };
  const handleLoading = (loading, message) => {
    setSomethingLoading(loading);
    setLoadingMessage(message);
  }
  const handleSelect = async (option, intervalIndex) => {
    if(option.type === "difficulty") {
      global_session.difficulty = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, difficulty: option.value});
    }
    if(option.type === "media_source") {
      
      const completeMediaSourceChange = async () => {
        if(option.value==="Spotify"){
          setLoadingMessage("Checking if you've given Runerval access. Make sure popups are not disabled.")
          setSomethingLoading(true);
          await authenticateWithSpotify(token, setToken);
          let spotify_access_data = JSON.parse(localStorage.getItem('spotifyAccess'));
          console.log("spotify_access_data", spotify_access_data)
          if(spotify_access_data){
            //we need to check if the user has premium
            let spotify_user_data;
            try{
              let spot_endpoint = "https://api.spotify.com/v1/me"
              setLoadingMessage("Checking if you have Spotify Premium.")
              spotify_user_data = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint="+spot_endpoint, "GET", null, token, setToken);
            }catch(error){
              console.error(error)
              alert("Failed to get Spotify user data")
            }
            if(spotify_user_data?.product === "premium"){
              console.log("USER HAS PREMIUM")
              setSomethingLoading(false);
              global_session.media_source = option.value;
              option.value === "None" ? setPlayingMedia(false) : setPlayingMedia(true)
              setMediaSource(option.value)
              localStorage.setItem('draft_session', JSON.stringify(global_session));
              setSession({...global_session, media_source: option.value});
            }else{
              setSomethingLoading(false);
              alert("You need to have Spotify Premium to use Spotify as a media source.")
              return
            }
          }else{
            console.log("no access")
          }


          // console.log("ATTEMPTEDING")
          // setLoadingMessage("Starting Spotify Authentication")
          // setSomethingLoading(true);
          // await authenticateWithSpotify(token, setToken);
          // setSomethingLoading(false);


        }else{
          global_session.media_source = option.value;
          option.value === "None" ? setPlayingMedia(false) : setPlayingMedia(true)
          setMediaSource(option.value)
          localStorage.setItem('draft_session', JSON.stringify(global_session));
          setSession({...session, media_source: option.value});
        }

      }
    
      const hasLinkedMedia = global_session.segments.some(segment =>
        segment.movements.some(movement => movement.media.location !== "")
      );

      if (hasLinkedMedia) {
        const userChoice = window.confirm("There is linked media in one or more movements. Changing the source will unlink that media. Do you want to continue?");
        if (userChoice) {
          global_session.segments.forEach(segment => {
            segment.movements.forEach(movement => {
              if (movement.media.location !== "") {
                movement.media = createMedia(); // Replace with your default data structure for media
              }
            });
          });
          completeMediaSourceChange()
        }else{
          return
        }
      }else{
        completeMediaSourceChange()
      }
    
      

      // Call the checkIfMediaIsLinked function whenever you want to check for linked media
    }
    if(option.type === "coach") {
      global_session.coach_id = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, coach_id: option.value});
    }
    if(option.type === "genre") {
      global_session.genres.push(option.value);
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, genres: [...session.genres, option.value]});
    }
    if(option.type === "run_type") {
      global_session.type = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, type: option.value});
    }
    if(option.type === "public") {
      global_session.open_to_public = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, open_to_public: option.value});
    }
    if(option.type === "segment") {
      global_session.segments[selectedSegmentIndex].type = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, segments: global_session.segments});
    }
    if(option.type === "pace") {
      console.log(selectedIntervalIndex)
      global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[intervalIndex].pace = option.value;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession({...session, segments: global_session.segments});
    }
    
  };
  const handleSelectQuickBuild = async (option, intervalIndex) => {
    if(option.type === "quick_build_time") {
      global_quick_build_session.quick_build_time = option.value;
      setQuickBuildSessionData({...global_quick_build_session, quick_build_time: option.value});
    }
    if(option.type === "difficulty") {
      global_quick_build_session.difficulty = option.value;
      setQuickBuildSessionData({...global_quick_build_session, difficulty: option.value});
    }
    if(option.type === "media_source") {
      if(option.value === "Spotify"){  
        //we need to check if user has Spotify Premium
        setLoadingMessage("Checking if you've given Runerval access. Make sure popups are not disabled.")
        setSomethingLoading(true);
        await authenticateWithSpotify(token, setToken);
        let spotify_access_data = JSON.parse(localStorage.getItem('spotifyAccess'));
        console.log("spotify_access_data", spotify_access_data)
        if(spotify_access_data){
          //we need to check if the user has premium
          let spotify_user_data;
          try{
            let spot_endpoint = "https://api.spotify.com/v1/me"
            setLoadingMessage("Checking if you have Spotify Premium.")
            spotify_user_data = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint="+spot_endpoint, "GET", null, token, setToken);
          }catch(error){
            console.error(error)
            alert("Failed to get Spotify user data")
          }
          if(spotify_user_data?.product === "premium"){
            console.log("USER HAS PREMIUM")
            setSomethingLoading(false);
            setMediaSource(option.value)
            global_quick_build_session.media_source = option.value;
            setQuickBuildSessionData({...global_quick_build_session, media_source: option.value});
          }else{
            setSomethingLoading(false);
            alert("You need to have Spotify Premium to use Spotify as a media source.")
            return
          }
        }else{
          console.log("no access")
        }
      }
      global_quick_build_session.media_source = option.value;
      setQuickBuildSessionData({...global_quick_build_session, media_source: option.value});
    }
    if(option.type === "coach") {
      option.value = JSON.parse(option.value)
      console.log(option.value)
      console.log(option.value.id)
      console.log(option.value.name)
      console.log(option.value.photo)
      global_quick_build_session.coach_id = {
        id: option.value.id,
        name: option.value.name,
        photo: option.value.photo
      };
      setQuickBuildSessionData({...global_quick_build_session, coach_id: {
        id: option.value.id,
        name: option.value.name,
        photo: option.value.photo
      }});
    }
    if(option.type === "genre") {
      global_quick_build_session.genres.push(option.value);
      setQuickBuildSessionData({...global_quick_build_session, genres: [...global_quick_build_session.genres, option.value]});
    }
    if(option.type === "run_type") {
      global_quick_build_session.type = option.value;
      setQuickBuildSessionData({...global_quick_build_session, type: option.value});
    }
    if(option.type === "public") {
      global_quick_build_session.open_to_public = option.value;
      setQuickBuildSessionData({...global_quick_build_session, open_to_public: option.value});
    }
    if(option.type === "custom_instructions") {
      global_quick_build_session.custom_instructions = option.value;
      setQuickBuildAreWeUsingCustomInstructions(option.value);
      if(option.value===true){
        setAreWeFetchingCharacterCount(true);
        let character_count = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/gptTokensAndElevenLabsCharacters", "GET", null, token, setToken)
        console.log(character_count)
        if(character_count.length > 0){
          character_count = character_count[0].characters;
        }else{
          character_count = 0;
        }
        setUsersCharacterCount(character_count)
        setAreWeFetchingCharacterCount(false);
      }
      setQuickBuildSessionData({...global_quick_build_session, custom_instructions: option.value});
    }
  };
  const handleGenreSelectionChange = (selectedGenres) => {
    // Update global_session with the new genres
    const updatedSession = { ...global_session, genres: selectedGenres };
    global_session = updatedSession; // Update the global_session object
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
  };
  const handleGenreSelectionChangeQuickBuild = (selectedGenres) => {
    // Update global_session with the new genres
    const updatedSession = { ...global_quick_build_session, genres: selectedGenres };
    global_quick_build_session = updatedSession; // Update the global_session object
    setQuickBuildSessionData(updatedSession);
  };
  // const updateTimes = (selectedIntervalIndex) => {
  //   //we need to update the session time, the segment time, the movement time, and the interval time
  //   let updatedSession = {...global_session};
  //   let updatedSegment = {...updatedSession.segments[selectedSegmentIndex]};
  //   let updatedMovement = {...updatedSegment.movements[selectedMovementIndex]};
  //   let updatedInterval = {...updatedMovement.intervals[selectedIntervalIndex]};
  //   //update interval time
  //   updatedInterval.time.total_seconds = parseInt(updatedInterval.time.h)*3600 + parseInt(updatedInterval.time.m)*60 + parseInt(updatedInterval.time.s);
  //   //update movement time
  //   updatedMovement.time = updatedMovement.intervals.reduce((sum, interval) => sum + interval.time.total_seconds, 0);
  //   //update segment time
  //   updatedSegment.time = updatedSegment.movements.reduce((sum, movement) => sum + movement.time, 0);
  //   //update session time
  //   updatedSession.time = updatedSession.segments.reduce((sum, segment) => sum + segment.time, 0);
  //   //update global session
  //   global_session = updatedSession;
  //   console.log("updatedSession", updatedSession)
  //   localStorage.setItem('draft_session', JSON.stringify(global_session));
  //   setSession(updatedSession);
  //   console.log("did this fire?")
  // }
  function recalculateTimes(new_global_session) {
    new_global_session.time = 0; // Reset session time

    new_global_session.segments.forEach(segment => {
      segment.time = 0; // Reset segment time
  
      segment.movements.forEach(movement => {
        movement.time = 0; // Reset movement time
  
        movement.intervals.forEach(interval => {
          movement.time += interval.time.total_seconds; // Sum up interval times for the movement
        });
  
        segment.time += movement.time; // Add movement time to segment time
      });
  
      new_global_session.time += segment.time; // Add segment time to session time
    });
    global_session = new_global_session;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(new_global_session);

  }
  const handleToggle = (toggleIdentifier, isOn) => {
    switch (toggleIdentifier) {
      case 'playMediaDuringMovement':
        // update the selected movement playing_media true or false based on switch
        global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].playing_media = isOn;
        localStorage.setItem('draft_session', JSON.stringify(global_session));
        setSession({...session, segments: global_session.segments});
        checkIfMediaIsPlayingInAnyMovement()
        break;
      case 'startMediaRandom':
        // update the selected movement .media.starttime.random true or false based on switch
        global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.random = isOn;
        localStorage.setItem('draft_session', JSON.stringify(global_session));
        setSession({...session, segments: global_session.segments});
        break;
      case 'playMediaContinuously':
      // update the selected movement .media.starttime.continuous_playback true or false based on switch
        global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.continuous_playback = isOn;
        //we need to loop through all other media and see if their locations match the current seklectedsegment and selectedmovement and set their continuous_playback to isOn
        global_session.segments.forEach(segment => {
          segment.movements.forEach(movement => {
            if(global_session.media_source === "YouTube"){
              let did_something_change = false;
              console.log("getVideoIDFromURL(movement.media.location)", getVideoIDFromURL(movement?.media?.location))
              console.log("getVideoIDFromURL(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)", getVideoIDFromURL(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location))
              //make sure they are not null
              if(getVideoIDFromURL(movement?.media?.location).id != null && getVideoIDFromURL(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location).id != null){
                if(getVideoIDFromURL(movement?.media?.location).id === getVideoIDFromURL(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location).id){
                  movement.media.continuous_playback = isOn;
                  did_something_change = true;
                }
              }
              if(did_something_change === true){
                //toast
                if(isOn){
                  showToast("Continous playback set to true for matching videos.", "success")
                }else{
                  showToast("Continous playback set to false for matching videos.", "success")
                }
              }
            }
            if(global_session.media_source === "Spotify"){
              let did_something_change = false;
              console.log("spotifyUrlParser(movement.media.location)", spotifyUrlParser(movement.media.location))
              if(spotifyUrlParser(movement?.media?.location) != null && spotifyUrlParser(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location) != null){
                if(spotifyUrlParser(movement?.media?.location)?.uri === spotifyUrlParser(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)?.uri){
                  movement.media.continuous_playback = isOn;
                  did_something_change = true;
                } 
              }
              if(did_something_change === true){
                //toast
                if(isOn){
                  showToast("Continous playback set to true for matching tracks.", "success")
                }else{
                  showToast("Continous playback set to false for matching tracks.", "success")
                }
              }
            }
          });
        });
        localStorage.setItem('draft_session', JSON.stringify(global_session));
        setSession({...session, segments: global_session.segments});
        break;
      default:
        // Default case
    }
  };
  const fetchYouTubeData = async (searchString) => {
    setSomethingLoading(true);
    setLoadingMessage("Fetching YouTube Data")
    let updatedSession = {...global_session};
    // Get the video ID from the URL
    let youTubeSearchStringParsed = getVideoIDFromURL(searchString, null);
    console.log("youTubeSearchStringParsed", youTubeSearchStringParsed)
    if(youTubeSearchStringParsed.type === "video") {
      //we need to fetch process.env.REACT_APP_GET_YOUTUBE_VIDEO_DATA_URL
      //we also need to pass a auth header with the user token
      let youTubeData;
      try{
        youTubeData = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_YOUTUBE_VIDEO_DATA_URL+"?videoId="+youTubeSearchStringParsed.id, "GET", null, token, setToken);
      }catch(error){
        console.log("Failed to get YouTube data")
        alert("failed to get youtube data")
      }
        console.log("youTubeData", youTubeData)
        console.log(youTubeData)
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.thumbnail = youTubeData.snippet.thumbnails;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.title = youTubeData.snippet.title;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.artist = youTubeData.snippet.channelTitle;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.description = youTubeData.snippet.description;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.number_of_items = 1;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.type = "item";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.source = "YouTube";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.location = searchString;
        let durationObject;
        try{
          durationObject = parseYouTubeVideoDuration(youTubeData.contentDetails.duration);
        }catch(e){
          console.error(e)
          alert("Error", "There was an error getting the duration of the video.")
          durationObject = {
              h: "00",
              m: "00",
              s: "00",
              total_seconds: 0
          };
        }

        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.duration = durationObject.total_seconds;

        //we need to loop through all other media and see if their locations match the current seklectedsegment and selectedmovement and set their continuous_playback to isOn  
        let is_there_a_media_item_continuous_true = false;
        updatedSession.segments.forEach(segment => {
          segment.movements.forEach(movement => {
            if(updatedSession.media_source === "YouTube"){
              console.log("getVideoIDFromURL(movement.media.location)", getVideoIDFromURL(movement?.media?.location))
              console.log("getVideoIDFromURL(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)", getVideoIDFromURL(updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location))
              //make sure they are not null
              if(getVideoIDFromURL(movement?.media?.location).id != null && getVideoIDFromURL(updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location).id != null){
                if(getVideoIDFromURL(movement?.media?.location).id === getVideoIDFromURL(updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location).id){
                  if(movement?.media?.continuous_playback === true){
                    is_there_a_media_item_continuous_true = true;
                  }
                }
              }
            }
          });
        });
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.continuous_playback = is_there_a_media_item_continuous_true;

    }else if(youTubeSearchStringParsed.type === "playlist") {

      try{
        let youtubePlaylistData = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_YOUTUBE_PLAYLIST_DATA_URL+"?playlistId="+youTubeSearchStringParsed.id, "GET", null, token, setToken);
        console.log("youtubePlaylistData", youtubePlaylistData)
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.thumbnail = youtubePlaylistData.snippet.thumbnails;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.title = youtubePlaylistData.snippet.title;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.artist = youtubePlaylistData.snippet.channelTitle;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.number_of_items = youtubePlaylistData.contentDetails.itemCount;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.description = youtubePlaylistData.snippet.description;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.type = "playlist";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.source = "YouTube";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.location = searchString;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.duration = 0;
      }catch(error){
        //lets alert the user of the error
        alert("Error getting playlist data")
        console.error(error)
      }

    }else{
      alert("We coudln't figure out the video id or playlist id from the url you entered. Please try again.")
    }
    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
    setSomethingLoading(false);
    setLoadingMessage("Loading...")
  }
  const fetchSpotifyData = async (searchString) => {
    setSomethingLoading(true);
    setLoadingMessage("Fetching Spotify Data")
    let updatedSession = {...global_session};
    // Get the video ID from the URL
    let spotifySearchStringParsed = spotifyUrlParser(searchString);
    if(spotifySearchStringParsed===null){
      alert("We couldn't figure out the Spotify id from the url you entered. Please try again.")
      setSomethingLoading(false);
      return
    }
    let spotify_access_data = localStorage.getItem('spotifyAccess');
    console.log(spotify_access_data)
    //check if expires in is later than now
    let is_token_expired = false;
    if(spotify_access_data){
      spotify_access_data = JSON.parse(spotify_access_data)
      let date = new Date();
      let time = date.getTime();
      if(spotify_access_data.expires_at < time){
        is_token_expired = true;
      }
    }else{
      is_token_expired = true;
    }
    if(is_token_expired){
      //we need to refresh the token
      try {
          await authenticateWithSpotify(token, setToken);
      } catch(error) {
          // Handle error
          alert("error", error)
      }
      spotify_access_data = localStorage.getItem('spotifyAccess');
      spotify_access_data = JSON.parse(spotify_access_data)
    }
    // type: parts[1],
    // id: parts[2],
    // uri: urlOrUri
    console.log("spotifySearchStringParsed", spotifySearchStringParsed)
    if(spotifySearchStringParsed.type === "track") {
      //we need to fetch process.env.REACT_APP_GET_YOUTUBE_VIDEO_DATA_URL
      //we also need to pass a auth header with the user token
      try{
        let spot_endpoint = "https://api.spotify.com/v1/tracks/"+spotifySearchStringParsed.id
          
          
        let spotifyData = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint="+spot_endpoint, "GET", null, token, setToken);
        console.log("spotifyData", spotifyData)

        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.thumbnail = spotifyData.album.images[0].url;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.title = spotifyData.name;
        //loop through the artists and concatinate with "," with name
        let artist_string = "";
        for(let i = 0; i < spotifyData.artists.length; i++){
          artist_string += spotifyData.artists[i].name;
          if(i < spotifyData.artists.length-1){
            artist_string += ", "
          }
        }
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.artist = artist_string;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.description = spotifyData.album.name;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.number_of_items = 1;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.type = "item";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.source = "Spotify";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.location = spotifyData.uri;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.duration = spotifyData.duration_ms/1000;

        let is_there_a_media_item_continuous_true = false;
        global_session.segments.forEach(segment => {
          segment.movements.forEach(movement => {
            if(global_session.media_source === "Spotify"){
              console.log("spotifyUrlParser(movement.media.location)", spotifyUrlParser(movement.media.location))
              if(spotifyUrlParser(movement?.media?.location) != null && spotifyUrlParser(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location) != null){
                if(spotifyUrlParser(movement?.media?.location)?.uri === spotifyUrlParser(global_session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)?.uri){
                  if(movement?.media?.continuous_playback === true){
                    is_there_a_media_item_continuous_true = true;
                  }
                } 
              }
            }
          });
        });
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.continuous_playback = is_there_a_media_item_continuous_true;
      }catch(error){
        alert(error)
      }

    }else if(spotifySearchStringParsed.type === "playlist") {

    try{
      let spot_endpoint = "https://api.spotify.com/v1/playlists/"+spotifySearchStringParsed.id
        
          
        let spotifyData = await fetchDataWithTokenRefresh(process.env.REACT_APP_SPOTIFY_REQUEST+"?spotify_access_token="+spotify_access_data.access_token+"&endpoint="+spot_endpoint, "GET", null, token, setToken);
        console.log("spotifyData", spotifyData)

        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.thumbnail = spotifyData.images[0].url;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.title = spotifyData.name;
        //loop through the artists and concatinate with "," with name
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.artist = spotifyData.owner.display_name;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.description = ""
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.type = "playlist";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.source = "Spotify";
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.location = spotifyData.uri;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.duration = 0;
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.number_of_items = spotifyData.tracks.total;

              //continous playback code
              // for (const segment of theSession.segments) {
              //     for (const movement of segment.movements) {
              //     const media = movement.media;

              //     if (media && media.location ==== location && media.continuous_playback) {
              //         theSegment.movements[index].media.continuous_playback = true;
              //     }
              //     }
              // }
      }catch(error){
        alert(error)
      }

    }else{
      alert("We coudln't figure out the video id or playlist id from the url you entered. Please try again.")
    }
    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
    setSomethingLoading(false);
    setLoadingMessage("Loading...")
  }
  const getYouTubeVideoCurrentTime = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      return currentTime
    }
  };
  const getSpotifyCurrentTime = (newTime) => {
    console.log("newTime", newTime)
    setSpotifyPlayerCurrentTime(newTime);
  };
  const calculatePlaybackTime = (interval, index) => {
    //we have the interval and the index of the interval. We need to add the total time.total_seconds of all previous intervals in teh session and return their summation
    if(index===0){
      return formatMillisecondsToHMS(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.starttime?.total_seconds*1000);
    }
    let totalSeconds = 0;
    for(let i = 0; i < index; i++) {
      totalSeconds += session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals[i]?.time?.total_seconds;
    }
    const updatedSession = {...global_session};
    totalSeconds = totalSeconds + updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds;
    return formatMillisecondsToHMS(totalSeconds*1000);
  }
  const setTimeIntervalController = (index) => {
    let playerPlaybackTime;
    if(session?.media_source === "YouTube"){
      playerPlaybackTime = Math.floor(getYouTubeVideoCurrentTime());
      const updatedSession2 = {...global_session};
      playerPlaybackTime = playerPlaybackTime - updatedSession2.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds
    }
    if(session?.media_source === "Spotify"){
      playerPlaybackTime = Math.floor(spotifyPlayerCurrentTime);
      console.log("playerPlaybackTime", playerPlaybackTime)
    }
    if(index==="end_of_video"){
      index=session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals.length;
      playerPlaybackTime=Math.floor(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.duration);
      playerPlaybackTime = playerPlaybackTime - session.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds
    }
    console.log("playerPlaybackTime", playerPlaybackTime)
    const updatedSession = {...global_session};
    if(index===0){
      let local_playback_here = Math.floor(getYouTubeVideoCurrentTime());
      updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = local_playback_here;
      updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h = millisecondsToHMSObject(local_playback_here*1000).h;
      updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.m = millisecondsToHMSObject(local_playback_here*1000).m;
      updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s = millisecondsToHMSObject(local_playback_here*1000).s;
      global_session = updatedSession;
      localStorage.setItem('draft_session', JSON.stringify(global_session));
      setSession(updatedSession);
      return
    }
    //go through session and get all intervals that interval_length_lock = false;
    let i;
    let number_of_unlocked_intervals = 0;
    let total_time_of_locked_intervals = 0;
    console.log(number_of_unlocked_intervals)
    for(i=0; i < index; i ++) {
      if(!updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals[i]?.interval_length_lock) {
        number_of_unlocked_intervals++;
      }
      if(updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals[i]?.interval_length_lock) {
        total_time_of_locked_intervals = total_time_of_locked_intervals + updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals[i]?.time.total_seconds
      }
    }
    playerPlaybackTime = playerPlaybackTime - total_time_of_locked_intervals;
    if(playerPlaybackTime < 0){
      alert("Playback time is less than total time of locked intervals. Unlock intervals to set this time. ")
      return
    }
    if(number_of_unlocked_intervals <= 0){
      alert("You have no unlocked intervals to set. Unlock intervals to set this time.")
      return
    }
    let time_to_set_on_each_interval = Math.floor(playerPlaybackTime/number_of_unlocked_intervals);
    let u;
    for(u=0; u < index; u ++) {
      if(!updatedSession?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals[u]?.interval_length_lock) {
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].time.total_seconds=time_to_set_on_each_interval
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].time.h=millisecondsToHMSObject(time_to_set_on_each_interval*1000).h
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].time.m=millisecondsToHMSObject(time_to_set_on_each_interval*1000).m
        updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].time.s=millisecondsToHMSObject(time_to_set_on_each_interval*1000).s
      }
    }


    // let totalMovementTime = 0;
    // let totalSegmentTime = 0;
    // let totalSessionTime = 0;

    // // Loop through all intervals of current movement
    // for (let i = 0; i < updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.length; i++) {
    //   const interval = updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[i];
    //   totalMovementTime += interval.time.total_seconds;
    // }

    // // Set movement time
    // updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].time = totalMovementTime;

    // // Loop through all movements of current segment
    // for (let i = 0; i < updatedSession.segments[selectedSegmentIndex].movements.length; i++) {
    //   const movement = updatedSession.segments[selectedSegmentIndex].movements[i];
    //   totalSegmentTime += movement.media.time;
    // }

    // // Set segment time
    // updatedSession.segments[selectedSegmentIndex].time = totalSegmentTime;

    // // Loop through all segments of session
    // for (let i = 0; i < updatedSession.segments.length; i++) {
    //   const segment = updatedSession.segments[i];
    //   totalSessionTime += segment.time;
    // }

    // // Set session time
    // updatedSession.media.time = totalSessionTime;


    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
    recalculateTimes(updatedSession);
  }
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let updatedSession = {...global_session};

    const items = reorder(
      session.segments,
      result.source.index,
      result.destination.index
    );
    items.forEach((item, index) => {
      item.position = index + 1;
    });
    updatedSession.segments = items;
    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
  };
  const onDragEndMovements = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedSession = {...session};
    const movements = Array.from(updatedSession.segments[selectedSegmentIndex].movements);
    const [reorderedItem] = movements.splice(result.source.index, 1);
    movements.splice(result.destination.index, 0, reorderedItem);

    updatedSession.segments[selectedSegmentIndex].movements = movements;
    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
  };
  const onDragEndIntervals = (result) => {
    if (!result.destination) {
      return;
    }

    const updatedSession = { ...session };
    const intervals = Array.from(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals);
    const [reorderedItem] = intervals.splice(result.source.index, 1);
    intervals.splice(result.destination.index, 0, reorderedItem);

    updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals = intervals;
    global_session = updatedSession;
    localStorage.setItem('draft_session', JSON.stringify(global_session));
    setSession(updatedSession);
  };
  const checkIfWeCanChangeCoach = () => {
    let disable_the_dropdown = false;
    // Check if session.start_voice_line.default is true
    if (global_session.start_voice_line.default !== true) {
      disable_the_dropdown = true;
    }
    // Check if session.end_voice_line is true
    if (global_session.end_voice_line.default !== true) {
      disable_the_dropdown = true;
    }
    // Check segments
    global_session.segments.forEach((segment) => {
      // Check movements within each segment
      segment.movements.forEach((movement) => {
        // Check intervals within each movement
        movement.intervals.forEach((interval) => {
          // Check if interval.voiceline.default is not true
          if (interval.voiceline.default !== true) {
            disable_the_dropdown = true;
          }
        });
      });
    });
    console.log("disable_the_dropdown", disable_the_dropdown)
    disable_the_dropdown === true? setChooseCoachDisabled(true):setChooseCoachDisabled(false);
  }
  const checkIfMediaIsPlayingInAnyMovement = () => {
    let disableMediaSourceDropdown = false;
  
    // Loop through each segment
    global_session.segments.forEach((segment) => {
      // Loop through each movement in the segment
      segment.movements.forEach((movement) => {
        // Check if playing_media is true
        if (movement.playing_media === true) {
          disableMediaSourceDropdown = true;
        }
      });
    });
  
    console.log("disableMediaSourceDropdown", disableMediaSourceDropdown);
    setMediaSourceDisabled(disableMediaSourceDropdown);
  }

  useEffect(() => {

    if(user) {
      console.log("FIRED");
      newOrEditSession();
    }
    //check to see if new session or edit session

  }
  , []);// eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <div style={{minHeight:"calc(100vh - 178px)"}}>
          {user ? (
            <div>
              {user.product !== "free" ? (
              <div style={{display:"flex", flex:1, justifyContent:"center"}}>
                {somthingLoading && (
                  <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
                    <div className="loading-icon"></div>
                    <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
                    {quickBuildLoadingStatuses!==null && (
                      <div>
                        {quickBuildLoadingStatuses.generate_playlist ? (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Generate Playlist: {Math.round(quickBuildLoadingStatuses.generate_playlist)}%</h3>
                        ) : (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Generate Playlist: 0%</h3>
                        )}
                        {quickBuildLoadingStatuses.playlist_data ? (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Playlist Data: {Math.round(quickBuildLoadingStatuses.playlist_data)}%</h3>
                        ) : (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Playlist Data: 0%</h3>
                        )}
                        {quickBuildLoadingStatuses.session_playlist ? (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Session Playlist: {Math.round(quickBuildLoadingStatuses.session_playlist)}%</h3>
                        ) : (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Session Playlist: 0%</h3>
                        )}
                        {quickBuildLoadingStatuses.track_details ? (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Track Details: {Math.round(quickBuildLoadingStatuses.track_details)}%</h3>
                        ) : (
                          <h3 style={{marginTop:10, color:"#ffffff"}}>Track Details: 0%</h3>
                        )}
                        {quickBuildAreWeUsingCustomInstructions===true && (
                          <>
                            {quickBuildLoadingStatuses.characters ? (
                              <h3 style={{marginTop:10, color:"#ffffff"}}>Monthly Characters Usage: {quickBuildLoadingStatuses.characters}/10000</h3>
                            ):(
                              <h3 style={{marginTop:10, color:"#000000"}}>Monthly Characters Usage: (fetching)/10000</h3>
                            )}
                          </>
                        )}
                        {quickBuildAreWeUsingCustomInstructions===true && (
                          <>
                          {quickBuildLoadingStatuses.coach !== "max" ? (
                            <h3 style={{marginTop:10, color:"#ffffff"}}>Generate Coach: {Math.round(quickBuildLoadingStatuses.coach)}%</h3>
                          ):(
                            <h3 style={{marginTop:10, color:"#ff0000"}}>You've reached monthly max voiceline characters used.</h3>
                          )}
                          </>
                        )}
                        {quickBuildAreWeUsingCustomInstructions===false ? (
                          <>
                            {Math.round(quickBuildLoadingStatuses.generate_playlist) === 100 && Math.round(quickBuildLoadingStatuses.playlist_data) === 100 && Math.round(quickBuildLoadingStatuses.session_playlist) === 100 && Math.round(quickBuildLoadingStatuses.track_details) === 100 && (
                              <h3 style={{marginTop:10, color:"#ffffff"}}>Inserting session into database...</h3>
                            )}
                          </>
                        ):(
                          <>
                            {Math.round(quickBuildLoadingStatuses.generate_playlist) === 100 && Math.round(quickBuildLoadingStatuses.playlist_data) === 100 && Math.round(quickBuildLoadingStatuses.session_playlist) === 100 && Math.round(quickBuildLoadingStatuses.track_details) === 100 && Math.round(quickBuildLoadingStatuses.coach) === 100 && (
                              <h3 style={{marginTop:10, color:"#ffffff"}}>Inserting session into database...</h3>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {hasQuickOrAdvancedBeenChosen ? (
                  <div style={{display:"flex", flex:1, justifyContent:"center"}}>
                    {quickOrAdvanced === "advanced" ? (
                      <div style={{display:"flex", flex:1, flexDirection:"column"}}>
                        <div style={{gap:10, display: 'flex', justifyContent: 'space-between', padding: '10px', backgroundColor:"#F3F3F3", marginBottom:20}}>
                        <div style={{display:"flex", flex:1}}>
                          <button className="large" style={{flex:1, marginRight:10, backgroundColor:"#D81F1F"}} onClick={()=>{
                            const confirm = window.confirm("Continuing will delete all current session data. Are you sure you want to continue?");
                            if (confirm) {
                              localStorage.removeItem('draft_session');
                              window.location.reload();
                            }
                          }}>Reset Session</button>
                          <button className="large" style={{flex:1, backgroundColor:"#000000"}} onClick={()=>{
                            localStorage.removeItem(`bubbleClosed-${"session-details-instruction"}`);
                            localStorage.removeItem(`bubbleClosed-${"session-thumbnail-instruction"}`);
                            localStorage.removeItem(`bubbleClosed-${"session-coach-instructions-instruction"}`);
                            localStorage.removeItem(`bubbleClosed-${"segment-instruction-1"}`);
                            localStorage.removeItem(`bubbleClosed-${"movement-instruction-1"}`);
                            localStorage.removeItem(`bubbleClosed-${"interval-instruction-1"}`);
                            //frefresh the page
                            window.location.reload();
                          }}>Reset Help Bubbles</button>
                        </div>
                        <div style={{flex:1}}>
                        </div>
                        <button className="large" style={{flex:1}} onClick={async ()=>{
                          setLoadingMessage("Saving Session")
                          setSomethingLoading(true);
                          try{
                            await fetchDataWithTokenRefresh(process.env.REACT_APP_STORE_DATA_IN_DATABASE+"/?document=sessions", "POST", global_session, token, setToken); 
                            localStorage.removeItem('draft_session'); 
                            setSomethingLoading(false);
                            showToast("Session Saved")
                            navigate("/profile")
                          }catch(error){
                            setSomethingLoading(false);
                            alert(error.message)
                            console.error(error)
                          }
                        }}>Save</button>
                        </div>
                        <div style={{display:"flex", flex:1}}>
                          {doWeHaveSession ? (
                            <div style={{display:"flex", flex:1}}>
                              {showYouTubeIntervalController && (
                              <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999}}>
                                <div className="content-item-wrapper">
                                  <div style={{display:"flex", marginBottom:10, alignItems:"center"}}>
                                    <button className="small" style={{marginRight:10}}>?</button>
                                    <h3 style={{flex:1}}>Interval Controller</h3>
                                    <button className="medium" onClick={()=>{setShowYouTubeIntervalController(false)}}>Close</button>
                                  </div>
                                  <div style={{display:"flex"}}>
                                    {session.media_source === "YouTube" && (
                                      <YouTubeVideo videoId={getVideoIDFromURL(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex].media.location).id} ref={playerRef} />
                                    )}
                                    {session.media_source === "Spotify" && (
                                      <SpotifyPlayer onCurrentTimeChange={getSpotifyCurrentTime} media={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media}/>
                                    )}
                                    <div>
                                      <div style={{display:"flex", marginLeft:10, marginBottom:10}}>
                                        <div style={{display:"flex", flex:1}}>
                                          <button className="small" style={{marginRight:10}}>?</button>
                                          <h3 style={{flex:1}}>Length of Interval</h3>
                                        </div>
                                        <div style={{display:"flex", flex:1}}>
                                          <h3 style={{flex:1, textAlign:"end"}}>Playback Time</h3>
                                          <button className="small" style={{marginLeft:10}}>?</button>
                                        </div>
                                      </div>
                                      {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.intervals.map((interval, index) => (
                                      <div key={index} style={{display:"flex", alignItems:"center", marginLeft:10, marginTop:index>0?5:0, marginBottom:index>0?5:0, paddingTop:index>0?5:0, paddingBottom:index>0?5:0, paddingRight:5, paddingLeft:5, backgroundColor:index%2===0?"#ffffff":"#F2F2F2"}}>
                                        <div style={{display:"flex", flex:1, marginRight:100, alignItems:"center"}}>
                                          <div style={{marginRight:10, padding:5, display:"flex", borderRadius:50, height:20, width:20, backgroundColor:"#D4D4D4", justifyContent:"center", alignContent:"center"}}>
                                            <h3 style={{color:"#ffffff", textAlign:"center"}}>{index + 1}</h3>
                                          </div>
                                          <h3>{interval.pace} for {formatMillisecondsToHMS(interval.time.total_seconds*1000)}</h3>
                                          {interval.interval_length_lock ? (
                                            <IconLock className="icon-buttons red" onClick={()=>{
                                              let updatedSession = {...global_session};
                                  
                                              for (let u = index+1; u < updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.length; u++) {
                                                if(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].playback_time_lock) {
                                                  updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].playback_time_lock = false;
                                                }
                                              }
                                              
                                              updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].interval_length_lock = false;
                                              global_session = updatedSession;
                                              localStorage.setItem('draft_session', JSON.stringify(global_session));
                                              setSession(updatedSession);
                                            }}/>
                                          ):(
                                            <IconLockOpen className="icon-buttons" onClick={()=>{
                                              let updatedSession = {...global_session};
                                              updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].interval_length_lock = true;
                                              global_session = updatedSession;
                                              localStorage.setItem('draft_session', JSON.stringify(global_session));
                                              setSession(updatedSession);
                                            }}/>
                                          )}
                                        </div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          {interval.playback_time_lock ? (
                                            <IconLock className="icon-buttons red" onClick={()=>{
                                              let updatedSession = {...global_session};
                                              updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].playback_time_lock = false;
                                              for (let i = 0; i < index; i++) {
                                                //we need to check and see if there are any next intervals where playback_time_lock = true
                                                let are_there_future_locked_intervals = false;
                                                for (let u = i+1; u < updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.length; u++) {
                                                  if(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[u].playback_time_lock) {
                                                    are_there_future_locked_intervals = true;
                                                  }
                                                }
                                                if(!are_there_future_locked_intervals) {
                                                  updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[i].interval_length_lock = false;
                                                }
                                              }
                                              global_session = updatedSession;
                                              localStorage.setItem('draft_session', JSON.stringify(global_session));
                                              setSession(updatedSession); 
                                            }}/>
                                          ):(
                                            <IconLockOpen className="icon-buttons" onClick={()=>{
                                              let updatedSession = {...global_session};
                                              updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].playback_time_lock = true;
                                              for (let i = 0; i < index; i++) {
                                                updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[i].interval_length_lock = true;
                                              }
                                              global_session = updatedSession;
                                              localStorage.setItem('draft_session', JSON.stringify(global_session));
                                              setSession(updatedSession);
                                            }}/>
                                          )}
                                          <h3 style={{marginRight:10}}>{calculatePlaybackTime(interval, index)}</h3>
                                          <button className="medium" onClick={()=>{
                                            setTimeIntervalController(index)
                                            //lets set current interval playback_time_lock = true
                                            let updatedSession = {...global_session};
                                            updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].playback_time_lock = true;
                                            for (let i = 0; i < index; i++) {
                                              updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[i].interval_length_lock = true;
                                            }
                                            global_session = updatedSession;
                                            localStorage.setItem('draft_session', JSON.stringify(global_session));
                                            setSession(updatedSession);
                                            }}>Set Time</button>
                                        </div>
                                      </div>
                                      ))}
                                      <div style={{marginLeft:10, marginRight:10}}>
                                        <h3>Movement will end when video hits this time: {calculatePlaybackTime(null, global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.length)}</h3>
                                      </div>
                                      <div style={{display:"flex", marginLeft:10, marginTop:10}}>
                                        <button className="medium" style={{flex:1, marginRight:10}} onClick={()=>{
                                          setTimeIntervalController(global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.length)
                                        }}>Set End Time</button>
                                        <button className="medium" style={{flex:1}} onClick={()=>{
                                          setTimeIntervalController("end_of_video")
                                        }}>Set End Time End of Video</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              )}
                              {showCoachVoicelineBuilder && (
                              <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9998}}>
                                <div className="content-item-wrapper" style={{minWidth:550, display:"flex", flexDirection:'column', maxWidth:600}}>
                                  <h3>Coach: {session.coach_id.name}</h3>
                                  <h3>Characters Used this Month: {userNumberOfTokens}/10000</h3>
                                  <div style={{display:"flex"}}>
                                    <div style={{flex:1, marginRight:10}}>
                                      <h4 className='input-title'>Coach Voiceline:</h4>
                                      <input type="text" onChange={(event)=>{
                                        setVoicelineToGenerate(event.target.value)
                                      }}
                                      value={voicelineToGenerate}
                                      />
                                    </div>
                                    <button className="medium" style={{marginTop:5}} onClick={async ()=>{
                                      setSomethingLoading(true);
                                      setLoadingMessage("Generating Voiceline")


                                      let result = await fetchDataWithTokenRefresh(process.env.REACT_APP_FETCH_ELEVEN_LAB_VOICE_LINE, "POST", {text_to_speech: voicelineToGenerate, voice_id: session.coach_id.id}, token, setToken);



                                      console.log(result)
                                      setGeneratedVoiceLineBlob(result);
                                      const blobUrl = URL.createObjectURL(result);
                                      setGeneratedVoiceLineUrl(blobUrl);
                                      setNewlyGeneratedVoiceLine({
                                        id:'temp',
                                        online_location: 'temp',
                                        string: voicelineToGenerate,
                                        coach: {
                                            id: session.coach_id.id,
                                            name: session.coach_id.name
                                        },
                                        author: user.uid,
                                        default: false
                                      });
                                      setSomethingLoading(false);
                                    }}>Generate</button>
                                  </div>
                                  {newlyGeneratedVoiceLine && (
                                    <div style={{marginTop:10, display:"flex", alignItems:"flex-end"}}>
                                      <div style={{flex:1, marginRight:5 }}>
                                        <h3 style={{marginBottom:25}} className='input-title'>Generated Voiceline: {newlyGeneratedVoiceLine.string}</h3>
                                        {/* <button className="medium">Play</button> */}
                                        <audio controls src={generatedVoiceLineUrl}></audio>
                                      </div>
                                      <button style={{flex:1, marginLeft:5}} className="medium" onClick={async ()=>{
                                          if(newlyGeneratedVoiceLine===null) {
                                              alert("Error", "You need to generate a voiceline before you can save it.")
                                              return
                                          }
                                          setSomethingLoading(true);
                                          setLoadingMessage("Saving Voiceline")
                                          //set interval, movement, segment, and session with the new data
                                          let voiceline_obj_to_save = newlyGeneratedVoiceLine;
                                          voiceline_obj_to_save.id = generateId();
                                          //const soundFile = generatedVoiceLineUrl;
                                          let base64 = await blobToBase64(generatedVoiceLineBlob)
                                          console.log(base64)
                                          let uploaded_location = await fetchDataWithTokenRefresh(process.env.REACT_APP_PUT_FILES_IN_STORAGE+"/?fileFolder=coachVoiceLines&fileType=audio&fileName="+voiceline_obj_to_save.id, "POST", {file: base64}, token, setToken);
                                          let online_location = uploaded_location;
                                          if(online_location.code === "ECONNRESET" ||  online_location.error) {
                                              //let undo_character_charge = await fetchDataWithAuthorizationAndData(undoVoiceLineCharacters, userFirebaseToken, {text_to_speech: newlyGeneratedVoiceLineWeAreEditing?.string})
                                              await fetchDataWithTokenRefresh(process.env.REACT_APP_UNDO_VOICELINE_CHARACTERS, "POST", {text_to_speech: newlyGeneratedVoiceLine?.string}, token, setToken);

                                              let result = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/gptTokensAndElevenLabsCharacters", "GET", null, token, setToken);
                                              console.log(result)
                                              if(result.length > 0) {
                                                setUserNumberOfTokens(result[0].characters);
                                              }else{
                                                setUserNumberOfTokens(10000);
                                              }
                                              alert('Error', "Something went wrong storing the file. Please try again later. File size may be too big");

                                          }else{
                                              console.log(online_location)
                                              voiceline_obj_to_save.online_location = online_location.fileLocation;
                                              voiceline_obj_to_save.author = user.uid;
                                              voiceline_obj_to_save.coach = session.coach_id;
                                              

                                              //okay we have stored the file online and we have the character count correct. We need to now update the database with the new voiceline and associated data
                                              
                                              await fetchDataWithTokenRefresh(process.env.REACT_APP_STORE_DATA_IN_DATABASE+"/?document=coachVoiceLines", "POST", voiceline_obj_to_save, token, setToken);  

                                              let updatedSession = {...global_session};
                                              switch (coachVoiceLineEditingType) {
                                                case "start_voice_line":
                                                  updatedSession.start_voice_line = voiceline_obj_to_save;
                                                  break;
                                                case "end_voice_line":
                                                  updatedSession.end_voice_line = voiceline_obj_to_save;
                                                  break;
                                                default:
                                                  if (coachVoiceLineEditingType.startsWith("interval/")) {
                                                    let split = coachVoiceLineEditingType.split("/");
                                                    if (split.length === 4) { // Ensure we have the right number of parts
                                                      let segindex = parseInt(split[1], 10);
                                                      let movindex = parseInt(split[2], 10);
                                                      let intindex = parseInt(split[3], 10);
                                                      updatedSession.segments[segindex].movements[movindex].intervals[intindex].voiceline = voiceline_obj_to_save;
                                                    }
                                                  } else {
                                                    // Handle other cases
                                                  }
                                              }
                                              setSession(updatedSession);
                                              global_session = updatedSession;
                                              localStorage.setItem('draft_session', JSON.stringify(global_session));
                                              checkIfWeCanChangeCoach()

                                          }

                                          setVoicelineToGenerate(null);
                                          setCoachVoiceLineEditing(null);
                                          setNewlyGeneratedVoiceLine(null);
                                          setCoachVoiceLineEditingType(null);
                                          setShowCoachVoicelineBuilder(false);
                                          setSomethingLoading(false);

                                      }}>Save</button>
                                    </div>
                                  )}
                                  <div style={{width:"100%", height:1, backgroundColor:"#cccccc", marginTop:10, marginBottom:10}}></div>
                                  <div style={{display:"flex"}}>
                                  <h3 style={{flex:1, marginRight:10}}>
                                    Current Voiceline: {coachVoiceLineEditing.default === true ? "Default" : `"${coachVoiceLineEditing.string}"`}
                                  </h3>
                                    {coachVoiceLineEditing.default===true?(
                                      <h4>An automated instruction to increase or decrease pace.</h4>
                                    ):(
                                      <audio controls src={
                                        process.env.REACT_APP_ENV === "development" 
                                          ? coachVoiceLineEditing.online_location.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/")
                                          : coachVoiceLineEditing.online_location
                                      }></audio>
                                    )}
                                  </div>
                                  <button className="medium" style={{backgroundColor:"#ff0000", alignSelf:"flex-end"}} onClick={()=>{
                                    if (newlyGeneratedVoiceLine === null) {
                                      setShowCoachVoicelineBuilder(false);
                                    } else {
                                      const confirm = window.confirm("You have an unsaved voiceline. Do you want to continue?");
                                      if (confirm) {
                                        // Continue with the action
                                        setVoicelineToGenerate(null);
                                        setCoachVoiceLineEditing(null);
                                        setNewlyGeneratedVoiceLine(null);
                                        setCoachVoiceLineEditingType(null);
                                        setShowCoachVoicelineBuilder(false);
                                        setSomethingLoading(false);
                                      } else {
                                        // Cancel the action
                                      }
                                    }
                                  }}>Cancel</button>
                                </div>
                              </div>
                              )}
                              {showThumbnailSelector && (
                              <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9998}}>
                                <div className="content-item-wrapper" style={{minWidth:550, display:"flex", flexDirection:'column'}}>
                                  <h3>Select a Thumbnail</h3>
                                  <div style={{display:"flex"}}>
                                    <div style={{flex:1, marginRight:10}}>
                                      <h4 className='input-title'>URL to .png or .jpg</h4>
                                      <input type="text" onChange={(event)=>{
                                        setThumbnailSearchString(event.target.value)
                                      }}
                                      value={thumbnailSearchString}
                                      />
                                    </div>
                                    <button className="medium" style={{marginTop:5}} onClick={async ()=>{
                                      setSomethingLoading(true);
                                      setLoadingMessage("Loading Thumbnail")
                                      if(isValidImageUrl(thumbnailSearchString)){ 
                                        let updatedSession = {...global_session};
                                        updatedSession.thumbnail = thumbnailSearchString;
                                        global_session = updatedSession;
                                        localStorage.setItem('draft_session', JSON.stringify(global_session));
                                        setSession(updatedSession);
                                        setThumbnailSearchString("");
                                        setSomethingLoading(false);
                                        setShowThumbnailSelector(false);
                                      }else{
                                        alert("Your URL has an invalid image extension. Please try again.")
                                        setSomethingLoading(false);
                                      }
                                    }}>Confirm</button>
                                  </div>
                                  <h3 style={{marginBottom:10, marginTop:10}}>OR</h3>
                                  <h3 style={{marginBottom:10, marginTop:10}}>Upload a local .png or .jpg</h3>
                                  <input style={{color:"#000"}} type="file" accept="image/*" onChange={(event) => {
                                      setSomethingLoading(true);
                                      setLoadingMessage("Uploading Image");
                                      const file = event.target.files[0];
                                      if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
                                          const reader = new FileReader();
                                          reader.readAsDataURL(file);
                                          reader.onloadend = async () => {
                                              const base64String = reader.result;
                                              const base64Image = base64String.split(',')[1];
                                              const sessionId = session.id;
                                              try {
                                                  const uploaded_location = await fetchDataWithTokenRefresh(process.env.REACT_APP_PUT_FILES_IN_STORAGE + `/?fileFolder=sessionImages&fileType=image&fileName=session-${sessionId}`, "POST", { file: base64Image }, token, setToken);
                                                  console.log(uploaded_location);
                                                  // Your existing logic
                                                  let updatedSession = {...global_session};
                                                  updatedSession.thumbnail = uploaded_location.fileLocation+ '?timestamp=' + new Date().getTime();
                                                  ;
                                                  global_session = updatedSession;
                                                  localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                  setSession(updatedSession);
                                                  setSomethingLoading(false);
                                                  setShowThumbnailSelector(false);
                                                  setThumbnailSearchString("");
                                              } catch (error) {
                                                  setSomethingLoading(false);
                                                  alert("Error", "Something went wrong uploading your image. Please try again later.");
                                              }
                                          };
                                      } else {
                                          setSomethingLoading(false);
                                          alert("Invalid File", "Please select a valid .png or .jpg image file.");
                                      }
                                  }}/>
                                  <button className="medium" style={{backgroundColor:"#ff0000", marginTop:10}} onClick={()=>{
                                    setShowThumbnailSelector(false);
                                    setThumbnailSearchString("");
                                  }}>Cancel</button>
                                </div>
                              </div>
                              )}
                              <div style={{padding:10, marginBottom:100, flex:1}}>
                                <div style={{display:'flex', gap:"10px"}}>
                                  <div style={{flex:1}}>
                                    <Bubble id="session-details-instruction">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>1</h1>
                                      </div>
                                      <h3>Set the first details of your session.</h3>
                                      <h3>Make sure to choose a media source if you want to add media.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    <div>
                                      <h3>Set Details of your Session</h3>
                                      <h4 className='input-title'>Title</h4>
                                      <input
                                        type="text"
                                        onChange={(event)=>{
                                          const updatedSession = {...global_session};
                                          updatedSession.title = event.target.value;
                                          global_session = updatedSession;
                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                          setSession(updatedSession);
                                        }}
                                        value={session?.title===null?"":session?.title}
                                      ></input>
                                    </div>
                                    <div style={{display:'flex', gap:"10px", marginTop:10}}>
                                      <Dropdown options={difficulties} onSelect={handleSelect} title="Difficulty" selectedValue={session?.difficulty}/>
                                      <Dropdown disabledAlertMessage="You have media playing during movements. Disable playing media for all movements before changing the media source." disabled={mediaSourceDisabled} options={media_sources} onSelect={handleSelect} title="Media Source" selectedValue={session?.media_source}/>
                                    </div>
                                  </div>
                                  <div style={{flex:1}}>
                                    <Bubble id="session-thumbnail-instruction">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>2</h1>
                                      </div>
                                      <h3>Continue setting session options.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    {/* <h3>Choose a session thumbnail</h3>
                                    <div style={{flexDirection:"row", display:"flex"}}>
                                      <div className='image-button'>
                                        <div className='image-button-content' style={{backgroundImage:`url('${process.env.REACT_APP_ENV === "development" ? session?.thumbnail.replace("https://storage.googleapis.com/", "http://127.0.0.1:9199/") : session?.thumbnail}')`}}>
                                        </div>
                                      </div>
                                      <button className="medium" onClick={()=>{
                                        setShowThumbnailSelector(true);
                                      }}>Change Image</button>
                                    </div> */}
                                    <h3>&nbsp;</h3>
                                    <div style={{display:'flex', gap:"10px", marginTop:6}}>
                                      <Dropdown disabledAlertMessage="You have created custom coach instructions. You must set outro, intro, and intervals to default coach instructions to change the coach." disabled={chooseCoachDisabled} options={coaches} onSelect={handleSelect} title="Coach" selectedValue={session?.coach_id.name}/>
                                      <MultiSelectDropdown  defaultSelectedOptions={session.genres} onSelectionChange={handleGenreSelectionChange} options={genres} title="Select Genres" />
                                    </div>
                                    <div style={{display:'flex', gap:"10px", marginTop:10}}>
                                      <Dropdown options={run_types} onSelect={handleSelect} title="Type" selectedValue={session?.type}/>
                                      <Dropdown options={[{label: 'Yes', value: true, type:"public"}, {label: 'No', value: false, type:"public"}]} onSelect={handleSelect} title="Open to Public" selectedValue={session?.open_to_public}/>
                                    </div>
                                  </div>
                                  <div style={{flex:1}}>
                                    <Bubble id="session-coach-instructions-instruction">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>3</h1>
                                      </div>
                                      <h3>Generate audio that your coach will say during the session.</h3>
                                      <h3>Default means premade coach instructions will play.</h3>
                                      <h3>Custom instructions can be generated and saved to be played at the start and end of the session.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    <h3>Set what the coach says at the start and end:</h3>
                                    <div>
                                        <div style={{display:"flex", alignItems:"center"}}>
                                          <button className="small" style={{marginRight:10}}>?</button>
                                          <h3>Coach's Session Intro</h3>
                                        </div>
                                        <div style={{display:"flex", gap:"10px", alignItems:"center", marginTop:10}}>
                                          <button className={`medium ${session?.start_voice_line.default===false ? "unselected" : ""}`} style={{width:"100%"}} onClick={()=>{
                                            if (session?.start_voice_line.default === false) {
                                              const confirmChange = window.confirm("Changing to default will unlink a custom generated instruction. Do you want to continue?");
                                              if (!confirmChange) {
                                                return;
                                              }
                                            }
                                            const updatedSession = {...global_session};
                                            updatedSession.start_voice_line.default = true;
                                            global_session = updatedSession;
                                            localStorage.setItem('draft_session', JSON.stringify(global_session));
                                            setSession(updatedSession);
                                            checkIfWeCanChangeCoach()
                                          }}>Default</button>
                                          <button className={`medium ${session?.start_voice_line.default===true ? "unselected" : ""}`} style={{width:"100%"}} onClick={async ()=>{
                                            setLoadingMessage("Fetching Your Used Characters")
                                            setSomethingLoading(true);
                                            //we need to request for user's character count
                                            let result = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/gptTokensAndElevenLabsCharacters", "GET", null, token, setToken);
                                            console.log(result)
                                            if(result.length > 0) {
                                              setUserNumberOfTokens(result[0].characters);
                                            }else{  
                                              setUserNumberOfTokens(0);
                                            }
                                            setCoachVoiceLineEditing(session.start_voice_line);
                                            setCoachVoiceLineEditingType("start_voice_line");
                                            setSomethingLoading(false);
                                            setShowCoachVoicelineBuilder(true);
                                          }}>Custom</button>
                                        </div>
                                    </div>
                                    <div style={{marginTop:10}}>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        <button className="small" style={{marginRight:10}}>?</button>
                                        <h3>Coach's Session Outro</h3>
                                      </div>
                                      <div style={{display:"flex", gap:"10px", alignItems:"center", marginTop:10}}>
                                        <button className={`medium ${session?.end_voice_line.default===false ? "unselected" : ""}`} style={{width:"100%"}} onClick={()=>{
                                          if (session?.end_voice_line.default === false) {
                                            const confirmChange = window.confirm("Changing to default will unlink a custom generated instruction. Do you want to continue?");
                                            if (!confirmChange) {
                                              return;
                                            }
                                          }
                                          
                                          const updatedSession = {...global_session};
                                          updatedSession.end_voice_line.default = true;
                                          global_session = updatedSession;
                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                          setSession(updatedSession);
                                          checkIfWeCanChangeCoach();
                                        }}>Default</button>
                                        <button className={`medium ${session?.end_voice_line.default===true ? "unselected" : ""}`} style={{width:"100%"}} onClick={async ()=>{
                                          setLoadingMessage("Fetching Your Used Characters")
                                          setSomethingLoading(true);
                                          //we need to request for user's character count
                                          let result = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/gptTokensAndElevenLabsCharacters", "GET", null, token, setToken);
                                          console.log(result)
                                          if(result.length > 0) {
                                            setUserNumberOfTokens(result[0].characters);
                                          }else{  
                                            setUserNumberOfTokens(0);
                                          }
                                          setCoachVoiceLineEditing(session.end_voice_line);
                                          setCoachVoiceLineEditingType("end_voice_line");
                                          setSomethingLoading(false);
                                          setShowCoachVoicelineBuilder(true);
                                        }}>Custom</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{display:'flex', gap:"10px"}}>
                                  <div style={{flex:1, marginTop:10}}>
                                    <Bubble id="segment-instruction-1">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>4</h1>
                                      </div>
                                      <h3>Below are the top level segments.</h3>
                                      <h3>Add/Remove segments below.</h3>
                                      <h3>Click on as segment to edit, its contents will appear on the right.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    <div style={{marginTop:10}}>
                                      <div style={{display:"flex", alignItems:"center"}}>
                                        <h3>Session length: {formatMillisecondsToHMS(session?.time*1000)}</h3>
                                      </div>
                                      <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                          {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                              {session && session.segments.map((segment, index) => (
                                                <Draggable key={segment.id.toString()} draggableId={segment.id.toString()} index={index}>
                                                  {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                                      <div style={{marginTop: 10, backgroundColor: selectedSegmentIndex === index ? "#c2ffe8" : "#ffffff"}} onClick={() => setSelectedSegmentIndex(index)} className="content-item-wrapper">
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                          <div className="session-builder-numbers" style={{marginRight: 10}}>{segment.position}</div>
                                                          <div style={{flex: 1}}>
                                                            <h3>{segment.type} for {formatMillisecondsToHMS(segment.time * 1000)}</h3>
                                                            <h4>{segment.movements.length} movements</h4>
                                                          </div>
                                                          <div style={{marginRight: 10}}>
                                                            <Tooltip text="Delete Segment">
                                                              <IconDelete className="icon-buttons red" onClick={()=>{
                                                                deleteSegMoveInt("segment", index)
                                                                recalculateTimes(global_session)
                                                              }}/>
                                                            </Tooltip>
                                                          </div>
                                                          <div  {...provided.dragHandleProps}>
                                                            <IconDrag className="icon-buttons"/>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    </div>
                                    <button className="medium" style={{marginTop:10, width:"100%"}} onClick={()=>{
                                      ///lets add a segment to teh session and update the global session
                                    global_session.segments.push(createSegment({type: "Run", position:global_session.segments.length+1, time: 10, movements: [createMovement({type: "Run", intervals: [createInterval({pace: "Walk",  time: { s: "10", m: "00", h: "00", total_seconds: 10 }})]})]}));
                                    const updatedSession = {...global_session};
                                      //we need to recalculate all the times for movememnt nad segment and session
                                      recalculateTimes(updatedSession)
                                      //setSession({...session, segments: global_session.segments});

                                    }}>
                                        Add Segment
                                    </button>
                                  </div>
                                  <div style={{flex:1, marginTop:10}}>
                                    <Bubble id="movement-instruction-1">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>5</h1>
                                      </div>
                                      <h3>Below are the movements of selected segment.</h3>
                                      <h3>Add/Remove movements below.</h3>
                                      <h3>Click on as movement to edit, its contents will appear on the right.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    {session?.segments[selectedSegmentIndex] ? (
                                    <div>
                                      <h3 style={{marginTop:5, marginBottom:10}}>Segment length: {formatMillisecondsToHMS(session.segments[selectedSegmentIndex]?.time*1000)}</h3>
                                      <Dropdown options={segments} onSelect={handleSelect} title="What are we doing in this segment?" selectedValue={session.segments[selectedSegmentIndex]?.type}/>
                                      <h3 style={{marginTop:10}}>Movements:</h3>
                                      <DragDropContext onDragEnd={onDragEndMovements}>
                                        <Droppable droppableId="movements">
                                          {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                              {session.segments[selectedSegmentIndex]?.movements.map((movement, index) => (
                                                <Draggable key={movement.id} draggableId={movement.id} index={index}>
                                                  {(provided) => (
                                                    <div ref={provided.innerRef} {...provided.draggableProps}>
                                                      <div 
                                                        style={{marginTop: 10, backgroundColor: selectedMovementIndex === index ? "#c2ffe8" : "#ffffff"}}
                                                        onClick={() => setSelectedMovementIndex(index)} 
                                                        className="content-item-wrapper" 
                                                      >
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                          <div className="session-builder-numbers" style={{marginRight: 10}}>{index + 1}</div>
                                                          <div style={{flex: 1}}>
                                                            <h3>Length: {formatMillisecondsToHMS(movement.time * 1000)}</h3>
                                                            <h4>{movement.intervals.length} intervals</h4>
                                                          </div>
                                                          <div>
                                                            <Tooltip text="Delete Movement">
                                                              <IconDelete className="icon-buttons red" onClick={()=>{
                                                                deleteSegMoveInt("movement", index)
                                                                recalculateTimes(global_session)
                                                              }}/>
                                                            </Tooltip>
                                                          </div>
                                                          <div  {...provided.dragHandleProps}>
                                                            <IconDrag className="icon-buttons"/>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ))}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                      <button className="medium" style={{marginTop:10, width:"100%"}} onClick={()=>{
                                        const updatedSession = {...global_session};
                                        ///lets add a movement to teh segment and update the global session and selected segment
                                        updatedSession.segments[selectedSegmentIndex].movements.push(createMovement({type: "Run", position: global_session.segments[selectedSegmentIndex].movements.length+1, intervals: [createInterval({pace: "Walk",  time: { s: "10", m: "00", h: "00", total_seconds: 10 }})]}));
                                      
                                        //we need to recalculate all the times for movememnt nad segment and session
                                        recalculateTimes(updatedSession)
                                        // setSession({...session, segments: global_session.segments});
                                        // recalculateTimes();
                                      }}>
                                        Add Movement
                                      </button>
                                    </div>
                                    ):(
                                      <div>
                                        <h3>Select a segment to edit.</h3>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{flex:1, marginTop:10}}>
                                    <Bubble id="interval-instruction-1">
                                      <div style={{position:"absolute", top:-5, left:-5, display:"flex"}}>
                                        <h1
                                        style={{
                                          backgroundColor:"#ff0000",
                                          alignSelf:"flex-start",
                                          height:30,
                                          fontWeight:600,
                                          color:"#fff",
                                          borderRadius:30,
                                          width:30,
                                          textAlign:"center"
                                        }}>6</h1>
                                      </div>
                                      <h3>Below are the intervals of selected movement.</h3>
                                      <h3>Add/Remove intervals below.</h3>
                                      <h3>Set Custom Coach Instructions</h3>
                                      <h3>Add media to the movement.</h3>
                                      <h4>Click on this to close it.</h4>
                                    </Bubble>
                                    {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex] ? (
                                      <div>
                                        <h3 style={{marginTop:5, marginBottom:10}}>Movement length: {formatMillisecondsToHMS(session.segments[selectedSegmentIndex].movements[selectedMovementIndex].time*1000)}</h3>
                                        <h3 style={{marginTop:10, marginBottom:10}}>Intervals:</h3>
                                        <DragDropContext onDragEnd={onDragEndIntervals}>
                                          <Droppable droppableId="intervals">
                                            {(provided) => (
                                              <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {session.segments[selectedSegmentIndex].movements[selectedMovementIndex]?.intervals.map((interval, index) => (
                                                  <Draggable key={interval.id} draggableId={interval.id} index={index}>
                                                    {(provided) => (
                                                      <div ref={provided.innerRef} {...provided.draggableProps}>
                                                        <div style={{marginTop: 10}} className="content-item-wrapper">
                                                          <div style={{display:"flex", flexDirection:"row"}}>
                                                            <div className="session-builder-numbers">{index+1}</div>
                                                            <div style={{flex:1}}></div>
                                                            <Tooltip text="Delete Interval">
                                                              <IconDelete className="icon-buttons red" onClick={()=>{
                                                                deleteSegMoveInt("interval", index)
                                                                recalculateTimes(global_session)
                                                              }}/>
                                                            </Tooltip>
                                                            <Tooltip text="Click+Drag">
                                                              <div  {...provided.dragHandleProps}>
                                                                <IconDrag className="icon-buttons"/>
                                                              </div>
                                                            </Tooltip>
                                                          </div>
                                                          <div style={{marginBottom:10, display:"flex", flexDirection:"row"}}>
                                                            <div style={{display:"flex", alignItems:"center", whiteSpace:"nowrap", marginRight:10}}>
                                                              
                                                              <button className="small" style={{marginRight:10}}>?</button>
                                                              <h3 style={{flex:1}}>Coach's Instruction</h3>
                                                             
                                                            </div>
                                                            <div style={{display:"flex", gap:"10px", alignItems:"center", marginTop:10, flex:1}}>
                                                              <button className={`medium ${interval?.voiceline.default===false ? "unselected" : ""}`} style={{width:"100%"}} onClick={()=>{
                                                                const updatedSession = {...global_session};
                                                                if (updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].voiceline.default === false) {
                                                                  const confirmChange = window.confirm("Changing to default will unlink a custom generated instruction. Do you want to continue?");
                                                                  if (!confirmChange) {
                                                                    return;
                                                                  }
                                                                }
                                                                updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals[index].voiceline.default = true;
                                                                global_session = updatedSession;
                                                                localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                                setSession(updatedSession);
                                                                checkIfWeCanChangeCoach()
                                                              }}>Default</button>
                                                              <button className={`medium ${interval?.voiceline.default===true ? "unselected" : ""}`} style={{width:"100%"}} onClick={async ()=>{
                                                                setLoadingMessage("Fetching Your Used Characters")
                                                                setSomethingLoading(true);
                                                                //we need to request for user's character count
                                                                let result = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/gptTokensAndElevenLabsCharacters", "GET", null, token, setToken);
                                                                console.log(result)
                                                                if(result.length > 0) {
                                                                  setUserNumberOfTokens(result[0].characters);
                                                                }else{  
                                                                  setUserNumberOfTokens(0);
                                                                }
                                                                setCoachVoiceLineEditing(interval?.voiceline);
                                                                setCoachVoiceLineEditingType("interval/"+selectedSegmentIndex+"/"+selectedMovementIndex+"/"+index);
                                                                setSomethingLoading(false);
                                                                setShowCoachVoicelineBuilder(true);
                                                              }}>Custom</button>
                                                            </div>
                                                          </div>
                                                          <div style={{display:"flex", gap:"10px"}}>
                                                            <div style={{flex:1}}>
                                                              <Dropdown options={paces} onSelect={(option)=>{
                                                                handleSelect(option, index)
                                                                console.log("interval", interval)
                                                              }} title="Pace" selectedValue={interval.pace}/>
                                                            </div>
                                                            <div style={{flex:1}}>
                                                              <h4 className='input-title'>Hours</h4>
                                                              <input
                                                                type="number"
                                                                min="0"
                                                                max="23"
                                                                step="1"
                                                                value={interval.time.h}
                                                                onChange={(e) => {
                                                                  // Deep copy the specific segment and movement you're updating
                                                                  const updatedSegment = { ...global_session.segments[selectedSegmentIndex] };
                                                                  updatedSegment.movements = [...updatedSegment.movements];

                                                                  const updatedMovement = { ...updatedSegment.movements[selectedMovementIndex] };
                                                                  updatedMovement.intervals = [...updatedMovement.intervals];

                                                                  // Update the interval's time
                                                                  const updatedInterval = { ...updatedMovement.intervals[index], time: { ...updatedMovement.intervals[index].time }};
                                                                  updatedInterval.time.h = e.target.value;
                                                                  updatedInterval.time.total_seconds = parseInt(e.target.value) * 3600 + parseInt(updatedInterval.time.m) * 60 + parseInt(updatedInterval.time.s);

                                                                  // Assign the updated interval back to the movement
                                                                  updatedMovement.intervals[index] = updatedInterval;

                                                                  // Assign the updated movement back to the segment
                                                                  updatedSegment.movements[selectedMovementIndex] = updatedMovement;

                                                                  // Create a new session object with the updated segment
                                                                  const updatedSession = {
                                                                    ...global_session,
                                                                    segments: global_session.segments.map((seg, idx) => idx === selectedSegmentIndex ? updatedSegment : seg)
                                                                  };

                                                                  // Update the global session and state
                                                                  global_session = updatedSession;
                                                                  localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                                  setSession(updatedSession);
                                                                  recalculateTimes(updatedSession);
                                                                }}
                                                              ></input>
                                                            </div>
                                                            <div style={{flex:1}}>
                                                              <h4 className='input-title'>Minutes</h4>
                                                              <input
                                                                type="number"
                                                                min="0"
                                                                max="59"
                                                                step="1"
                                                                value={interval.time.m}
                                                                onChange={(e) => {
                                                                  // Deep copy the specific segment and movement you're updating
                                                                  const updatedSegment = { ...global_session.segments[selectedSegmentIndex] };
                                                                  updatedSegment.movements = [...updatedSegment.movements];

                                                                  const updatedMovement = { ...updatedSegment.movements[selectedMovementIndex] };
                                                                  updatedMovement.intervals = [...updatedMovement.intervals];

                                                                  // Update the interval's time
                                                                  const updatedInterval = { ...updatedMovement.intervals[index], time: { ...updatedMovement.intervals[index].time }};
                                                                  updatedInterval.time.m = e.target.value;
                                                                  updatedInterval.time.total_seconds =parseInt(updatedInterval.time.h) * 3600 + parseInt(e.target.value) * 60 + parseInt(updatedInterval.time.s);

                                                                  // Assign the updated interval back to the movement
                                                                  updatedMovement.intervals[index] = updatedInterval;

                                                                  // Assign the updated movement back to the segment
                                                                  updatedSegment.movements[selectedMovementIndex] = updatedMovement;

                                                                  // Create a new session object with the updated segment
                                                                  const updatedSession = {
                                                                    ...global_session,
                                                                    segments: global_session.segments.map((seg, idx) => idx === selectedSegmentIndex ? updatedSegment : seg)
                                                                  };

                                                                  // Update the global session and state
                                                                  global_session = updatedSession;
                                                                  localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                                  setSession(updatedSession);
                                                                  recalculateTimes(updatedSession);
                                                                }}
                                                              ></input>
                                                            </div>
                                                            <div style={{flex:1}}>
                                                              <h4 className='input-title'>Seconds</h4>
                                                              <input
                                                                type="number"
                                                                min="0"
                                                                max="59"
                                                                step="1"
                                                                value={interval.time.s}
                                                                onChange={(e) => {
                                                                // Deep copy the specific segment and movement you're updating
                                                                const updatedSegment = { ...global_session.segments[selectedSegmentIndex] };
                                                                updatedSegment.movements = [...updatedSegment.movements];

                                                                const updatedMovement = { ...updatedSegment.movements[selectedMovementIndex] };
                                                                updatedMovement.intervals = [...updatedMovement.intervals];

                                                                // Update the interval's time
                                                                const updatedInterval = { ...updatedMovement.intervals[index], time: { ...updatedMovement.intervals[index].time }};
                                                                updatedInterval.time.s = e.target.value;
                                                                updatedInterval.time.total_seconds =parseInt(updatedInterval.time.h) * 3600 + parseInt(updatedInterval.time.m) * 60 + parseInt(e.target.value);

                                                                // Assign the updated interval back to the movement
                                                                updatedMovement.intervals[index] = updatedInterval;

                                                                // Assign the updated movement back to the segment
                                                                updatedSegment.movements[selectedMovementIndex] = updatedMovement;

                                                                // Create a new session object with the updated segment
                                                                const updatedSession = {
                                                                  ...global_session,
                                                                  segments: global_session.segments.map((seg, idx) => idx === selectedSegmentIndex ? updatedSegment : seg)
                                                                };

                                                                // Update the global session and state
                                                                global_session = updatedSession;
                                                                localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                                setSession(updatedSession);
                                                                recalculateTimes(updatedSession);
                                                                }}
                                                              ></input>
                                                            </div>
                                                          </div>
                                                          </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                ))}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                          <div style={{marginTop:10}}>
                                            <button className='medium' style={{width:"100%"}} onClick={()=>{
                                              ///lets add a interval to the movememnt and update the global session and selected segment
                                              global_session.segments[selectedSegmentIndex].movements[selectedMovementIndex].intervals.push(createInterval({pace: "Walk", time: { s: "10", m: "00", h: "00", total_seconds: 10 }}));
                                              const updatedSession = {...global_session};
                                              //we need to recalculate all the times for movememnt nad segment and session
                                              recalculateTimes(updatedSession)
                                              // setSession({...session, segments: global_session.segments});
                                              // recalculateTimes();
                                            }}>
                                              Add Interval
                                            </button>
                                          </div>
                                          {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.source === "YouTube" && (
                                          <div style={{marginTop:10}}>
                                            {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media && session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.playing_media && (
                                              <div className="content-item-wrapper">
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                  <img style={{maxWidth:200, marginRight:10}} src={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail.maxres?.url || session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail.high?.url || session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail.medium?.url || session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail.standard?.url || session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail.default?.url} alt="Media Thumbnail" />
                                                  <div>
                                                    <h3 className="truncate-multiline" style={{marginBottom:10}}>{session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.title}</h3>
                                                    <h4 className="truncate-multiline">{session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.artist}</h4>
                                                    {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                    <h4>Duration: {formatMillisecondsToHMS(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.duration*1000)}</h4>
                                                    )}
                                                    {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "playlist" && (
                                                    <h4>Number of Videos: {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.number_of_items}</h4>
                                                    )}
                                                  </div>
                                                </div>
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.random===false && (
                                                <div style={{marginTop:10}}>
                                                  <h3>In-video playback start time:</h3>
                                                  <div style={{display:"flex"}}>
                                                    <div style={{flex:1, marginRight:10}}>
                                                        <h4 className='input-title'>Hours</h4>
                                                        <input
                                                          type="number"
                                                          min="0"
                                                          max="23"
                                                          step="1"
                                                          value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.h}
                                                          onChange={(e) => {
                                                            const updatedSession = {...global_session};
                                                            updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h = e.target.value;
                                                            // Update the global session and state
                                                            //we need to update the total seconds based on the new hours
                                                            updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(e.target.value) * 3600 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.m) * 60 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s);
                                                            global_session = updatedSession;
                                                            localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                            setSession(updatedSession);
                                                          }}
                                                        ></input>
                                                    </div>
                                                    <div style={{flex:1, marginRight:10}}>
                                                      <h4 className='input-title'>Minutes</h4>
                                                      <input
                                                        type="number"
                                                        min="0"
                                                        max="59"
                                                        step="1"
                                                        value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.m}
                                                        onChange={(e) => {
                                                          const updatedSession = {...global_session};
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.m = e.target.value;
                                                          // Update the global session and state
                                                          //we need to update the total seconds based on the new hours
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h) * 3600 + parseInt(e.target.value) * 60 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s);
                                                          global_session = updatedSession;
                                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                          setSession(updatedSession);
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <div style={{flex:1, marginRight:10}}>
                                                      <h4 className='input-title'>Seconds</h4>
                                                      <input
                                                        type="number"
                                                        min="0"
                                                        max="59"
                                                        step="1"
                                                        value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.s}
                                                        onChange={(e) => {
                                                          const updatedSession = {...global_session};
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s = e.target.value;
                                                          // Update the global session and state
                                                          //we need to update the total seconds based on the new hours
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h) * 3600 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s) * 60 + parseInt(e.target.value);
                                                          global_session = updatedSession;
                                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                          setSession(updatedSession);
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <button className="medium" style={{marginTop:5}} onClick={()=>{
                                                      setShowYouTubeIntervalController(true);
                                                    }}>
                                                      Interval Controller
                                                    </button>
                                                  </div>
                                                </div>
                                                )}
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                                                  <h3 style={{flex:1}}>Random in-video playback start time?</h3>
                                                  <ToggleSwitch initialState={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.starttime.random} onChange={(e) => handleToggle('startMediaRandom', e.target.checked)} />
                                                </div>
                                                )}
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                                                  <div style={{flex:1, display:"flex", alignItems:"center"}}>
                                                    <button className="small" style={{marginRight:10}}>?</button>
                                                    <h3>Play media continously?</h3>
                                                  </div>
                                                  <ToggleSwitch initialState={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.continuous_playback} onChange={(e) => handleToggle('playMediaContinuously', e.target.checked)} />
                                                </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          )}
                                          {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.source === "Spotify" && (
                                          <div style={{marginTop:10}}>
                                            {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media && session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.playing_media && (
                                              <div className="content-item-wrapper">
                                                <div style={{display:"flex", alignItems:"center"}}>
                                                  <img style={{maxWidth:100, marginRight:10}} src={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.thumbnail} alt="Media Thumbnail" />
                                                  <div>
                                                    <h3 className="truncate-multiline">{session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.title}</h3>
                                                    <h4 className="truncate-multiline">{session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.artist}</h4>
                                                    <h4 className="truncate-multiline">{session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.description}</h4>
                                                    {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                    <h4>Duration: {formatMillisecondsToHMS(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.duration*1000)}</h4>
                                                    )}
                                                    {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "playlist" && (
                                                    <h4>Number of Videos: {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.number_of_items}</h4>
                                                    )}
                                                  </div>
                                                </div>
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.random===false && (
                                                <div style={{marginTop:10}}>
                                                  <h3>In-video playback start time:</h3>
                                                  <div style={{display:"flex"}}>
                                                    <div style={{flex:1, marginRight:10}}>
                                                        <h4 className='input-title'>Hours</h4>
                                                        <input
                                                          type="number"
                                                          min="0"
                                                          max="23"
                                                          step="1"
                                                          value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.h}
                                                          onChange={(e) => {
                                                            const updatedSession = {...global_session};
                                                            updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h = e.target.value;
                                                            // Update the global session and state
                                                            //we need to update the total seconds based on the new hours
                                                            updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(e.target.value) * 3600 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.m) * 60 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s);
                                                            global_session = updatedSession;
                                                            localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                            setSession(updatedSession);
                                                          }}
                                                        ></input>
                                                    </div>
                                                    <div style={{flex:1, marginRight:10}}>
                                                      <h4 className='input-title'>Minutes</h4>
                                                      <input
                                                        type="number"
                                                        min="0"
                                                        max="59"
                                                        step="1"
                                                        value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.m}
                                                        onChange={(e) => {
                                                          const updatedSession = {...global_session};
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.m = e.target.value;
                                                          // Update the global session and state
                                                          //we need to update the total seconds based on the new hours
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h) * 3600 + parseInt(e.target.value) * 60 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s);
                                                          global_session = updatedSession;
                                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                          setSession(updatedSession);
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <div style={{flex:1, marginRight:10}}>
                                                      <h4 className='input-title'>Seconds</h4>
                                                      <input
                                                        type="number"
                                                        min="0"
                                                        max="59"
                                                        step="1"
                                                        value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.starttime.s}
                                                        onChange={(e) => {
                                                          const updatedSession = {...global_session};
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s = e.target.value;
                                                          // Update the global session and state
                                                          //we need to update the total seconds based on the new hours
                                                          updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.total_seconds = parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.h) * 3600 + parseInt(updatedSession.segments[selectedSegmentIndex].movements[selectedMovementIndex].media.starttime.s) * 60 + parseInt(e.target.value);
                                                          global_session = updatedSession;
                                                          localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                          setSession(updatedSession);
                                                        }}
                                                      ></input>
                                                    </div>
                                                    <button className="medium" style={{marginTop:5}} onClick={()=>{
                                                      setShowYouTubeIntervalController(true);
                                                    }}>
                                                      Interval Controller
                                                    </button>
                                                  </div>
                                                </div>
                                                )}
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                                                  <h3 style={{flex:1}}>Random in-video playback start time?</h3>
                                                  <ToggleSwitch initialState={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.starttime.random} onChange={(e) => handleToggle('startMediaRandom', e.target.checked)} />
                                                </div>
                                                )}
                                                {session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media.type === "item" && (
                                                <div style={{display:"flex", alignItems:"center", marginTop:10}}>
                                                  <div style={{flex:1, display:"flex", alignItems:"center"}}>
                                                    <button className="small" style={{marginRight:10}}>?</button>
                                                    <h3>Play media continously?</h3>
                                                  </div>
                                                  <ToggleSwitch initialState={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.continuous_playback} onChange={(e) => handleToggle('playMediaContinuously', e.target.checked)} />
                                                </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          )}
                                          {mediaSource==="YouTube" && session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.playing_media && (
                                          <div>
                                            <div style={{display:"flex"}}>
                                              <div style={{flex:1, marginRight:10}}>
                                                <h4 className='input-title'>YouTube Video/Playlist URL/ID</h4>
                                                <input
                                                  value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location}
                                                  onChange={
                                                    (e) => {
                                                      // Deep copy the specific segment and movement you're updating
                                                      const updatedSegment = { ...global_session.segments[selectedSegmentIndex] };
                                                      updatedSegment.movements = [...updatedSegment.movements];

                                                      const updatedMovement = { ...updatedSegment.movements[selectedMovementIndex] };
                                                      updatedMovement.media = {...updatedMovement.media};

                                                      // Update the interval's time
                                                      const updatedMedia = { ...updatedMovement.media, location: e.target.value };
                                                      updatedMovement.media = updatedMedia;

                                                      // Assign the updated interval back to the movement
                                                      updatedSegment.movements[selectedMovementIndex] = updatedMovement;

                                                      // Create a new session object with the updated segment
                                                      const updatedSession = {
                                                        ...global_session,
                                                        segments: global_session.segments.map((seg, idx) => idx === selectedSegmentIndex ? updatedSegment : seg)
                                                      };

                                                      // Update the global session and state
                                                      global_session = updatedSession;
                                                      localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                      setSession(updatedSession);
                                                    }
                                                  }
                                                ></input>
                                              </div>
                                              <button className='medium' style={{marginTop:5, marginRight:10}} onClick={()=>{fetchYouTubeData(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)}}>
                                                Search
                                              </button>
                                              <button className='medium' style={{marginTop:5}}>
                                                Help
                                              </button>
                                            </div>
                                          </div>
                                          )}
                                          {mediaSource==="Spotify" && session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.playing_media && (
                                          <div>
                                            <div style={{display:"flex"}}>
                                              <div style={{flex:1, marginRight:10}}>
                                                <h4 className='input-title'>Spotify Track/Playlist URI/URL</h4>
                                                <input
                                                  value={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location}
                                                  onChange={
                                                    (e) => {
                                                      // Deep copy the specific segment and movement you're updating
                                                      const updatedSegment = { ...global_session.segments[selectedSegmentIndex] };
                                                      updatedSegment.movements = [...updatedSegment.movements];

                                                      const updatedMovement = { ...updatedSegment.movements[selectedMovementIndex] };
                                                      updatedMovement.media = {...updatedMovement.media};

                                                      // Update the interval's time
                                                      const updatedMedia = { ...updatedMovement.media, location: e.target.value };
                                                      updatedMovement.media = updatedMedia;

                                                      // Assign the updated interval back to the movement
                                                      updatedSegment.movements[selectedMovementIndex] = updatedMovement;

                                                      // Create a new session object with the updated segment
                                                      const updatedSession = {
                                                        ...global_session,
                                                        segments: global_session.segments.map((seg, idx) => idx === selectedSegmentIndex ? updatedSegment : seg)
                                                      };

                                                      // Update the global session and state
                                                      global_session = updatedSession;
                                                      localStorage.setItem('draft_session', JSON.stringify(global_session));
                                                      setSession(updatedSession);
                                                    }
                                                  }
                                                ></input>
                                              </div>
                                              <button className='medium' style={{marginTop:5, marginRight:10}} onClick={async ()=>{
                                                await fetchSpotifyData(session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.media?.location)
                                                }}
                                                >
                                                Search
                                              </button>
                                              <button className='medium' style={{marginTop:5}}>
                                                Help
                                              </button>
                                            </div>
                                          </div>
                                          )}
                                          {playingMedia && (
                                          <div style={{marginTop:10}}>
                                            <div style={{display:"flex", alignItems:"center"}}>
                                              <h3 style={{flex:1}}>Play Media during movement?</h3>
                                              <ToggleSwitch initialState={session?.segments[selectedSegmentIndex]?.movements[selectedMovementIndex]?.playing_media} onChange={(e) => handleToggle('playMediaDuringMovement', e.target.checked)} />
                                            </div>
                                          </div>
                                          )}
                                      </div>
                                    ):(
                                      <div>
                                        <h3>Select a movement to edit.</h3>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <Toast message={toastMessage} type={toastType} isOpen={isToastOpen} closeToast={closeToast} />
                            </div>
                          ):(
                            <div>
                              <h1>Setting Session</h1>
                            </div>
                          )}
                        </div>
                      </div>
                    ):(
                      <div style={{maxWidth:800, display:'flex', flex:1, alignSelf:"center", flexDirection:"column", gap:10}}>
                        <h1 style={{marginTop:20}}>Quick Builder</h1>
                        {/* <input
                          type="text"
                          onChange={(event)=>{
                            const updatedSession = {...global_session};
                            updatedSession.title = event.target.value;
                            global_session = updatedSession;
                            localStorage.setItem('draft_session', JSON.stringify(global_session));
                            setSession(updatedSession);
                          }}
                          value={session?.title===null?"":session?.title}
                        ></input> */}
                        <Dropdown options={quick_build_time} onSelect={handleSelectQuickBuild} title="Length (minutes)" selectedValue={quickBuildSessionData?.quick_build_time}/>
                        <Dropdown options={difficulties} onSelect={handleSelectQuickBuild} title="Difficulty" selectedValue={quickBuildSessionData?.difficulty}/>
                        <Dropdown options={media_sources} onSelect={handleSelectQuickBuild} title="Media Source" selectedValue={quickBuildSessionData?.media_source}/>
                        {quickBuildSessionData?.media_source!=="None" && (
                          <div>
                            {quickBuildSessionData?.media_source==="YouTube" && (
                              <h4 className='input-title'>Video Playlist URL</h4>
                            )}
                            {quickBuildSessionData?.media_source==="Spotify" && (
                              <h4 className='input-title'>Spotify Playlist URL</h4>
                            )}
                            <input
                              type="text"
                              onChange={(event)=>{
                                const updatedSession = {...global_quick_build_session};
                                updatedSession.media_location = event.target.value;
                                global_quick_build_session = updatedSession;
                                setQuickBuildSessionData(updatedSession);
                              }}
                              value={quickBuildSessionData?.media_location===null?"":quickBuildSessionData?.media_location}
                            ></input>
                          </div>
                        )}
                        <Dropdown options={coaches} onSelect={handleSelectQuickBuild} title="Coach" selectedValue={quickBuildSessionData?.coach_id.name}/>
                        <Dropdown options={[{label: 'Yes', value: true, type:"custom_instructions"}, {label: 'No', value: false, type:"custom_instructions"}]} onSelect={handleSelectQuickBuild} title="AI Generated Coach Instructions" selectedValue={quickBuildSessionData?.custom_instructions}/>
                        {quickBuildSessionData?.custom_instructions===true && (
                          <div>
                            <h3>Your estimated session character usaged: {(quickBuildSessionData.quick_build_time/10) * 500} characters.</h3>
                            {!areWeFetchingCharacterCount ? (
                              <h3>Your monthly character usage: {usersCharacterCount}/10000</h3>
                            ):(
                              <div style={{display:"flex", flexDirection:'row'}}>
                                <h3>Your monthly character usage: </h3>
                                <div className="loading-icon"></div>
                                <h3> /10000</h3>
                              </div>
                            )}
                            {usersCharacterCount >= 10000 && (
                              <h3 style={{color:"#ff0000"}}>You've reached your character allotment for the month. AI coaching instructions will not be generated.</h3>
                            )}
                            {usersCharacterCount >= 9000 && usersCharacterCount < 10000 &&(
                              <h4 style={{color:"#9b870c"}}>You're nearing your limit. If your limit is reached while generating AI coach instructions the remaining movements will not generate AI instructions.</h4>
                            )}
                          </div>
                        )}
                        <MultiSelectDropdown onSelectionChange={handleGenreSelectionChangeQuickBuild} options={genres} title="Select Genres" />
                        <Dropdown options={run_types} onSelect={handleSelectQuickBuild} title="Type" selectedValue={quickBuildSessionData?.type}/>
                        <Dropdown options={[{label: 'Yes', value: true, type:"public"}, {label: 'No', value: false, type:"public"}]} onSelect={handleSelectQuickBuild} title="Open to Public" selectedValue={quickBuildSessionData?.open_to_public}/>
                        <button className="medium" style={{width:"100%", marginTop:10}} onClick={ async ()=>{
                          if(quickBuildSessionData?.quick_build_time === "Select an option") {
                            alert("Please select a length of the session.")
                            return
                          }
                          if(quickBuildSessionData?.media_location !== "") {
                            if(quickBuildSessionData?.media_source === "YouTube"){
                              //check if youtube url is playlist valid
                              let playlist_id = getVideoIDFromURL(quickBuildSessionData.media_location);
                              // make sure playlist_id tpype is either BOTH or PLAYLIST
                              if(playlist_id.type === "playlist"){
                                const updatedSession = {...global_quick_build_session};
                                updatedSession.media_location = playlist_id.id;
                                global_quick_build_session = updatedSession;
                                setQuickBuildSessionData(updatedSession);
                              }else if(playlist_id.type === "both"){
                                //this URL contains more than the list= part of the URL. just get the list part
                                const updatedSession = {...global_quick_build_session};
                                updatedSession.media_location = playlist_id.playlist_id;
                                global_quick_build_session = updatedSession;
                                setQuickBuildSessionData(updatedSession);
                              }else{
                                alert("Invalid YouTube URL. Please enter a valid YouTube URL.");
                                return
                              }
                            }
                            if(quickBuildSessionData?.media_source === "Spotify"){
                              let uri_url = spotifyUrlParser(quickBuildSessionData.media_location);
                              if(uri_url?.type === "playlist"){
                                const updatedSession = {...global_quick_build_session};
                                updatedSession.media_location = uri_url.id;
                                global_quick_build_session = updatedSession;
                                setQuickBuildSessionData(updatedSession);
                              }else{
                                alert("Invalid Spotify playlist URL. Please enter a valid Spotify playlist URL.");
                                return
                              }
                            }
                          }else if(quickBuildSessionData?.media_location === "" && quickBuildSessionData?.media_source !== "None"){
                            alert("You need a playlist URL if you have a media source selected.");
                            return
                          }
                          console.log(global_quick_build_session)
                          setLoadingMessage("Generating Session, this can take up to 5 minutes.");
                          setSomethingLoading(true);
                          //make a request to the server to build the session
                          //turn global_quick_build_session.genre into a string
                          let genre_string = "";
                          if(global_quick_build_session.genres != null){
                            if(global_quick_build_session.genres.length > 0){
                              for(let i = 0; i < global_quick_build_session.genres.length; i++){
                                if(i === global_quick_build_session.genres.length-1){
                                  genre_string += global_quick_build_session.genres[i];
                                }else{
                                  genre_string += global_quick_build_session.genres[i]+",";
                                }
                              }
                            }
                          }
                          try{
                            const real_time_database = uuidv4();
                            console.log(firebase_app.options); // Logs the configuration options of the default app
                            try{
                              console.log("DID THIS FIRE?")
                              const db = getDatabase(firebase_app);
                              console.log('quick-build-status-' + real_time_database)
                              const quickBuildProgress = ref(db, 'quick-build-status-' + real_time_database);
                              console.log(quickBuildProgress)
                              onValue(quickBuildProgress, (snapshot) => {
                                const data = snapshot.val();
                                setQuickBuildLoadingStatuses(data)
                                setLoadingMessage("Generating Session, this can take up to 5 minutes.");
                                console.log(data)
                              }, (error) => {
                                console.error(error)
                              });
                            }catch(e){
                              console.log(e)
                              console.error("failed to sync with realtime progress")
                            }
                            let generate_session = await fetchDataWithTokenRefresh(process.env.REACT_APP_QUICK_BUILD_SESSION+"?author="+global_quick_build_session.author+"&coach_id_id="+global_quick_build_session.coach_id.id+"&coach_id_name="+global_quick_build_session.coach_id.name+"&coach_id_photo="+global_quick_build_session.coach_id.photo+"&custom_instructions="+global_quick_build_session.custom_instructions+"&difficulty="+global_quick_build_session.difficulty+"&quick_build_time="+global_quick_build_session.quick_build_time+"&media_location="+global_quick_build_session.media_location+"&media_source="+global_quick_build_session.media_source+"&open_to_public="+global_quick_build_session.open_to_public+"&type="+global_quick_build_session.type+"&genres="+genre_string+"&real_time_database="+real_time_database, "POST", null, token, setToken);
                            console.log(generate_session)
                     
                            if(generate_session.message === "Success"){
                              navigate("/profile");
                            }else{
                              alert("Failed to generate session. Please try again.");
                            }
                          }catch(e){
                            console.log(e)
                            alert("Failed to generate session. Please try again.");
                          }
                          setSomethingLoading(false);
                        }}>Generate Session</button>
                      </div>
                    )}
                  </div>
                ):(
                  <div style={{maxWidth:800, display:'flex', flex:1, gap:20, alignSelf:"center"}}>
                      <div style={{flex:1, marginTop:200}}> 
                        <h2>Quick Session Builder</h2>
                        <h3>Select a few items and let Runerval do the rest.</h3>
                        <button className="medium" style={{width:"100%", marginTop:10}} onClick={()=>{
                          global_quick_build_session = {quick_build_time: 30, difficulty: "Beginner", media_source: "None", coach_id: Constants.COACHES[0], type: "Treadmill", open_to_public: true, custom_instructions: false, media_location:"", author:user.uid};
                          setQuickBuildSessionData({quick_build_time: 30, difficulty: "Beginner", media_source: "None", coach_id: Constants.COACHES[0], type: "Treadmill", open_to_public: true, custom_instructions: false, media_location:"", author:user.uid});
                          setQuickOrAdvanced("quick");
                          setHasQuickOrAdvancedBeenChosen(true);
                        }}>Enter</button>
                      </div>
                      <div style={{flex:1, marginTop:200}}>
                        <h2>Advanced Session builder</h2>
                        <h3>Manually choose all parts of the session.</h3>
                        <button className="medium" style={{width:"100%", marginTop:10}} onClick={()=>{
                          setQuickOrAdvanced("advanced");
                          setHasQuickOrAdvancedBeenChosen(true);
                          //newOrEditSession();
                        }}>Enter</button>
                      </div>
                  </div>
                )}
              </div>
              ):(
                <div style={{display:"flex", justifyContent:"center"}}>
                  <div style={{maxWidth:1200, marginTop:100, minWidth:400}}>
                    <h2>Building custom sessions only available to Runerval Pro users.</h2>
                    <h3>Download the app and upgrade now to gain access to this feature.</h3>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={{display:"flex", justifyContent:"center"}}>
              <div style={{maxWidth:1200, marginTop:100, minWidth:400}}>
                <LoginForm onLoading={handleLoading}></LoginForm>
                <h2 style={{marginTop:20}}>Download and register within the app:</h2>
                <div style={{display:"flex", height:100}}>
                  <BadgeApple style={{flex:.9, height:100, marginRight:5}}></BadgeApple>
                  <div style={{backgroundRepeat:"no-repeat", backgroundPosition:"center", backgroundImage: `url(${googleBadge})`, flex:1, height:100, marginLeft:5, backgroundSize:"113%"}}></div>
                </div>
              </div>
            </div>
          )}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default BuildSession;