import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { SessionCard } from './SessionCard';
import './HorizontalSessionList.css';
import {ReactComponent as IconNavigateBack} from '../assets/icons/navigate_back.svg';
import {ReactComponent as IconNavigateForward} from '../assets/icons/navigate_forward.svg';

export const HorizontalSessionList = ({ sessions }) => {
  const [index, setIndex] = useState(0); // Current index of the first visible session card
  const [springProps, setSpringProps] = useSpring(() => ({
    from: { transform: 'translateX(0px)' }
  }));

  const numVisibleCards = 3;
  const cardWidth = 380;

  const handleNext = () => {
    const newIndex = Math.min(index + numVisibleCards, sessions.length - numVisibleCards);
    setSpringProps.start({
      to: { transform: `translateX(-${newIndex * cardWidth}px)` }
    });
    setIndex(newIndex);
  };

  const handlePrev = () => {
    const newIndex = Math.max(index - numVisibleCards, 0);
    setSpringProps.start({
      to: { transform: `translateX(-${newIndex * cardWidth}px)` }
    });
    setIndex(newIndex);
  };

  return (
    <div className="horizontal-session-list-container">
      <button 
        style={{
          position: "absolute", 
          zIndex: 5, 
          left: 0, 
          height: 200, 
          width: 45,
          background: "linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0))" // Added gradient here
        }} 
        onClick={handlePrev} 
        disabled={index === 0}
      >
        <IconNavigateBack style={{fill:"#ffffff", width:50, height:50}} />
      </button>
      <animated.div
        className="scroll-container"
        style={springProps}
      >
        {sessions.map((session, idx) => (
          <div key={idx} className="session-card-wrapper">
            <SessionCard session={session} />
          </div>
        ))}
      </animated.div>
      <button style={{
          position: "absolute", 
          zIndex: 5, 
          right: 0, 
          height: 200, 
          width: 45,
          background: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))" // Added gradient here
        }} 
        onClick={handleNext} disabled={index >= sessions.length - numVisibleCards}>
          <IconNavigateForward style={{fill:"#ffffff", width:50, height:50}}/>
      </button>
    </div>
  );
};
