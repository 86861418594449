import React, { useState } from 'react';
import { signUp } from '../utils/Auth';



function SignUpForm({onLoading}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [didFail, setDidFail] = useState(false);
  const [signUpError, setSignUpError] = useState(false);
  const [wasItASuccess, setWasItASuccess] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [didntAgreeToPolicies, setDidntAgreeToPolicies] = useState(false);

  const generateRandomAnimal = () => {
    const animals = ['cat', 'dog', 'elephant', 'lion', 'tiger'];
    const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
    return randomAnimal;
  };

  const generateRandomNumbers = () => {
    const randomNumbers = Math.floor(10000 + Math.random() * 90000);
    return randomNumbers;
  };

  const generateRandomString = () => {
    const randomAnimal = generateRandomAnimal();
    const randomNumbers = generateRandomNumbers();
    return `${randomAnimal}${randomNumbers}`;
  };
  
  const [username, setUsername] = useState(generateRandomString());

const today = new Date();
const year = today.getFullYear();
const month = String(today.getMonth() + 1).padStart(2, '0');
const day = String(today.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
  
  const [birthday, setBirthday] = useState(formattedDate);
  const [newsletter, setNewsletter] = useState(true);


  const handleSubmit = async (event) => {
    event.preventDefault();
    onLoading(true, "Signing up...")
    // Handle login logic here

    try{
      if(termsOfService && privacyPolicy === true){
        await signUp(password, repeatPassword, email, birthday, username, newsletter);
        setDidntAgreeToPolicies(false)
        setDidFail(false);
        setWasItASuccess(true);
      }else{
        setDidFail(true);
        setSignUpError("You must agree to the Terms of Service and Privacy Policy.")
        setDidntAgreeToPolicies(true)
      }
    }catch(error){
      setDidFail(true);
      setSignUpError(error.message)

    }
    onLoading(false)
  };

  return (
    <div>
      {!wasItASuccess ? (
      <form onSubmit={handleSubmit} style={{maxWidth:400}}>
        <h2>Sign Up</h2>
        <div>
          {didFail && <h3 style={{color:"red"}}>{signUpError}</h3>}
          <h4 className='input-title'>Email <span style={{color:"#ff0000"}}>(required)</span></h4>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
          {/* <label>
            Email:
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
          </label> */}
        </div>
        <div>
          <h4 className='input-title'>Username</h4>
          <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
        </div>
        <div>
          <h4 className='input-title'>Birthday</h4>
          <input type="date" value={birthday} onChange={e => setBirthday(e.target.value)} />
          <h4>We want to know the average age of our users.</h4>
        </div>
        <div>
          <h4 className='input-title'>Password <span style={{color:"#ff0000"}}>(required)</span></h4>
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <div>
          <h4 className='input-title'>Repeat Password <span style={{color:"#ff0000"}}>(required)</span></h4>
          <input type="password" value={repeatPassword} onChange={e => setRepeatPassword(e.target.value)} />
        </div>
        <div style={{display:"flex", flexDirection:'row', marginTop:10}}>
          <h3 style={{flex:1, color:didntAgreeToPolicies?"#ff0000":"#000000"}}>You read and agree with the <a href="/terms_of_service.html" target="_blank">Terms of Service</a>:</h3>
          <input type="checkbox" style={{width:"auto"}} checked={termsOfService} onChange={e => setTermsOfService(e.target.checked)} />
        </div>
        <div style={{display:"flex", flexDirection:'row', marginTop:10}}>
          <h3 style={{flex:1, color:didntAgreeToPolicies?"#ff0000":"#000000"}}>You read and agree with the <a href="/privacy_policy.html" target="_blank">Privacy Policy</a>:</h3>
          <input type="checkbox" style={{width:"auto"}} checked={privacyPolicy} onChange={e => setPrivacyPolicy(e.target.checked)} />
        </div>
        <div style={{display:"flex", flexDirection:'row', marginTop:10}}>
          <h3 style={{flex:1}}>Can we send you emails?</h3>
          <input type="checkbox" style={{width:"auto"}} checked={newsletter} onChange={e => setNewsletter(e.target.checked)} />
        </div>
        <div>
          <button className="medium" style={{marginTop:10}} type="submit">Register</button>
        </div>
      </form>
      ):(
        <h2 style={{maxWidth:400}}>Thank you for registering. Check your email to verify.</h2>
      )}
    </div>
  );
}

export default SignUpForm;