// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
let databaseURL = "https://runerval-a6a0b-default-rtdb.firebaseio.com"
if(process.env.NODE_ENV === "development"){
  databaseURL = "http://127.0.0.1:9000/?ns=runerval-a6a0b-default-rtdb"
}
const firebaseConfig = {
  apiKey: "AIzaSyCFBI_McCvX8TBATFHefmX9jHAXXGgzJV0",
  authDomain: "runerval-a6a0b.firebaseapp.com",
  databaseURL: databaseURL,
  projectId: "runerval-a6a0b",
  storageBucket: "runerval-a6a0b.appspot.com",
  messagingSenderId: "609448226343",
  appId: "1:609448226343:web:69cc014054cb696906e802",
  measurementId: "G-SLL146JDM1"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

