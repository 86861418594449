import React, { useState } from 'react';
import Footer from '../components/Footer';
import { universalFetchPublic } from '../utils/Utils';
import Toast from '../components/Toast';

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({}); // State for validation errors
  const [somthingLoading, setSomethingLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [toastMessage, setToastMessage] = useState()
  const [toastType, setToastType] = useState()
  const [isToastOpen, setIsToastOpen] = useState(false)

  const showToast = (message, type = 'success') => {
    setToastMessage(message);
    setToastType(type);
    setIsToastOpen(true);
  };

  const closeToast = () => {
    setIsToastOpen(false);
  };
  // Email validation function
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name cannot be empty.";
    if (!email.trim()) newErrors.email = "Email cannot be empty.";
    else if (!isValidEmail(email)) newErrors.email = "Please enter a valid email address.";
    if (!message.trim()) newErrors.message = "Message cannot be empty.";
    setErrors(newErrors);
    if(Object.keys(newErrors).length > 0){
      setSomethingLoading(false);
    }
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSomethingLoading(true);
    setLoadingMessage("Sending message...");
    if (!validateForm()) return; // Stop the form submission if validation fails

    const emailDetails = {
      email,
      name,
      message,
    };

    try {
      let send_email = await universalFetchPublic(process.env.REACT_APP_SEND_EMAIL, "POST", emailDetails);
      console.log(send_email, "send_email");
      // Reset form after sending email if successful
      setSomethingLoading(false);
      showToast("Message sent successfully", "success");
      setEmail("");
      setName("");
      setMessage("");
      setErrors({}); // Clear any errors
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div>
      {somthingLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
          <div className="loading-icon"></div>
          <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
        </div>
      )}
      <div style={{ minHeight: "calc(100vh - 178px)" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flex: 1, maxWidth: 1200, marginTop: 100, minWidth: 400 }}>
            <div style={{flex:1}}>
              <h4>Email</h4>
              <h2>contact@runerval.com</h2>
              <div style={{padding:20}}></div>
              <h4>Social Media</h4>
              <h3>No socials yet.</h3>
              <div style={{padding:20}}></div>
              <div style={{
                display:"flex",
                alignItems:"center"
              }}>
                <div style={{
                  backgroundImage: "url('https://firebasestorage.googleapis.com/v0/b/powerhourproject.appspot.com/o/user_uploaded_content%2F58%2Favatar%2F5764381?alt=media&token=6b1ceb27-ed72-4ab9-b054-68378afb9c5e')",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: 50,
                  height: 50,
                  borderRadius:50,
                  borderStyle:"solid",
                  borderWidth:2,
                  borderColor:"#000"
                }}></div>
                <div style={{marginLeft:10}}> 
                  <h4>Created by </h4>
                  <h3>Michael Whitney | <a href="https://github.com/MPDubs" rel="noreferrer" target="_blank">GitHub</a></h3>
                </div>
              </div>
            </div>
            {/* Existing structure */}
            <form style={{ flex: 1 }} onSubmit={handleSubmit}>
              <h2>Contact Us</h2>
              <div>
                <h4 className='input-title'>Name</h4>
                <input type="text" value={name} onChange={e => setName(e.target.value)} />
                {errors.name && <div style={{ color: "red" }}>{errors.name}</div>}
              </div>
              <div>
                <h4 className='input-title'>Email</h4>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
                {errors.email && <div style={{ color: "red" }}>{errors.email}</div>}
              </div>
              <div>
                <h4 style={{ marginTop: 20, marginBottom: 10 }}>Message</h4>
                <textarea value={message} onChange={e => setMessage(e.target.value)} />
                {errors.message && <div style={{ color: "red" }}>{errors.message}</div>}
              </div>
              <div>
                <button className="medium" style={{ marginTop: 10 }} type="submit">Send</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toast message={toastMessage} type={toastType} isOpen={isToastOpen} closeToast={closeToast} />
      <Footer></Footer>
    </div>
  );
};

export default Contact;