import React, { useContext, useEffect } from 'react';
import { SessionContext } from '../App'; // Adjust the path as needed
import NavigationProfile from '../components/NavigationProfile'; // Adjust the path as needed
import { HorizontalSessionList } from '../components/HorizonalSessionList';
import {fetchDataWithTokenRefresh} from '../utils/Utils';




function ProfileMySessions() {
  const { user, token, setToken } = useContext(SessionContext);
  const [sessions, setSessions] = React.useState([]);
  const [runervalSessions, setRunervalSessions] = React.useState([]); // [TODO: Add the runerval sessions here
  const [somthingLoading, setSomethingLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState("Loading...");


  async function requestUsersSessions() {
    setSomethingLoading(true)
    setLoadingMessage("Fetching your sessions...")
    try{
      let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/sessions?authorId="+user.uid, "GET", null, token, setToken)
      setSessions(get_sessions)
    }catch(error) {
      alert("Error fetching sessions")
      console.log(error)
    }
    if(user.email==="test@test.com" || user.email==="whitnemp@gmail.com"){
      try{
        let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/sessions?authorId=Runerval", "GET", null, token, setToken)
        setRunervalSessions(get_sessions)
      }catch(error) {
        alert("Error fetching sessions")
        console.log(error)
      }
    }
    setSomethingLoading(false)
  }
  useEffect(() => {
    if(user){
      requestUsersSessions();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
      <div>
        <NavigationProfile user={user}></NavigationProfile>
        {somthingLoading && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex:9999, flexDirection:"column" }}>
          <div className="loading-icon"></div>
          <h3 style={{marginTop:10, color:"#ffffff"}}>{loadingMessage}</h3>
        </div>
        )}
        <div style={{height:300, padding:10}}>
          <h3 style={{marginBottom:10}}>My Sessions</h3>
          <HorizontalSessionList sessions={sessions}></HorizontalSessionList>
        </div>
        {(user.email==="test@test.com" || user.email==="whitnemp@gmail.com") && (
        <div style={{height:300, padding:10}}>
          <h3 style={{marginBottom:10}}>Runerval Sessions</h3>
          <HorizontalSessionList sessions={runervalSessions}></HorizontalSessionList>
        </div>
        )}
      </div>
    );
}

export default ProfileMySessions;