import React, { useState, useEffect } from 'react';
import './Bubble.css';

function Bubble({ id, children }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Initialize visibility based on localStorage
    setIsVisible(!localStorage.getItem(`bubbleClosed-${id}`));
  }, [id]);

  const handleClose = () => {
    localStorage.setItem(`bubbleClosed-${id}`, 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="bubble" onClick={handleClose}>
      {children}
    </div>
  );
}

export default Bubble;