import {useState, useEffect, useContext} from 'react';
import { SessionContext } from '../App'; // Adjust the path as needed
import { auth } from '../firebase';
import './Home.css';
import promoVideo from '../assets/promo_video.mp4'; // Adjust the path as necessary
import { HorizontalSessionList } from '../components/HorizonalSessionList';
import {fetchDataWithTokenRefresh, universalFetchPublic} from '../utils/Utils';
import * as Constants from '../constants';
import homeFirstPromo from '../assets/images/home_first_promo.jpg';
import homeFirstPromo2 from '../assets/images/home_first_promo2.jpg';
import ComparisonGrid from '../components/ComparisonGrid';
import Footer from '../components/Footer';
import SessionCardLoading from '../components/SessionCardLoading';
import Dropdown from '../components/Dropdown';

function Home({navigationHeaderRefHeight}) {
    const { user, token, setToken } = useContext(SessionContext);
    console.log(navigationHeaderRefHeight, "navigationHeaderRefHeight")
    const [runervalSessions, setRunervalSessions] = useState([]); // [TODO: Add the runerval sessions here
    const [publicSessions, setPublicSessions] = useState([]); // [TODO: Add the runerval sessions here
    const [pullingRunervalSessions, setPullingRunervalSessions] = useState(true);
    const [pullingPublicSessions, setPullingPublicSessions] = useState(true);
    const [topRatedSessions, setTopRatedSessions] = useState([]); // [TODO: Add the top rated sessions here
    const [topRatedSessionsMonth, setTopRatedSessionsMonth] = useState("month");
    // const [somethingLoading, setSomethingLoading] = useState(false);
    // const [loadingMessage, setLoadingMessage] = useState("Loading...");
    const options = [
      { value: 'month', label: 'Month' },
      { value: 'week', label: 'Week' },
      { value: 'year', label: 'Year' }
    ];

    async function requestUsersSessions() {
      // setSomethingLoading(true)
      // setLoadingMessage("Fetching your sessions...")
      setPullingRunervalSessions(true)
      if(user){
        console.log("user logged in")
        try{
          let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/sessions?authorId=Runerval", "GET", null, token, setToken)
          setRunervalSessions(get_sessions)
        }catch(error) {
          alert("Error fetching sessions")
          console.log(error)
        }
      }else{
        //get user IP for rate limiting and shit
        let ip = await universalFetchPublic(process.env.REACT_APP_GET_IP, "GET", null)
        try {
          let get_sessions = await universalFetchPublic(process.env.REACT_APP_GET_DATA_PUBLIC+"/api/sessions?ip="+ip.ip+"&authorId=Runerval&sortBy=date", "GET", null)
          setRunervalSessions(get_sessions)
        } catch (error) {
          console.log("Error fetching sessions:", error);
        }
      }
      setPullingRunervalSessions(false)

    }
    async function requestPublicSessions() {
      // setSomethingLoading(true)
      // setLoadingMessage("Fetching your sessions...")
      setPullingPublicSessions(true)
      if(user){
        console.log("user logged in")
        try{
          let get_sessions = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA+"/api/sessions", "GET", null, token, setToken)
          setPublicSessions(get_sessions)
        }catch(error) {
          alert("Error fetching sessions")
          console.log(error)
        }
      }else{
        //get user IP for rate limiting and shit
        let ip = await universalFetchPublic(process.env.REACT_APP_GET_IP, "GET", null)
        console.log(ip)
        try {
          let get_sessions = await universalFetchPublic(process.env.REACT_APP_GET_DATA_PUBLIC+"/api/sessions?ip="+ip.ip, "GET", null)
          console.log(get_sessions)
          setPublicSessions(get_sessions)
        } catch (error) {
          console.log("Error fetching sessions:", error);
        }
      }
      setPullingPublicSessions(false)
    }
    async function requestTopRated(time){
      let get_sessions = await universalFetchPublic(process.env.REACT_APP_GET_TOP_REATED_SESSIONS+"?period="+time, "GET", null)
      console.log(get_sessions)
      setTopRatedSessions(get_sessions)
    }
    function handleSelect(option) {
      requestTopRated(option.value);
      console.log(option.label)
      setTopRatedSessionsMonth(option.value)
    }
    
    useEffect(() => {
        console.log(auth.user); //we need this here for some reason!
        requestUsersSessions();
        requestPublicSessions();
        requestTopRated("month")
        // Update currentContentHeight when navigationHeaderRefHeight changes

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <div style={{display:"flex", flexDirection:"column"}}>
                <div className="background-video-container" style={{height:600}}>
                    <video autoPlay loop muted className="video">
                        <source src={promoVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="overlay-content">
                        <div style={{backgroundColor:"rgba(0, 0, 0, 0.3)", padding:20, borderRadius:4}}>
                            <h1 className="hero">Welcome to Runerval</h1>
                            <h2 className="hero">Be guided through a running session on your phone or tablet!
                            </h2>
                            <h2 className="hero">Start running <span style={{color:"#17CF8B"}}>for free!</span></h2>
                        </div>
                    </div>
                </div>
                <div style={{padding:10}}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                    <h1 style={{marginRight:10}}>Top Rated Sessions</h1>
                    <div style={{width:125, marginBottom:10, marginRight:10}}>
                        <Dropdown 
                          options={options} 
                          onSelect={handleSelect} 
                          title="Select Period"
                          selectedValue={topRatedSessionsMonth} // Optionally preselect "month", ensure this value exists in your options
                        />
                    </div>
                    <div style={{width:10, height:10, borderRadius:10, backgroundColor:Constants.PACES_COLORS["Walk"]}}></div>
                    <h3 style={{marginLeft:5}}>Walk</h3>
                    <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Jog"]}}></div>
                    <h3 style={{marginLeft:5}}>Jog</h3>
                    <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Run"]}}></div>
                    <h3 style={{marginLeft:5}}>Run</h3>
                    <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Sprint"]}}></div>
                    <h3 style={{marginLeft:5}}>Sprint</h3>
                  </div>
                  {pullingRunervalSessions ? (
                    <div style={{height:196, flexDirection:"row", display:"flex", overflowX:"hidden"}}>
                      {[1,1,1].map((card, index) => (
                        <SessionCardLoading orientation={"horizontal"} key={index}/>
                      ))}
                    </div>
                  ):(
                    <div>
                      {topRatedSessions.length===0 ? (
                          <h3>Couldn't find any rated sessions.</h3>
                      ):(
                          <HorizontalSessionList sessions={topRatedSessions}></HorizontalSessionList>
                      )}
                    </div>
                  )}
                </div>
                <div style={{padding:10}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                      <h1 style={{marginBottom:10, marginRight:10}}>Today's Runerval Sessions</h1>
                      <div style={{width:10, height:10, borderRadius:10, backgroundColor:Constants.PACES_COLORS["Walk"]}}></div>
                      <h3 style={{marginLeft:5}}>Walk</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Jog"]}}></div>
                      <h3 style={{marginLeft:5}}>Jog</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Run"]}}></div>
                      <h3 style={{marginLeft:5}}>Run</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Sprint"]}}></div>
                      <h3 style={{marginLeft:5}}>Sprint</h3>
                    </div>
                    {pullingRunervalSessions ? (
                      <div style={{height:196, flexDirection:"row", display:"flex", overflowX:"hidden"}}>
                        {[1,1,1].map((card, index) => (
                          <SessionCardLoading orientation={"horizontal"} key={index}/>
                        ))}
                      </div>
                    ):(
                      <div>
                        {runervalSessions.length===0 ? (
                            <h3>Couldn't find any Runerval sessions.</h3>
                        ):(
                            <HorizontalSessionList sessions={runervalSessions}></HorizontalSessionList>
                        )}
                      </div>
                    )}
                </div>
                <div style={{padding:10}}>
                    <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                      <h1 style={{marginBottom:10, marginRight:10}}>Recent User Generated Sessions</h1>
                      <div style={{width:10, height:10, borderRadius:10, backgroundColor:Constants.PACES_COLORS["Walk"]}}></div>
                      <h3 style={{marginLeft:5}}>Walk</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Jog"]}}></div>
                      <h3 style={{marginLeft:5}}>Jog</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Run"]}}></div>
                      <h3 style={{marginLeft:5}}>Run</h3>
                      <div style={{width:10, height:10, borderRadius:10, marginLeft:10, backgroundColor:Constants.PACES_COLORS["Sprint"]}}></div>
                      <h3 style={{marginLeft:5}}>Sprint</h3>
                    </div>
                    {pullingPublicSessions ? (
                      <div style={{height:196, flexDirection:"row", display:"flex", overflowX:"hidden"}}>
                        {[1,1,1].map((card, index) => (
                          <SessionCardLoading orientation={"horizontal"} key={index}/>
                        ))}
                      </div>
                    ):(
                      <div>
                      {publicSessions.length===0 ? (
                          <h3>Pulling sessions...</h3>
                      ):(
                          <HorizontalSessionList sessions={publicSessions}></HorizontalSessionList>
                      )}
                      </div>
                    )}
                </div>
            </div>
            <div style={{ display:"flex", position: "relative", zIndex: 2,  backgroundImage: `url(${homeFirstPromo})`, backgroundSize:"50%", backgroundRepeat:"no-repeat", backgroundColor:"#000", backgroundPositionY:"45%", justifyContent:"center"}}>
              <div style={{width:1200, display:"flex", padding:10, paddingTop:100, paddingBottom:100}}>
                <div style={{flex:1}}></div>
                <div style={{flex:1}}>
                  <h1 className="hero" style={{color:"#fff"}}>Cardio can be tough.</h1>
                  <h2 className="hero" style={{color:"#fff", marginBottom:30}}>Trying anything to keep me running.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Be guided with AI generated audio through warmup, intervals, and cooldown.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Accompanied media generated around genres with YouTube and Spotify Premium.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Sessions are based on time, not distance.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Turn off the media if want and just listen to the guide.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Make your own sessions and more with <span style={{color:"#17cf8b"}}>Runerval Pro</span>.</h2>
                </div>
              </div>
            </div>
            <div style={{ display:"flex", position: "relative", zIndex: 2, backgroundColor:"#fff", justifyContent:"center"}}>
              <ComparisonGrid></ComparisonGrid>
            </div>
            <div style={{ display:"flex", position: "relative", zIndex: 2,  backgroundImage: `url(${homeFirstPromo2})`, backgroundSize:"45%", backgroundRepeat:"no-repeat", backgroundColor:"#000", backgroundPositionY:"30%", backgroundPositionX:"100%", justifyContent:"center"}}>
              <div style={{width:1200, display:"flex", padding:10, paddingTop:200, paddingBottom:200}}>
                <div style={{flex:1}}>
                  <h1 className="hero" style={{color:"#fff"}}>On your tablet and phone</h1>
                  <h2 className="hero" style={{color:"#fff", marginBottom:30}}>Rickrolled on cooldown :/</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>YouTube videos as part of a session.</h2>
                  <h2 style={{color:"#fff", marginBottom:10}}>Videos have to allow embedding.</h2>
                </div>
                <div style={{flex:1}}></div>
              </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Home;