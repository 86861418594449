import React, { createContext, useEffect, useState, useRef, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationHeader from './components/NavigationHeader';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import Verify from './pages/Verify';
import Session from './pages/Session';
import SignUp from './pages/SignUp';
import BuildSession from './pages/BuildSession';
import BrowseSessions from './pages/BrowseSessions';
import Profile from './pages/Profile';
import Contact from './pages/Contact';
import ProfileMySessions from './pages/ProfileMySessions';
import {auth} from './firebase';
import { fetchDataWithTokenRefresh } from './utils/Utils';


export const SessionContext = createContext({
  user: null,
  token: null,
  setUser: () => {},
  setToken: () => {}
});


export const SessionProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [authChecking, setAuthChecking] = useState(true);  // State to track authentication checking

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async function(firebaseUser) {
      setAuthChecking(true);  // Start checking
      if (firebaseUser) {
        const token = await firebaseUser.getIdToken();
        let user_metadata = await fetchDataWithTokenRefresh(process.env.REACT_APP_GET_DATA + "/api/userMetaData?user_id="+firebaseUser.uid, "GET", null, token);
     
        user_metadata = user_metadata[0];
        user_metadata.fetched = Date.now();
        console.log("user_metadata", user_metadata)
        firebaseUser = {...firebaseUser, ...user_metadata,}
        setToken(token);
        setUser(firebaseUser);
        localStorage.setItem('userMetaData', JSON.stringify(firebaseUser));
        console.log(token, "TOKEN")
      } else {
        setUser(null);
        setToken(null);
      }

      setAuthChecking(false);  // End checking
    });
   
    return () => unsubscribe(); // Clean up subscription
  }, []);

  return (
    <SessionContext.Provider value={{ user, setUser, token, setToken, authChecking }}>
      {children}
    </SessionContext.Provider>
  );

//   <div style={{ flex: "0 0 auto" }}> {/* For text aligned to the far left */}
//   <AppIcon style={{width:100, height:100}}></AppIcon>
// </div>


};

export function App() {
  const navigationHeaderRef = useRef(null);
  const [navigationHeaderRefHeight, setNavigationHeaderRefHeight] = useState(null);
  const { user, authChecking } = useContext(SessionContext);


  useEffect(() => {
    // createThirtyMinuteSpotifySession();
    if (navigationHeaderRef.current) {
      const height = navigationHeaderRef.current.offsetHeight;
      setNavigationHeaderRefHeight(height);
    }
  }, [user]);
  return (
    <Router>
      {/* <div style={{backgroundColor:"#17CF8B", padding:10, display:"flex", justifyContent:"center"}}>
        <h2>Build your own sessions and other cool features with <span style={{color:"#000", textDecoration:"underline"}}>Runerval Pro</span></h2>
      </div> */}
      <div ref={navigationHeaderRef}>
        <NavigationHeader user={user}></NavigationHeader>
      </div>
      {authChecking && <div className="loading-overlay">Identifying login status...</div>}  
      <div style={{ display: authChecking ? 'none' : 'block' }}>  
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home navigationHeaderRefHeight={navigationHeaderRefHeight} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/build-session" element={<BuildSession/>} />
          <Route path="/browse-sessions" element={<BrowseSessions />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/my-sessions" element={<ProfileMySessions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/session" element={<Session />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verify" element={<Verify />} />
        </Routes>
      </div>
    </Router>
  );
}