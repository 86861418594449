import React, { useState, useEffect } from 'react';
import './Toast.css';

function Toast({ message, type, isOpen, closeToast }) {
  const [shouldSlideDown, setShouldSlideDown] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setShouldSlideDown(false);
      const timer = setTimeout(() => {
        setShouldSlideDown(true);
        setTimeout(closeToast, 500); // Wait for slide-down animation to complete
      }, 3000); // Toast will stay for 3 seconds
      return () => clearTimeout(timer);
    }
  }, [isOpen, closeToast]);

  if (!isOpen && !shouldSlideDown) return null;

  return (
    <div className={`toast ${type} ${shouldSlideDown ? 'hide' : ''}`}>
      {message}
    </div>
  );
}

export default Toast;